/**
 * @module AttemptResetPassword
 */
//React imports
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Box,
} from '@material-ui/core';
//Other libraries
import { Translation } from 'react-i18next';
import axios from 'axios';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const errorCodesToMessages = {
  666: 'network error, please try again',
  1000: 'e-mail is missing',
  1001: 'password is missing',
  1002: 'token is missing',
  2000: 'password is less than 8 characters or more than 255 characters',
  3000: 'e-mail is not valid',
  3001: 'password is not valid',
  5000: 'token is not valid',
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    margin: '40px 0px',
    alignItems: 'center',
    justifyItems: 'center',
    // height: "100%"
  },
  paper: {
    width: '40%',
    minWidth: 300,
    maxWidth: 600,
    minHeight: '200px',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    display: 'flex',
  },
  form: {
    padding: '10px 20px',
    margin: '10px',
    width: '100%',
  },
  centeredFlex: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  mainButton: {
    width: '100%',
    minWidth: 200,
    maxWidth: 320,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },

  redText: {
    color: 'red',
  },
  whiteBackgroundLabel: {
    backgroundColor: 'white',
  },
  resetPasswordText: { margin: '20px 0' },
  successMessage: { display: 'flex', justifyContent: 'center' },
  progress: { position: 'absolute' },
}));
/**
 * @method AttemptResetPassword
 * @description attempt reset password component
 * @see module:Authentication
 */
const AttemptResetPassword = (props) => {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const handleChange = (event) => {
    const temp = { ...formData };
    temp[event.target.name] = event.target.value;
    setFormData(temp);
  };
  const emailRef = useRef(null);
  const handleBlur = (event) => {
    emailRef.current.validate(event.target.value);
  };
  /**
   * @method handleSubmit
   *
   * @description sends a reset password request
   */
  const handleSubmit = () => {
    //
    const { email } = formData;
    setLoading(true);
    setErrors([]);
    setSuccessMessage(false);
    axios
      .post(
        apiEndpoint + 'customers/attempt-password-reset',
        {
          email,
        },
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
        }
      )
      .then((res) => {
        setLoading(false);
        setSuccessMessage(true);
      })
      .catch((error) => {
        setLoading(false);

        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          setErrors(error.response.data.errors);
        } else {
          setErrors([{ code: 666, message: 'timeout' }]);
        }
      });
  };
  // console.log('errors', errors);
  const classes = useStyles();
  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t('reset your password')}</title>
          </Helmet>
          <Typography className={classes.resetPasswordText}>
            {t('reset your password')}
          </Typography>
          <Paper className={classes.paper}>
            <ValidatorForm
              onSubmit={handleSubmit}
              className={classes.form}
              instantValidate={false}
            >
              <Grid container>
                {errors.length > 0 && (
                  <Grid item xs={12}>
                    <ul>
                      {errors.map((error, index) => (
                        <li className={classes.redText} key={index}>
                          {t(errorCodesToMessages[error.code])}
                        </li>
                      ))}
                    </ul>
                  </Grid>
                )}
                {successMessage && (
                  <Grid item xs={12}>
                    <Box p={2} className={classes.successMessage}>
                      <Typography>
                        {t('check your e-mail for instructions')}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box pb={3}>
                    <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      variant='outlined'
                      ref={emailRef}
                      label={t('email')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name='email'
                      value={formData.email}
                      validators={['required', 'isEmail']}
                      errorMessages={[
                        t('this field is required'),
                        t('email is not valid'),
                      ]}
                      fullWidth
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} className={classes.centeredFlex}>
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={loading}
                    className={classes.mainButton}
                  >
                    {(loading && t('please wait')) ||
                      (!loading && t('reset password'))}
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.progress}
                      />
                    )}
                  </Button>
                </Grid>

                <Grid item xs={12} className={classes.centeredFlex}>
                  <Box mt={1}>
                    <Typography variant='subtitle2'>
                      {`${t('back to')} `}
                      <Link to='/auth/sign-in'>{t('sign in')}</Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Paper>
        </>
      )}
    </Translation>
  );
};

export default AttemptResetPassword;
