/**
 * @module User
 */
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Box } from "@material-ui/core";
import { useGlobalState } from "../../GlobalState";
//components
import Cart from "./Cart";
import Profile from "./Profile";
import AddAddressInitial from "./AddAddressInitial/";
import NoMatch404 from "../NoMatch404";
import Checkout from "./Checkout/index";

/**
 * @method UserPages
 * @description nested router for user pages. checks if user is logged in or not,
 * - if true, renders the appropriate page
 * - if false, redirects to the sign in page for the user to log in
 * Profile is also a nested router
 * @requires Cart
 * @requires Profile
 * @requires AddAddressInitial
 * @requires Checkout
 */
const UserPages = props => {
  const [loggedIn] = useGlobalState("loggedIn");
  return (
    <Box pt={1}>
      {!loggedIn ? (
        <Redirect to="/auth/sign-in" />
      ) : (
        <Switch>
          <Route path="/user/cart" component={Cart} exact />
          <Route path="/user/profile" component={Profile} />
          <Route
            path={"/user/add-address"}
            component={AddAddressInitial}
            exact
          />
          <Route path={"/user/checkout"} exact component={Checkout} />
          {/* 404 */}
          <Route component={NoMatch404} />
        </Switch>
      )}
    </Box>
  );
};

export default UserPages;
