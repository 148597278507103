/**
 * @module Product
 */
//React imports
import React, { useEffect, useCallback } from 'react';
import queryString from 'query-string';
import {
  Button,
  Typography,
  Grid,
  TextField,
  Box,
  MenuItem,
  CircularProgress,
  Hidden,
  Backdrop,
} from '@material-ui/core';
import { Skeleton, Rating } from '@material-ui/lab';
//Other libraries
import { Translation } from 'react-i18next';
import axios from 'axios';
import ProductImageCarousel from './ProductImageCarousel';
import TwoEightTwoContainer from '../../common/TwoEightTwoContainer';
import { useGlobalState } from '../../GlobalState';
import { useState } from 'react';
import Helmet from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import ItemSlider from '../Homepage/TopPicks/ItemSlider';
import parse from 'html-react-parser';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  content: { paddingTop: 4, height: '100%' },
  listItem: { lineHeight: '2rem' },
  boldFont: { fontWeight: '600' },
  dividerMenuItem: { borderBottom: '1px solid gray', padding: '4px' },
  itemPrice: {
    display: 'inline-flex',
    color: theme.palette.primary.dark,
    paddingRight: '4px',
  },
  errorText: {
    display: 'inline-flex',
    color: '#ba0000',
    paddingRight: '4px',
  },
  mainButton: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  centeredFlex: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  secondaryButton: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.veryDark,
  },
  ratingRoot: { color: theme.palette.primary.dark, padding: '4px 0' },
  columnFlex: { display: 'flex', flexDirection: 'column' },
  fromVendor: { fontSize: 14, display: 'inline-flex' },
  quantitySelect: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  itemSpecs: {
    paddingTop: 16,
  },
  fullWidth: { width: '100%' },
  absolutePosition: { position: 'absolute' },
  listItem2: {
    paddingInlineStart: '20px',
    marginTop: '8px',
  },
  itemDescriptionContainer: {
    display: 'flex',
    maxWidth: '100%',
    overflow: 'auto',
  },
  errorContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
}));
//put this at the top of the page
let itemCancelTokenSource = new axios.CancelToken.source();
let cartCancelTokenSource = new axios.CancelToken.source();

const useStyles2 = makeStyles((theme) => ({
  horizontalDivider: {
    width: '30%',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginTop: '10px',
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));

/**
 * @method ItemRecommendations
 * @description creates a search request with item title and retailer and
 * renders an item slider with the resulting items, as related items
 * @param {Object} props component props
 * @param {string} props.title item search key
 * @param {string} props.retailer retailer name
 * @param {string} props.currentItemId id of current item to remove from result array
 * @param {boolean} props.parentLoading loading state of parent, to sync loading with it
 * @see module:ItemSlider
 * @see module:Product
 */

const ItemRecommendations = (props) => {
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { title, retailer, currentItemId, parentLoading } = props;
  const carouselRef = React.createRef();
  const classes = useStyles2();
  useEffect(() => {
    setLoading(true);
    // console.log("title", title);
    axios
      .post(
        apiEndpoint + 'search/',
        {
          retailer: retailer,
          search_key: title,
        },
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
        }
      )
      .then((res) => {
        // console.log("res", res);
        if (res.data && res.data.data) {
          // console.log("res.data.data", res.data.data);
          const items = res.data.data.filter(
            (item) => item.retailer_item_id !== currentItemId
          );
          // console.log("items", items);
          if (items.length > 0) {
            setItem({
              title: 'related items',
              retailer,
              items: items,
            });
            setError(false);
          } else {
            setError(true);
          }
        }
      })
      .catch((error) => {
        // console.log('error', error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [title, retailer, currentItemId]);

  // return null;
  return !loading && !parentLoading ? (
    !error ? (
      <>
        <Box p={2} className={classes.boxContainer}>
          <div className={classes.horizontalDivider} />
        </Box>
        <ItemSlider item={item} refCarrier={carouselRef} />
      </>
    ) : null
  ) : (
    <Grid item container xs={12} spacing={1}>
      <Box p={2} className={classes.boxContainer}></Box>
      {[...Array(4)].map((_item, idx) => {
        return (
          <Grid item key={idx} xs={6} sm={4} md={3} lg={3} xl={2}>
            <Skeleton height='200px' width='100%' variant='rect' />
            <Skeleton width='80%' height={20} />
            <Skeleton width='20%' height={20} />
          </Grid>
        );
      })}
    </Grid>
  );
};

/**
 * @method Product
 * @description product details component
 * @requires ProductImageCarousel
 * @see module:ItemRecommendations
 */
const Product = (props) => {
  const [loggedIn] = useGlobalState('loggedIn');
  const [session] = useGlobalState('session');
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [buttonText, setButtonText] = useState('');
  const [buttonStatus, setButtonStatus] = useState('enabled');
  const [error, setError] = useState(false);
  const [, setCart] = useGlobalState('cart');
  // const [showMore, setShowMore] = useState(false);
  // const [data, setData] = useState(null);
  const [item, setItem] = useState({});
  const [startTime] = useState(new Date());
  const [variant, setVariant] = useState(0);
  const query = props.location.search;
  const { retailer, id: retailer_item_id } = queryString.parse(query);
  // console.log('variant', variant);
  // useEffect(() => {
  //   console.log("item", data);
  // }, [data]);

  /**
   * @method scrollToTop
   *
   * @description scrolls to top of page after mount
   */
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [query]);

  useEffect(() => {
    // console.log("item", item);
    if (item.price === 0) {
      setButtonText('product cannot be added to cart');
      setButtonStatus('disabled');
    }
  }, [item]);

  useEffect(() => {
    if (!loggedIn) {
      setButtonText('please login to start shopping');
    } else {
      setButtonText('add to cart');
    }
  }, [loggedIn]);

  /**
   * @method getItemData
   *
   * @description gets item data
   */
  const getItemData = useCallback(() => {
    const getData = () => {
      setLoading(true);
      setError(false);
      axios
        .post(
          apiEndpoint + 'product/details',
          {
            retailer_item_id,
            retailer,
          },
          {
            timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
            // timeout: 666,
            cancelToken: itemCancelTokenSource.token,
          }
        )
        .then((res) => {
          // console.log("product res", res);
          const item = res.data.data;
          setItem(item);
          // console.log('item', item);

          if (item.images) {
            setVariant(item.retailer_item_id);
          } else {
            setError(true);
          }
          setLoading(false);
          // item.other_variants && setVariant(0);
        })
        .catch((error) => {
          // console.log('get product details error', error);
          if (
            new Date().getTime() - startTime <
            process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT
          ) {
            // if (new Date().getTime() - startTime < 666) {
            setTimeout(getData, 1000);
          } else {
            setError(true);
            setLoading(false);
          }
        });
    };
    getData();
  }, [retailer_item_id, retailer, startTime]);

  useEffect(() => {
    getItemData();
    return () => {
      // To cancel previous request use:
      itemCancelTokenSource.cancel('get product data canceled');
      itemCancelTokenSource = new axios.CancelToken.source();
    };
  }, [getItemData]);

  /**
   * @method handleCartButton
   *
   * @description attempts to add item to user cart
   */
  const handleCartButton = () => {
    if (!loggedIn) {
      props.history.push('/auth/sign-in');
      return;
    }
    setButtonText('please wait');
    setButtonStatus('loading');

    // To cancel previous request use:
    cartCancelTokenSource.cancel('add item to cart canceled');
    cartCancelTokenSource = new axios.CancelToken.source();

    // console.log("item", item);

    const { retailer_item_id, retailer } = item;
    // console.log('item', item);
    let variant;
    if (retailer === 'AMAZON_CA' || retailer === 'ALIEXPRESS')
      variant = item.current_variant;
    else {
      const tempVariant = other_variants.find(
        (item) => item.retailer_sku === current_variant.retailer_sku
      );
      variant = tempVariant ? tempVariant.variant : [];
    }
    axios
      .post(
        apiEndpoint + 'cart',
        {
          retailer_item_id,
          retailer,
          variant,
          quantity,
        },
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
          // timeout: 666,
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
          cancelToken: cartCancelTokenSource.token,
        }
      )
      .then((res) => {
        setCart(res.data.data);
        setButtonText('added to cart');
        setButtonStatus('disabled');
        setTimeout(() => {
          setButtonText('add to cart');
          setButtonStatus('enabled');
        }, 1000);
      })
      .catch((error) => {
        setButtonStatus('disabled');
        setButtonText('failed to add item, please try again');
        setTimeout(() => {
          setButtonText('add to cart');
          setButtonStatus('enabled');
        }, 2000);
      });
  };

  const classes = useStyles();

  // console.log("item", item);

  const {
    images,
    title,
    rating,
    price,
    features,
    description,
    other_variants,
    specs,
    current_variant,
  } = item;

  useEffect(() => {
    // console.log("item has changed");
    // console.log("item", item);
  }, [item]);

  return (
    <Translation>
      {(t) => (
        <main className={classes.content}>
          <Helmet>
            <title>
              {title
                ? `${title} - ${t('product details')}`
                : t('product details')}
            </title>
          </Helmet>

          <TwoEightTwoContainer>
            <Grid container spacing={2}>
              {!error ? (
                !loading ? (
                  <>
                    {/* images and specs 1 */}
                    <Hidden xsDown>
                      <Grid item xs={12} sm={7} container>
                        {/* image carousel */}

                        <Grid item xs={12}>
                          <ProductImageCarousel images={images} />
                        </Grid>

                        {/* item specs */}
                        {specs && (
                          <Grid item xs={12} className={classes.itemSpecs}>
                            <>
                              <Typography className={classes.boldFont}>
                                {t('specifications')}
                              </Typography>
                              <div>
                                {specs.map((spec, idx) => (
                                  <Typography key={idx}>{spec}</Typography>
                                ))}
                              </div>
                            </>
                          </Grid>
                        )}
                      </Grid>
                    </Hidden>
                    {/* item basic info */}
                    <Grid xs={12} sm={5} item className={classes.columnFlex}>
                      {/* TITLE */}
                      <Typography variant='h5'>{title}</Typography>
                      {/* RATING */}
                      <Rating
                        classes={{ root: classes.ratingRoot }}
                        value={rating}
                        precision={0.5}
                        size='small'
                        readOnly
                      />
                      {/* PRICE AND RETAILER */}
                      <div>
                        {price === 0 ? (
                          <Typography
                            variant='h6'
                            className={classes.errorText}
                          >
                            {t('this product is currently unavailable')}
                          </Typography>
                        ) : (
                          <Typography
                            variant='h5'
                            className={classes.itemPrice}
                          >
                            {typeof price === 'number'
                              ? numeral(price).format(
                                  process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                                )
                              : price}
                          </Typography>
                        )}
                        <Typography variant='h5' className={classes.fromVendor}>
                          {(() => {
                            switch (retailer.toLocaleLowerCase()) {
                              case 'amazon_ca':
                                return `${t('from')} ${t('amazon')}`;
                              case 'walmart_ca':
                                return `${t('from')} ${t('walmart')}`;
                              case 'aliexpress':
                                return `${t('from')} ${t('aliexpress')}`;
                              default:
                                return `${t('from')} ${t('unknown vendor')}`;
                            }
                          })()}
                        </Typography>
                      </div>

                      {/* FEATURES */}

                      {features && (
                        <Box>
                          <ul className={classes.listItem2}>
                            {features.map((feature, idx) => (
                              <li key={idx} className={classes.listItem}>
                                <Typography>{feature}</Typography>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      )}

                      <Box my={1}>
                        <Grid container>
                          {/* QUANTITY SELECTOR */}
                          {price !== 0 && (
                            <Grid
                              item
                              xs={3}
                              className={classes.quantitySelect}
                            >
                              <Typography className={classes.boldFont}>
                                {t('qty')}
                              </Typography>

                              <Box py={0.5} pr={0.5}>
                                <TextField
                                  value={quantity}
                                  onChange={(event) => {
                                    setQuantity(event.target.value);
                                  }}
                                  type='number'
                                  inputProps={{
                                    min: '1',
                                    max: '50',
                                  }}
                                  variant='outlined'
                                  fullWidth
                                />
                              </Box>
                            </Grid>
                          )}
                          {/* VARIANT SELECTOR AMAZON*/}
                          {(retailer === 'AMAZON_CA' ||
                            retailer === 'ALIEXPRESS') &&
                            other_variants &&
                            other_variants.length > 0 && (
                              <Grid
                                item
                                xs={price === 0 ? 12 : 9}
                                className={classes.fullWidth}
                              >
                                <Typography className={classes.boldFont}>
                                  {t('variant')}
                                </Typography>
                                <Box py={0.5}>
                                  <TextField
                                    value={retailer_item_id}
                                    onChange={(event) => {
                                      props.history.push(
                                        `/product?retailer=${retailer}&id=${event.target.value}`
                                      );
                                    }}
                                    select
                                    className={classes.textField}
                                    variant='outlined'
                                    fullWidth
                                  >
                                    <MenuItem disabled value={retailer_item_id}>
                                      {current_variant.join(' - ')}
                                    </MenuItem>

                                    <MenuItem
                                      disabled
                                      className={classes.dividerMenuItem}
                                    />
                                    {other_variants.map((variant, idx) => (
                                      <MenuItem
                                        key={idx}
                                        value={variant.retailer_item_id}
                                      >
                                        {variant.variant.join(' - ')}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Box>
                              </Grid>
                            )}
                          {/* VARIANT SELECTOR WALMART */}
                          {retailer === 'WALMART_CA' &&
                            other_variants &&
                            other_variants.length > 1 && (
                              <Grid
                                item
                                xs={price === 0 ? 12 : 9}
                                className={classes.fullWidth}
                              >
                                <Typography className={classes.boldFont}>
                                  {t('variant')}
                                </Typography>
                                <Box py={0.5}>
                                  <TextField
                                    value={current_variant.retailer_sku}
                                    onChange={(event) => {
                                      const sku = event.target.value;
                                      const chosenVariant = other_variants.find(
                                        (item) => item.retailer_sku === sku
                                      );

                                      setItem((item) => ({
                                        ...item,
                                        ...chosenVariant,
                                        current_variant: { retailer_sku: sku },
                                      }));
                                    }}
                                    select
                                    className={classes.textField}
                                    variant='outlined'
                                    fullWidth
                                  >
                                    {other_variants.map((variant, idx) => (
                                      <MenuItem
                                        disabled={
                                          variant.retailer_sku ===
                                          current_variant.retailer_sku
                                        }
                                        key={variant.retailer_sku}
                                        value={variant.retailer_sku}
                                      >
                                        {variant.variant.join(' - ')}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Box>
                              </Grid>
                            )}
                        </Grid>
                      </Box>
                      {/* ADD TO CART */}
                      <Box className={classes.centeredFlex}>
                        <Button
                          className={
                            buttonStatus !== 'enabled'
                              ? classes.secondaryButton
                              : classes.mainButton
                          }
                          aria-label='Add to shopping cart'
                          onClick={handleCartButton}
                          fullWidth
                          disabled={buttonStatus !== 'enabled'}
                        >
                          {t(buttonText)}
                          {buttonStatus === 'loading' && (
                            <CircularProgress
                              size={24}
                              className={classes.absolutePosition}
                            />
                          )}
                        </Button>
                      </Box>
                    </Grid>
                    {/* images and specs 2 */}
                    <Hidden smUp>
                      <Grid item xs={12} sm={7} container>
                        {/* image carousel */}

                        <Grid item xs={12}>
                          <ProductImageCarousel images={images} />
                        </Grid>

                        {/* item specs */}
                        {specs && (
                          <Grid item xs={12} className={classes.itemSpecs}>
                            <>
                              <Typography className={classes.boldFont}>
                                {t('specifications')}
                              </Typography>
                              <div>
                                {specs.map((spec, idx) => (
                                  <Typography key={idx}>{spec}</Typography>
                                ))}
                              </div>
                            </>
                          </Grid>
                        )}
                      </Grid>
                    </Hidden>
                    {/* item desc */}
                    {description && (
                      <Grid item xs={12}>
                        <>
                          <Typography className={classes.boldFont}>
                            {t('description')}
                          </Typography>
                          <Box className={classes.itemDescriptionContainer}>
                            {parse(description)}
                          </Box>
                        </>
                      </Grid>
                    )}
                  </>
                ) : (
                  // loading items
                  <>
                    {/* image carousel */}
                    <Backdrop className={classes.backdrop} open={true}>
                      <CircularProgress color='primary' />
                    </Backdrop>
                    <Grid item xs={12} sm={7} container spacing={1}>
                      <Grid item xs={12}>
                        <Skeleton height={400} variant='rect' />
                      </Grid>
                      {[...Array(4)].map((_item, idx) => (
                        <Grid key={idx} item xs={3}>
                          <Skeleton height={150} variant='rect' />
                        </Grid>
                      ))}
                    </Grid>
                    {/* item details */}
                    <Grid item xs={12} sm={5}>
                      <Skeleton height={20} width='100%' />
                      <Box mb={5}>
                        <Skeleton height={20} width='40%' />
                      </Box>
                      {[...Array(8)].map((_item, idx) => (
                        <Skeleton key={idx} height={20} width='100%' />
                      ))}
                      <Skeleton height={20} width='50%' />
                    </Grid>
                    {/* description */}
                    <Grid item xs={12} sm={7}>
                      {[...Array(10)].map((_item, idx) => (
                        <Skeleton key={idx} height={20} width='90%' />
                      ))}
                      <Skeleton height={20} width='80%' />
                    </Grid>
                    {/* specs */}
                    <Grid item xs={12} sm={5}>
                      {[...Array(10)].map((_item, idx) => (
                        <Skeleton key={idx} height={20} width='100%' />
                      ))}
                      <Skeleton height={20} width='20%' />
                    </Grid>
                  </>
                )
              ) : (
                <Grid item xs={12}>
                  <Box className={classes.errorContainer}>
                    <Typography>{`${t("couldn't load item")}...`}</Typography>
                    <Button
                      onClick={() => {
                        // props.history.goBack();
                        // props.history.go();
                        getItemData();
                      }}
                    >
                      {t('click here to refresh')}
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>

            {retailer && title && (
              <ItemRecommendations
                retailer={retailer}
                title={title}
                currentItemId={retailer_item_id}
                parentLoading={loading}
              />
            )}
          </TwoEightTwoContainer>
        </main>
      )}
    </Translation>
  );
};

export default Product;
