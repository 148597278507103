/**
 * @module PoppingCart
 */
//React imports
import React, { useState, useEffect } from "react";
import Image from "material-ui-image";
import { useHistory } from "react-router-dom";
import {
  Button,
  IconButton,
  Typography,
  Badge,
  Grid,
  Paper,
  Popover,
  DialogContent,
  DialogTitle,
  Divider,
  Box,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
//Other libraries
import { Translation } from "react-i18next";
import { useGlobalState } from "../../GlobalState";
import axios from "axios";
import numeral from "numeral";
//Icons
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
// let cancelTokenSource = new axios.CancelToken.source();

const useStyles = makeStyles((theme) => {
  const numberOfLines = 2;
  const lineHeight = 21;
  return {
    iconButtonCircle: {
      fill: "#fff",
      stroke: theme.palette.primary.main,
      strokeWidth: "1px",
    },
    absolutePosition: { position: "absolute" },
    cartIconContainer: {
      display: "flex",
      alignItems: "center",
    },
    cartIcon: {
      // backgroundColor: theme.palette.primary.main,
      // paddingRight:'4px',
      color: "#fff",
    },
    cartBadge: {
      backgroundColor: "rgb(246,186,70)",
      color: "#fff",
      fontSize: "16px",
      fontWeight: "bold",
      // marginLeft: '0px',
      padding: "3px 0px",
      width: "26px",
      borderRadius: "6px",
    },
    badge: {
      height: 22,
      width: 22,
      minWidth: 22,
      fontSize: "0.75rem",
      transform: "scale(1) translate(80%, -75%)",
    },
    centeredFlex: {
      alignItems: "center",
      justifyItems: "center",
      alignContent: "center",
      justifyContent: "center",
      display: "flex",
    },
    twoLineText: {
      overflow: "hidden",
      display: "-webkit-box",
      lineHeight: `${lineHeight}px`,
      maxHeight: `${lineHeight * numberOfLines}px`,
      maxWidth: "100%",
      WebkitLineClamp: `${numberOfLines}`,
      WebkitBoxOrient: "vertical",
    },
    shoppingCartIcon: { fontSize: 26 },
    zeroPadding: { padding: "0px !important" },
    poppingCartButtons: {
      display: "flex",
      justifyContent: "space-around",
    },
    undecoratedLink: { textDecoration: "none", color: "black" },
  };
});

/**
 * @method PoppingCart
 * @description renders the popping cart in the app bar
 * @see module:AppBar
 */
const PoppingCart = ({ style }) => {
  const [session] = useGlobalState("session");
  const [cart, setCart] = useGlobalState("cart");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cartAnchorEl, setCartAnchorEl] = useState(null);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const history = useHistory();
  const classes = useStyles();
  /**
   * @method getData
   *
   * @description gets user cart content
   */
  const getData = useCallback(() => {
    // cancelTokenSource.cancel("Operation canceled");
    // cancelTokenSource = new axios.CancelToken.source();
    if (localStorage.getItem("session")) {
      setLoading(true);
      setError(false);
      axios
        .get(apiEndpoint + "cart", {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
          headers: { Authorization: `Bearer ${session.token}` },
          // cancelToken: cancelTokenSource.token,
        })
        .then((res) => {
          setCart(res.data.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setCart([]);
    }
  }, [session.token, setCart]);

  useEffect(() => {
    getData();
  }, [getData]);

  /**
   * @method sumCart
   *
   * @description sums item price subtotal
   * @returns {number} item price subtotal
   */
  const sumCart = () => {
    let sum = 0;
    cart.forEach((item) => (sum += item.price * item.quantity));
    return sum;
  };

  /**
   * @method handleCartButtonClick
   *
   * @description opens/closes the popping cart and calls getData
   */
  const handleCartButtonClick = (event) => {
    getData();
    const { currentTarget } = event;
    setCartAnchorEl(currentTarget);
    setIsCartOpen(!isCartOpen);
  };

  const r = 60;
  const sumCartItems = () => cart.reduce((acc, cur) => acc + cur.quantity, 0);
  return (
    <Translation>
      {(t) => (
        <Box style={style}>
          <Box className={classes.cartIconContainer}>
            <IconButton
              id="shoppingCart"
              onClick={handleCartButtonClick}
              className={classes.cartIcon}
            >
              <ShoppingCartIcon className={classes.shoppingCartIcon} />
            </IconButton>
            {/* {sumCartItems() === 0 && ( */}
            <Box textAlign="center" className={classes.cartBadge}>
              {sumCartItems()}
            </Box>
            {/* )} */}
          </Box>

          <Popover
            open={isCartOpen}
            anchorEl={cartAnchorEl}
            PaperProps={{ style: { width: "350px", zIndex: 11 } }}
            onClose={() => setIsCartOpen(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper>
              {!error ? (
                !loading ? (
                  cart.length === 0 ? (
                    <Box p={2} className={classes.centeredFlex}>
                      <Typography>
                        {t("your cart is empty, continue shopping!")}
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <DialogTitle>{t("your cart content")}</DialogTitle>
                      <DialogContent>
                        <Grid container spacing={1}>
                          {cart.map((item, idx) => (
                            <Grid container key={idx}>
                              <Grid container>
                                {/* name and price/quantity */}
                                <Grid item xs={8}>
                                  <Box py={1}>
                                    <Grid container>
                                      {/* name */}
                                      <Grid item xs={12}>
                                        <Tooltip
                                          disableFocusListener
                                          disableTouchListener
                                          arrow
                                          title={item.title}
                                          placement="left"
                                          enterDelay={700}
                                        >
                                          <Link
                                            className={classes.undecoratedLink}
                                            onClick={() => {
                                              setIsCartOpen(false);
                                            }}
                                            to={`/product?retailer=${item.retailer}&id=${item.retailer_item_id}`}
                                          >
                                            <Typography
                                              variant="caption"
                                              className={classes.twoLineText}
                                            >
                                              {item.title}
                                            </Typography>
                                          </Link>
                                        </Tooltip>
                                      </Grid>
                                      {/* price/quantity */}
                                      <Grid item xs={12}>
                                        {`${numeral(item.price).format(
                                          process.env
                                            .REACT_APP_DEFAULT_CURRENCY_FORMAT
                                        )}x${item.quantity}`}
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                                {/* image */}
                                <Grid
                                  item
                                  xs={4}
                                  className={classes.centeredFlex}
                                >
                                  <Link
                                    onClick={() => {
                                      setIsCartOpen(false);
                                    }}
                                    to={`/product?retailer=${item.retailer}&id=${item.retailer_item_id}`}
                                  >
                                    <Image
                                      disableSpinner={true}
                                      style={{ padding: "0" }}
                                      imageStyle={{
                                        maxHeight: "60px",
                                        maxWidth: "60px",
                                        position: "relative",
                                        width: "unset",
                                        height: "unset",
                                      }}
                                      src={item.image_url}
                                    />
                                  </Link>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Box my={0.5}>
                                  <Divider />
                                </Box>
                              </Grid>
                            </Grid>
                          ))}
                          {/* total */}
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.zeroPadding}
                          >
                            <Grid item xs={8}>
                              <Typography>{t("total")}</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.centeredFlex}>
                              {numeral(sumCart()).format(
                                process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Box my={0.5}>
                                <Divider />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.poppingCartButtons}
                            >
                              <Button
                                color="secondary"
                                onClick={() => {
                                  setIsCartOpen(false);
                                  history.push("/user/cart");
                                }}
                                aria-label="view"
                              >
                                {t("view cart")}
                              </Button>

                              <Button
                                color="secondary"
                                onClick={() => {
                                  setIsCartOpen(false);
                                  history.push("/user/checkout");
                                }}
                                aria-label="checkout"
                              >
                                {t("checkout")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </>
                  )
                ) : (
                  <Box p={6} className={classes.centeredFlex}>
                    <CircularProgress />
                  </Box>
                )
              ) : (
                <Box p={2} className={classes.centeredFlex}>
                  <Grid container>
                    <Box mb={1}>
                      <Grid item xs={12}>
                        <Typography>
                          {t("an unexpected error occurred. please try again")}
                        </Typography>
                      </Grid>
                    </Box>
                    <Grid item xs={12} className={classes.poppingCartButtons}>
                      <Button
                        color="secondary"
                        onClick={() => {
                          setIsCartOpen(false);
                          history.push("/user/cart");
                        }}
                        aria-label="view"
                      >
                        {t("view cart")}
                      </Button>

                      <Button
                        color="secondary"
                        onClick={() => {
                          setIsCartOpen(false);
                          history.push("/user/checkout");
                        }}
                        aria-label="checkout"
                      >
                        {t("checkout")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Paper>
          </Popover>
        </Box>
      )}
    </Translation>
  );
};
export default PoppingCart;
