import React from "react";
import DataFetcher from "../../common/DataFetcher";
import { useGlobalState } from "../../GlobalState";

export default function Temp() {
  const [session] = useGlobalState("session");
  const token = session.token.slice();

  return (
    <DataFetcher
      onLoading={<h2>loading</h2>}
      onError={<h2>error</h2>}
      route={"search"}
      headers={{ Authorization: `Bearer ${token}` }}
      method="post"
      requestData={{}}
    >
      {({ data }) => <h2>{JSON.stringify(data)}</h2>}
    </DataFetcher>
  );
}
