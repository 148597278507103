/**
 * @module TopPicks
 */
//React imports
import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import TwoEightTwoContainer from "../../../common/TwoEightTwoContainer";

import axios from "axios";

import { widthsToCards } from "./ItemCarousel";
import ItemSlider from "./ItemSlider";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

/**
 * @method TopPicks
 * @description top picks component, it's a wrapper to fetch the
 * top picks data and maps it to different item sliders.\
 * @see module:ItemSlider
 * @see module:ItemCarousel
 */
const TopPicks = () => {
  const [topPicks, setTopPicks] = useState([]);
  const [loading, setLoading] = useState(true);

  /**
   * @method getData
   *
   * @description fetches top picks data
   */
  const getData = useCallback(() => {
    axios
      .get(apiEndpoint + "home/top-picks", {
        timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT
      })
      .then(res => {
        setTopPicks(res.data.data.top_picks);
        setLoading(false);
      })
      .catch(() => {
        setTimeout(getData, 1000);
      });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);
  /**
   * @member {Object[]} carousels
   *
   * @description creates an array of refs (one for each carousel),
   * and passes it down to the item carousel
   * through the item slider, these refs are used for the arrow buttons to control
   * the carousel since the state of the carousel is maintained by it
   */
  const carousels = [...Array(topPicks.length)].map(() => React.createRef());

  return (
    <TwoEightTwoContainer>
      {!loading ? (
        // map topPicks to sliders of items
        topPicks.map((item, idx) => (
          <ItemSlider item={item} key={idx} refCarrier={carousels[idx]} />
        ))
      ) : (
        <>
          <Grid item xs={12}>
            <Skeleton height={20} width="20%" />
          </Grid>
          {[...Array(4)].map((_item, idx) => {
            return (
              <Grid item key={idx} {...widthsToCards}>
                <Skeleton height="200px" width="90%" variant="rect" />
                <Skeleton width="80%" height={20} />
                <Skeleton width="20%" height={20} />
              </Grid>
            );
          })}
        </>
      )}
    </TwoEightTwoContainer>
  );
};

export default TopPicks;
