/**
 * @module ItemSlider
 */
//React imports
import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { styles } from "../styles";
import ItemCarousel from "./ItemCarousel";
import IconWithCircle from "../../../common/IconWithCircle";
import { makeStyles } from "@material-ui/styles";
import { useGlobalState } from "../../../GlobalState";
import { Translation } from "react-i18next";
import { widthsToCards } from "./ItemCarousel";
import useWidth from "./../../../common/useWidth";

const useStyles2 = makeStyles(styles);
const useStyles = makeStyles(theme => ({
  root: { margin: "10px 0" },
  title: { fontSize: 14 },
  horizontalDivider: {
    width: "10%",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginBottom: "10px"
  },
  flexEnd: { display: "flex", justifyContent: "flex-end" }
}));

/**
 * @method ItemSlider
 * @description top picks item slider.
 * renders item title, back and forward arrows and the actual item carousel
 * @param {Object} props component props
 * @param {Object} props.item item contains title and items array
 * @param {Object} props.refCarrier reference carried down from top picks and passed down to carousel
 * @see module:ItemCarousel
 */
const ItemSlider = props => {
  const { item, refCarrier } = props;
  const [language] = useGlobalState("language");

  const classes2 = useStyles2();
  const classes = useStyles();
  const width = useWidth();

  return (
    item.items &&
    item.items.length > 0 && (
      <Translation>
        {t => (
          <Grid container className={classes.root}>
            <Grid item xs={2} />
            <Grid item xs={8}>
              <Grid item xs={12} className={classes2.centeredFlex}>
                <Typography variant="h6">{t(item.title)}</Typography>
              </Grid>
              <Grid item xs={12} className={classes2.centeredFlex}>
                <div className={classes.horizontalDivider} />
              </Grid>
            </Grid>

            {item.items && item.items.length > 12 / widthsToCards[width] && (
              <Grid item xs={2} className={classes.flexEnd}>
                <IconWithCircle
                  r={30}
                  onClickFunction={() => {
                    refCarrier.current.prev();
                  }}
                  fontAwesomeClassName={
                    language === "en"
                      ? "fas fa-caret-left"
                      : "fas fa-caret-right"
                  }
                  fillColor="#fff"
                />
                <IconWithCircle
                  r={30}
                  onClickFunction={() => {
                    refCarrier.current.next();
                  }}
                  fontAwesomeClassName={
                    language === "en"
                      ? "fas fa-caret-right"
                      : "fas fa-caret-left"
                  }
                  fillColor="#fff"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ItemCarousel carouselRef={refCarrier} item={item} />
            </Grid>
          </Grid>
        )}
      </Translation>
    )
  );
};

export default ItemSlider;
