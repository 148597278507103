/**
 * @module GlobalState
 * @descriptionglobal state, collects data about user preferences and initializes the global state then the application
 */
import React from 'react';
import AxiosWrapper from './AxiosWrapper';
import { createGlobalState } from 'react-hooks-global-state';
import jwt from 'jsonwebtoken';

// console.log(jwt);
//arabic/english fonts
export const arabicFont = "Cairo, 'Source Sans Pro', sans-serif";
export const englishFont = "'Source Sans Pro', Cairo, sans-serif";
// get settings
// language
let language = localStorage.getItem('language');
if (!language) {
  language = 'en';
  localStorage.setItem('language', 'en');
}

if (language === 'ar') {
  document.body.style.direction = 'rtl';
  document.body.style.fontFamily = arabicFont;
}
// session
let session = JSON.parse(localStorage.getItem('session'));

let loggedIn = false;
if (!session) {
  session = {};
} else if (
  session &&
  new Date().getTime() / 1000 > jwt.decode(session.token).exp
) {
  loggedIn = true;
} else {
  // console.log("token is not expired");
  loggedIn = true;
}

/* Application initialization*/
//create global store
const { GlobalStateProvider, useGlobalState } = createGlobalState({
  language,
  loggedIn,
  session,
  userData: {},
  cart: [],
});
export { GlobalStateProvider, useGlobalState };

/**
 * @method GlobalState
 * @description
 * global state wrapper, returns axios wrapper
 */

function GlobalState(props) {
  return (
    <GlobalStateProvider>
      <AxiosWrapper />
    </GlobalStateProvider>
  );
}
export default GlobalState;
