/**
 * @module AppBar
 */
//React imports
import React, { useState } from "react";
import Image from "material-ui-image";
import { Link, useHistory } from "react-router-dom";
//Material UI imports
import { fade } from "@material-ui/core/styles";
import {
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  MenuItem,
  withStyles,
  Grid,
  Paper,
  FormControl,
  Select,
  Popper,
  Icon,
  Box,
  CircularProgress,
  Hidden,
  Button,
  Container,
  Tooltip,
} from "@material-ui/core";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
//Other libraries
import { Translation } from "react-i18next";
import classNames from "classnames";
import { useGlobalState, arabicFont, englishFont } from "../../GlobalState";
import TwoEightTwoContainer from "../TwoEightTwoContainer";
import Img from "react-image";
import ErrorMessageBar from "./ErrorMessageBar";
import PoppingCart from "./PoppingCart";
import SearchIcon from "@material-ui/icons/Search";
import AccountIcon from "@material-ui/icons/PermIdentity";
import amazonLogo from "./amazon.png";
import walmartLogo from "./walmart.png";
import aliexpressLogo from "./ali-express.png";
import libyaFlag from "./libya-flag.png";
import tunisiaFlag from "./tunisia-flag.png";
import egyptFlag from "./egypt-flag.png";
import algeriaFlag from "./algeria-flag.png";
import moroccoFlag from "./morocco-flag.png";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import queryString from "query-string";
import { Class } from "@material-ui/icons";

const netgeek_en_logo = require("../../assets/images/Logo-en.png");
const netgeek_ar_logo = require("../../assets/images/Logo-ar.png");

//flags

const ukFlag = require("./ukFlag.png");
//vendors
const vendors = [
  { title: "Amazon", name: "amazon", value: "AMAZON_CA", logo: amazonLogo },
  { title: "Walmart", name: "walmart", value: "WALMART_CA", logo: walmartLogo },
  {
    title: "Aliexpress",
    name: "aliexpress",
    value: "ALIEXPRESS",
    logo: aliexpressLogo,
  },
];
const countries = [
  { name: "Libya", flag: libyaFlag },
  { name: "Tunisia", flag: tunisiaFlag },
  { name: "Egypt", flag: egyptFlag },
  { name: "Algeria", flag: algeriaFlag },
  { name: "Morocco", flag: moroccoFlag },
];
//component style classes
const useStyles = makeStyles((theme) => {
  const fontSize = "16px";
  const headerHeight = "90px";
  return {
    pointerCursor: { cursor: "pointer" },
    userFullName: { textAlign: "end" },
    renderLoginUser: {
      justifyContent: "flex-end",
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
    },
    netjeekLogo: {
      display: "flex",
      backgroundColor: "#fff",
      paddingLeft: "16px",
      justifyContent: "space-between",
    },
    vendorSelectIcon: {
      top: "calc(50% - 7px)",
      right: "2px",
      fontSize: "1rem",
    },
    vendorInput: {
      height: "100%",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      justifyItems: "center",
      paddingRight: "20px",
    },
    vendorSelect: {
      margin: "0",
      width: "150px",
    },
    vendorSelectMenu: {
      padding: 0,
    },
    vendorSelectSelect: { height: "100%" },
    root: { display: isMobile ? "none" : "flex", flexDirection: "column" },
    undecoratedLink: {
      textDecoration: "none",
      color: theme.palette.primary.contrastText,
    },
    shippingCounteySelection: {
      display: "inline-flex",
      alignItems: "center",
      justifyItems: "center",
      "&:hover": { cursor: "pointer" },
      padding: "0px",
    },
    shippingContryImage: {
      margin: "1px 1px 1px 8px",
      height: "25px",
      // borderRadius: "2px",
    },
    selectedShippingCountryName: {
      margin: "2px",
      fontWeight: "bold",
      fontSize,
      marginLeft: "4px",
      minWidth: "30px",
      color: "#fff",
    },
    shippingCountryName: {
      margin: "2px",
      fontWeight: "bold",
      fontSize,
      marginLeft: "4px",
      color: "#000",
    },
    centeredFlex: {
      width: "100%",
      alignItems: "center",
      justifyItems: "center",
      alignContent: "center",
      justifyContent: "center",
      display: "flex",
    },
    justifyStartFlex: {
      width: "100%",
      alignItems: "center",
      justifyItems: "center",
      alignContent: "center",
      justifyContent: "flex-start",
      display: "flex",
    },
    search: {
      height: `calc(${headerHeight} / 2)`,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: "0px",
      position: "relative",
      borderRadius: "5px",
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      // marginRight: theme.spacing(2),
      // marginLeft: 0,
    },
    searchIconButton: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      alignContent: "center",
      color: "#fff",
      justifyContent: "center",
      background: "rgb(246,186,70)",
      // "&:hover": { backgroundColor: "#ffffff00" },
    },
    searchIconContainer: {
      backgroundColor: "#fff",
      width: "155px",
      border: "0",
      // borderLeft: `1px solid ${theme.palette.primary.light}`,
      borderRadius: "0 5px 5px 0",
    },
    inputRoot: {
      color: theme.palette.primary.main,
      backgroundColor: "#fff",
      width: "100%",
      height: "100%",
      display: "flex",
    },
    inputInput: {
      padding: theme.spacing(0, 1, 0, 2),
      transition: theme.transitions.create("width"),
      backgroundColor: "#fff",
      fontWeight: "bold",
      height: "100%",
      fontSize,
    },
    appBar: {
      height: headerHeight,
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      alignContent: "center",
      justifyContent: "space-between",
      // backgroundColor: 'rgb(57,188,218)',
      // marginTop: "10px",
      // marginBottom: "10px"
    },
    // topBar: {
    //   display: "flex",
    //   justifyContent: "space-between",
    //   height: "30px",
    //   backgroundColor: theme.palette.secondary.veryLight,
    //   alignItems: "center",
    //   justifyItems: "center",
    //   minHeight: "20px",
    // },
    languageSelect: {
      "&:hover": { cursor: "default" },
      "&:focus": { backgroundColor: "rgba(0, 0, 0, 0.0)" },
      zIndex: 4,
    },
    languageSelectorSelection: {
      display: "inline-flex",
      alignItems: "center",
      justifyItems: "center",
      "&:hover": { cursor: "pointer" },
      padding: "0px",
      color: "inhirit",
    },
    languageSelectorItem: {
      margin: "2px",
      marginLeft: "2px",
      fontWeight: "bold",
      fontSize,
      color: "inhirit",
    },
    languageSelectorItemImage: {
      margin: "2px",
      height: 16,
    },
    signInButton: {
      margin: "2px",
      marginLeft: "2px",
      fontWeight: "bold",
      fontSize,
      color: "inhirit",
      marginLeft: "10px",
    },
    dropDownArrow: { fontSize: 12, marginLeft: "3px" },
    socialIcon: {
      fontSize: 12,
      marginRight: "6px",
    },
    searchField: { width: "90%" },
    searchFieldForm: { width: "100%", height: "100%" },
    searchIcon: {
      fontSize: 24,
      marginLeft: "8px",
      marginRight: "10px",
      cursor: "pointer",
    },
    userIcon: { fontSize: 14, marginRight: "3px" },
    socialIconsContainer: {
      justifyContent: "flex-start",
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
    },
    languageSelectionsContainer: {
      alignItems: "center",
      justifyItems: "center",
      justifyContent: "flex-end",
      display: "flex",
    },
    cartItemContainer: {
      display: "flex",
      alignItems: "center",
    },
    fullWidth: {},
    leftTrangle: {
      width: 0,
      height: 0,
      borderTop: `calc(${headerHeight} / 2) solid transparent`,
      borderRight: "40px solid rgb(57,188,218)",
      borderBottom: `calc(${headerHeight} / 2) solid transparent`,
    },
    selectStyle: {
      padding: "0px !important",
    },
    vendorImgStyle: {
      cursor: "pointer",
      marginLeft: "10px",
      marginRight: "10px",
      width: 18,
    },
    selectedVendorStyle: {
      backgroundColor: "#39bcda",
    },
    vendorSelectionStyle: {
      backgroundColor: "#fff",
    },
    menuItemStyle: {
      fontSize,
    },
  };
});
/**
 * @method BootstrapInput
 * @description custom input field helper component for the search field in the app bar
 * @see module:AppBar
 */
const BootstrapInput = withStyles((theme) => ({
  input: {
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    border: "0",
    borderRight: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "5px 0 0 5px",
    padding: "0px 10px",
    color: theme.palette.secondary.dark, //font color, offwhite
    fontSize: 14,
    width: "100%",
    height: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "inherit",
    backgroundColor: theme.palette.secondary.light,
    "&:focus": {
      borderRadius: "5px 0 0 5px",
      backgroundColor: theme.palette.secondary.veryLight,
    },
  },
}))(InputBase);
/**
 * @description helper component that renders a vertical divider
 * @function VerticalDivider
 * @see module:AppBar
 * @param {number} margin margin value for the divider
 */
const VerticalDivider = (props) => {
  const { margin } = props;
  const classes = makeStyles((theme) => ({
    divider: {
      height: 25,
      borderRight: `1px solid ${theme.palette.primary.main}`,
      margin: margin,
    },
  }))();
  return <div className={classes.divider} />;
};
/**
 * @method AppBar
 * @description app bar component
 * @requires {@link VerticalDivider}
 * @requires {@link BootstrapInput}
 */
function AppBar() {
  const classes = useStyles();
  const [selectedVendor, setSelectedVendor] = useState("AMAZON_CA");
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [languageSelectMenuAnchorEl, setLanguageSelectMenuAnchorEl] = useState(
    null
  );
  const [selectedCountry, setSelectedCountry] = useState("Libya");
  const [, setLanguage] = useGlobalState("language");
  const [loggedIn, setLoggedIn] = useGlobalState("loggedIn");
  const [userData, setUserData] = useGlobalState("userData");
  const [, setSession] = useGlobalState("session");

  const history = useHistory();

  useEffect(() => {
    // console.log('history.location.pathname', history.location.pathname);
    if (history.location.pathname === "/search") {
      const { query, retailer } = queryString.parse(history.location.search);
      setSearchString(query);
      setSelectedVendor(retailer);
    }
  }, [history.location.pathname, history.location.search]);

  const handleVendorChange = (e) => {
    // console.log('e.target', e.target.name);
    const vendor = e.target.name;
    setSelectedVendor(vendor);
  };
  /**
   * @method handleSearchButton
   * @description redirects to the search page
   */
  const handleSearchButton = (event) => {
    event.preventDefault();
    if (searchString.length !== 0 && selectedVendor) {
      const searchUrl = `/search?query=${searchString}&retailer=${selectedVendor}`;
      history.push(searchUrl);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchString(event.target.value);
  };

  /**
   * @method renderProfileMenu
   * @description renders user profile menu
   */

  const renderProfileMenu = () => {
    const isMenuOpen = Boolean(profileMenuAnchorEl);
    /**
     * @method handleProfileMenuOpen
     * @description sets profile menu state to open
     */
    const handleProfileMenuOpen = (event) => {
      setProfileMenuAnchorEl(event.currentTarget);
    };
    /**
     * @method handleProfileMenuClose
     * @description sets profile menu state to closed
     */
    const handleProfileMenuClose = () => {
      setProfileMenuAnchorEl(null);
    };
    const { first_name: firstName, last_name: lastName } = userData;
    // console.log('firstName', firstName);
    return (
      <Translation>
        {(t) => (
          <div
            onMouseEnter={handleProfileMenuOpen}
            onMouseLeave={handleProfileMenuClose}
          >
            <div
              className={classes.centeredFlex}
              onClick={handleProfileMenuOpen}
            >
              {firstName ? (
                <div className={classes.centeredFlex}>
                  <AccountIcon
                    style={{
                      fontSize: "30px",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                  />
                </div>
              ) : (
                <CircularProgress
                  size={20}
                  style={{ color: "#fff", marginLeft: "10px" }}
                />
              )}
            </div>
            <Popper
              onMouseLeave={handleProfileMenuClose}
              anchorEl={profileMenuAnchorEl}
              open={isMenuOpen}
              onClose={handleProfileMenuClose}
            >
              <Paper>
                <MenuItem disabled>
                  <Typography
                    variant="caption"
                    className={classes.menuItemStyle}
                  >
                    {firstName + " " + lastName}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleProfileMenuClose();
                    history.push("/user/profile/settings");
                  }}
                >
                  <Typography
                    variant="caption"
                    className={classes.menuItemStyle}
                  >
                    {t("account settings")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleProfileMenuClose();
                    history.push("/user/profile/orders");
                  }}
                >
                  <Typography
                    variant="caption"
                    className={classes.menuItemStyle}
                  >
                    {t("orders")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setLoggedIn(false);
                    localStorage.removeItem("session");
                    handleProfileMenuClose();
                    history.push("/");
                    setUserData({});
                    setSession({});
                  }}
                >
                  <Typography
                    variant="caption"
                    className={classes.menuItemStyle}
                  >
                    {t("logout")}
                  </Typography>
                </MenuItem>
              </Paper>
            </Popper>
          </div>
        )}
      </Translation>
    );
  };
  /**
   * @method renderLanguageSelection
   * @description renders language selection menu
   */
  const renderLanguageSelection = (i18n) => {
    /**
     * @method setLanguageToArabic
     * @description changes website language to Arabic
     */
    const setLanguageToArabic = (i18n) => {
      document.body.style.direction = "rtl";
      document.body.style.fontFamily = arabicFont;
      i18n.i18n.changeLanguage("ar");
      setLanguage("ar");
      localStorage.setItem("language", "ar");
    };
    /**
     * @method setLanguageToEnglish
     * @description changes website language to English
     */
    const setLanguageToEnglish = (i18n) => {
      document.body.style.direction = "ltr";
      document.body.style.fontFamily = englishFont;
      i18n.i18n.changeLanguage("en");
      setLanguage("en");
      localStorage.setItem("language", "en");
    };

    const isLanguageSelectMenuOpen = Boolean(languageSelectMenuAnchorEl);
    /**
     * @member  arabicSelection
     * @description renders the libyan logo and Arabic text
     */
    const arabicSelection = (
      <div className={classes.languageSelectorSelection}>
        {/* <Img className={classes.languageSelectorItemImage} src={libyaFlag} /> */}
        <Typography className={classes.languageSelectorItem}>
          العربية
        </Typography>
      </div>
    );
    /**
     * @member  englishSelection
     * @description renders the UK logo and English text
     */
    const englishSelection = (
      <div className={classes.languageSelectorSelection}>
        {/* <Img className={classes.languageSelectorItemImage} src={ukFlag} /> */}
        <Typography
          className={classes.languageSelectorItem}
          // color="primary"
        >
          English
        </Typography>
      </div>
    );
    /**
     * @method handleLanguageSelectMenuOpen
     * @description sets language menu state to open
     */
    const handleLanguageSelectMenuOpen = (event) => {
      setLanguageSelectMenuAnchorEl(event.currentTarget);
    };
    /**
     * @method handleLanguageSelectMenuClose
     * @description sets language menu state to closed
     */
    const handleLanguageSelectMenuClose = () => {
      setLanguageSelectMenuAnchorEl(null);
    };

    return (
      <div
        onMouseEnter={handleLanguageSelectMenuOpen}
        onMouseLeave={handleLanguageSelectMenuClose}
        onClick={handleLanguageSelectMenuOpen}
      >
        <div className={classes.centeredFlex} style={{ color: "#fff" }}>
          {i18n.lng === "ar" ? arabicSelection : englishSelection}
        </div>

        <Popper
          onClose={handleLanguageSelectMenuClose}
          onMouseLeave={handleLanguageSelectMenuClose}
          className={classes.languageSelect}
          open={isLanguageSelectMenuOpen}
          anchorEl={languageSelectMenuAnchorEl}
        >
          <Paper>
            <MenuItem
              onClick={() => setLanguageToArabic(i18n)}
              style={{ color: "#000" }}
            >
              {arabicSelection}
            </MenuItem>
            <MenuItem
              onClick={() => setLanguageToEnglish(i18n)}
              style={{ color: "#000" }}
            >
              {englishSelection}
            </MenuItem>
          </Paper>
        </Popper>
      </div>
    );
  };
  /**
   * @method renderSocialIcons
   *
   * @description renders social icons
   */
  const renderSocialIcons = () => {
    const sites = [
      {
        link: "https://www.snapchat.com/add/Netjeek.com",
        icon: "fab fa-snapchat",
      },
      {
        link: "https://www.instagram.com/netjeek.com.ly",
        icon: "fab fa-instagram",
      },
      {
        link:
          "https://www.facebook.com/netjeek.netjeekcom.3?__cft__[0]=AZVTMrjn9yEoK-1llKTMSvfy2hGCzOF5Tdr9_8IjF4VqRuDM44Rui9FHc4h4SGrGcMDEmgShjTsiwsoEkBfQ7YS9Ew-43xRco-DxJxK0odXoxlXbPAjvhvecTLi5zAHlcAB3ztDVoMNBstEKz7RqMloD&__tn__=-]C%2CP-R",
        icon: "fab fa-facebook-f",
      },
    ];
    return (
      <div>
        {sites.map((site, idx) => (
          <a
            onClick={(event) => {
              event.preventDefault();
              window.open(site.link);
            }}
            key={idx}
            href={site.link}
            className={classes.undecoratedLink}
          >
            <Icon className={classNames(classes.socialIcon, site.icon)} />
          </a>
        ))}
      </div>
    );
  };
  const renderVendorIcons = () => {
    return (
      <Translation>
        {(t) => (
          <Box display="flex">
            {vendors.map((vendor) => {
              return (
                <Box
                  display="flex"
                  onClick={(vendor) => handleVendorChange(vendor)}
                  alignItems="center"
                  key={vendor.value}
                  className={
                    selectedVendor === vendor.value
                      ? classes.selectedVendorStyle
                      : classes.vendorSelectionStyle
                  }
                >
                  <VerticalDivider margin={"0px"} />
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    arrow
                    title={vendor.title}
                    placement="top"
                    enterDelay={700}
                  >
                    <img
                      src={vendor.logo}
                      name={vendor.value}
                      className={classes.vendorImgStyle}
                    />
                  </Tooltip>
                </Box>
              );
            })}
          </Box>
        )}
      </Translation>
    );
  };
  /**
   * @method {class} renderSearchBar
   *
   * @description renders the search bar
   */
  const renderSearchBar = () => {
    return (
      <Translation>
        {(t) => (
          <div className={classes.search}>
            {/* vendor select  */}
            {/* <FormControl className={classes.vendorSelect}>
              <Select
                className={classes.vendorSelectSelect}
                value={vendor}
                onChange={handleVendorChange                input={<BootstrapInput name='vendor' id='vendor-name' />}
                classes={{
                  icon: classes.vendorSelectIcon,
                  root: classes.vendorInput,
                }}
                MenuProps={{ classes: { list: classes.vendorSelectMenu } }}
              >
                {vendors.map((vendor) => (
                  <MenuItem value={vendor.value} key={vendor.value}>
                    <Typography>{t(vendor.name)}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            {/* search field */}
            <div className={classes.searchField}>
              <form
                onSubmit={handleSearchButton}
                className={classes.searchFieldForm}
              >
                <InputBase
                  value={searchString}
                  type="text"
                  placeholder={t("What are you looking for?")}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  onChange={handleSearchInputChange}
                  inputProps={{ "aria-label": "Search" }}
                />
              </form>
            </div>
            {/* search icon */}
            <div
              className={classNames(
                classes.searchIconContainer,
                classes.centeredFlex
              )}
            >
              {renderVendorIcons()}
              {/* <VerticalDivider margin={"0px"} /> */}

              <Box
                className={classes.searchIconButton}
                onClick={handleSearchButton}
                type="submit"
              >
                <SearchIcon className={classes.searchIcon} />
              </Box>
            </div>
          </div>
        )}
      </Translation>
    );
  };
  const renderShippingTo = () => {
    return (
      <Translation>
        {(t) => (
          <
            // display='flex'
            // width='100%'
            // justifyContent='space-around'
            // alignItems='center'
            // height='100%'
          >
            <Typography
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: "16px",
                whiteSpace: "nowrap",
              }}
            >
              <Hidden xsDown>{t("shipping_to")}</Hidden>
              <Hidden smUp>{t("shipping")}</Hidden>
            </Typography>
            <Box>
              <Select
                value={selectedCountry}
                disableUnderline
                IconComponent="none"
                onChange={(e) => setSelectedCountry(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-simple",
                }}
                classes={{
                  select: classes.selectStyle,
                }}
              >
                {countries.map((country) => {
                  return (
                    <MenuItem
                      disabled={country !== "Libya"}
                      key={country.name}
                      value={country.name}
                      aria-label={country}
                    >
                      <div className={classes.shippingCounteySelection}>
                        <Img
                          className={classes.shippingContryImage}
                          src={country.flag}
                        />
                        <Typography
                          className={
                            selectedCountry === country.name
                              ? classes.selectedShippingCountryName
                              : classes.shippingCountryName
                          }
                          variant="caption"
                          color="primary"
                        >
                          {t(country.name)}
                        </Typography>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </>
        )}
      </Translation>
    );
  };
  /**
   * @member netjeekLogo
   *
   * @description renders the netjeek logo
   */
  const netjeekLogo = (
    <Link to="/" className={classes.centeredFlex}>
      <Image
        disableSpinner={true}
        style={{ padding: "0", textAlign: "center" }}
        imageStyle={{
          maxHeight: "50px",
          // maxWidth: "80%",
          maxWidth: "100%",
          position: "relative",
          height: "unset",
          width: "unset",
        }}
        src={
          localStorage.getItem("language") === "en"
            ? netgeek_en_logo
            : netgeek_ar_logo
        }
      />
    </Link>
  );
  /**
   * @method renderLoginUser
   *
   * @description renders user menu and popping cart
   */
  const renderLoginUser = () =>
    loggedIn ? (
      <>{renderProfileMenu()}</>
    ) : (
      <Translation>
        {(t) => (
          <Link className={classes.undecoratedLink} to="/auth/sign-in">
            <div className={classes.centeredFlex}>
              <Typography
                className={classes.signInButton}
                style={{ display: "flex", alignItems: "center" }}
              >
                <AccountIcon style={{ fontSize: "30px", marginLeft: "10px" }} />
                {/* {t("sign_in")} */}
              </Typography>
              {/* <AccountIcon style={{ fontSize: "30px", marginLeft: "10px" }} /> */}
              {/* <Icon
                className={classNames('fas fa-user', classes.userIcon)}
                color='secondary'
              /> */}
            </div>
          </Link>
        )}
      </Translation>
    );

  return (
    <Translation>
      {(t, i18n) => (
        <div className={classes.root}>
          {/* TOP BAR */}

          {!(
            history.location.pathname.startsWith("/auth") ||
            history.location.pathname.startsWith("/user/add-address") ||
            history.location.pathname.startsWith("/user/checkout")
          ) && ( //exclude from signin, signup and reset password pages
            <>
              {/* MAIN APP BAR */}
              <Toolbar
                disableGutters
                position="static"
                className={classes.appBar}
              >
                {/* netjeek */}
                <Grid container style={{ height: "100%" }}>
                  <Hidden smDown>
                    <Grid item xs={2} className={classes.netjeekLogo}>
                      {netjeekLogo}
                      <Box className={classes.leftTrangle} />
                    </Grid>
                  </Hidden>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={10}
                    style={{ backgroundColor: "rgb(57,188,218)" }}
                  >
                    {/* search */}
                    <Hidden mdUp>
                      <Grid
                        item
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {netjeekLogo}
                        </Box>
                      </Grid>
                      <Grid item xs={10} className={classes.centeredFlex}>
                        {renderSearchBar()}
                      </Grid>
                    </Hidden>
                    {/* <Hidden xsDown>
                      <Grid item xs={1} />
                    </Hidden> */}
                    <Hidden smDown>
                      <Grid
                        sm={7}
                        md={12}
                        lg={7}
                        item
                        className={classes.centeredFlex}
                      >
                        {renderSearchBar()}
                      </Grid>
                    </Hidden>
                    {/* <Hidden smDown> */}
                    <Grid
                      item
                      xs={4}
                      md={4}
                      lg={5}
                      className={classes.centeredFlex}
                      style={{ justifyContent: "flex-end" }}
                    >
                      {renderShippingTo()}
                      <PoppingCart style={{ marginLeft: "30px" }} />
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginLeft: "30px", marginRight: "15px" }}
                        // width="100%"
                        height="100%"
                      >
                        {renderLanguageSelection(i18n)}
                        {renderLoginUser()}
                      </Box>
                    </Grid>
                    {/* </Hidden> */}
                    {/* <Grid
                      item
                      xs={4}
                      sm={4}
                      lg={1}
                      className={classes.centeredFlex}
                    >
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      lg={1}
                      className={classes.centeredFlex}
                    >
                      
                    </Grid> */}
                  </Grid>
                </Grid>
              </Toolbar>
              {/* EMAIL NOT ACTIVATED MESSAGE BAR */}
              <ErrorMessageBar />
            </>
          )}
        </div>
      )}
    </Translation>
  );
}

export default AppBar;
