/**
 * @module Cart
 */
//React imports
import React, { useState, useEffect, useCallback } from 'react';
import Image from 'material-ui-image';
import { Link } from 'react-router-dom';
import {
  IconButton,
  Button,
  Typography,
  Grid,
  Card,
  Icon,
  Tooltip,
  Popper,
  Paper,
  ClickAwayListener,
  Box,
  Hidden,
} from '@material-ui/core';
import { Translation } from 'react-i18next';
import Helmet from 'react-helmet';
import TwoEightTwoContainer from '../../../common/TwoEightTwoContainer';
import axios from 'axios';
import { useGlobalState } from '../../../GlobalState';
import { Skeleton } from '@material-ui/lab';
import numeral from 'numeral';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { handleGlobalError } from './../../../App';
import useWidth from '../../../common/useWidth';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const useStyles = makeStyles((theme) => ({
  deleteIconContainer: {
    display: 'flex',
    placeItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  cartItemCard: { position: 'relative', width: '100%', margin: '10px 0' },
  button: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  iconButton: {
    padding: '6px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  itemTitle: { fontSize: '0.9rem', fontWeight: '600' },
  itemPrice: {
    color: theme.palette.secondary.main,
    marginRight: '3px',
    fontSize: '0.85rem',
    fontWeight: '600',
  },
  itemRetailer: { display: 'inline-flex' },
  centeredFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cartItemsContainer: { display: 'flex', justifyContent: 'space-between' },

  quantityContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    justifyItems: 'center',
    width: 'fit-content',
    height: '30px',
  },
  quantityIcon: { fontSize: 14 },
  deleteIcon: { fontSize: 16 },
  undecoratedLink: { textDecoration: 'none', width: '100%', color: 'black' },
  checkoutContainer: {
    display: 'inline-flex',
    justifyContent: 'space-around',
  },
  emptyCartText: {
    minHeight: 200,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  orderTotal: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  tooltipRoot: { fontSize: 14 },
  // itemDescriptionContainer: { padding: "10px 15px 10px 7px" }, // toto's version
  itemDescriptionContainer: { padding: 4 }, // my version
  clearCart: { display: 'inline-flex' },
}));

let cartActionCancelTokenSource = new axios.CancelToken.source();

/**
 * @method Cart
 * @description cart page component
 */
const Cart = (props) => {
  const width = useWidth();
  // console.log('width', width);

  const [cart, setCart] = useGlobalState('cart');
  const [actionButtonsLoading, setActionButtonsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [session] = useGlobalState('session');
  const [deletePopperOpen, setDeletePopperOpen] = useState(false);
  const [deletePopperAnchorEl, setDeletePopperAnchorEl] = useState(null);
  /**
   * @method :{display:"inline-flex"}
   *
   * @description sends a request to clear all user cart
   */
  const clearCart = () => {
    setDeletePopperOpen(false);
    setLoading(true);
    axios
      .post(
        apiEndpoint + 'cart/clear',
        {},
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      )
      .then((res) => {
        setCart(res.data.data);
      })
      .catch((error) => {
        handleGlobalError(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  /**
   * @method getCart
   *
   * @description gets cart content once component mounts
   */
  const getCart = useCallback(() => {
    let cancelTokenSource = new axios.CancelToken.source();
    setLoading(true);
    setError(false);
    axios
      .get(apiEndpoint + 'cart', {
        timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        setCart(res.data.data);
        setError(false);
      })
      .catch((error) => {
        handleGlobalError(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      // To cancel previous request use:
      cancelTokenSource.cancel('cart get canceled');
      // cancelTokenSource = new axios.CancelToken.source();
    };
  }, [session, setCart]);

  useEffect(() => {
    getCart();
  }, [getCart]);
  /**
   * @method sumCart
   *
   * @returns {number} subtotal
   * @description sums the prices of items in the cart to calculate the subtotal
   */
  const sumCart = () => {
    return cart.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.quantity * currentValue.price,
      0
    );
  };
  /**
   * @method sumCartItems
   *
   * @returns {number} number of items in cart
   * @description calculates how many items there are in the cart
   */
  const sumCartItems = () => {
    return cart.reduce(
      (accumulator, currentValue) => accumulator + currentValue.quantity,
      0
    );
  };
  /**
   * @method deleteCartItem
   *
   * @description deletes specific item from the cart
   */
  const deleteCartItem = (item) => {
    setActionButtonsLoading(true);
    // To cancel previous request use:
    cartActionCancelTokenSource.cancel('decrease cart item canceled');
    cartActionCancelTokenSource = new axios.CancelToken.source();

    // console.log('item.id', item.id);

    axios
      .delete(apiEndpoint + 'cart', {
        data: { id: item.id },
        timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
        headers: { Authorization: `Bearer ${session.token}` },
        cancelToken: cartActionCancelTokenSource.token,
      })
      .then((res) => {
        setCart(res.data.data);
      })
      .catch((error) => {
        handleGlobalError(error);
        setError(true);
      })
      .finally(() => {
        setActionButtonsLoading(false);
      });
  };
  /**
   * @method decreaseCartItem
   *
   * @description decreases item quantity by one
   */
  const decreaseCartItem = (item) => {
    setActionButtonsLoading(true);
    // To cancel previous request use:
    cartActionCancelTokenSource.cancel('decrease cart item canceled');
    cartActionCancelTokenSource = new axios.CancelToken.source();

    axios
      .patch(
        apiEndpoint + 'cart',
        { id: item.id, quantity: item.quantity - 1 },
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
          headers: { Authorization: `Bearer ${session.token}` },
          cancelToken: cartActionCancelTokenSource.token,
        }
      )
      .then((res) => {
        setCart(res.data.data);
      })
      .catch((error) => {
        handleGlobalError(error);
        setError(true);
      })
      .finally(() => {
        setActionButtonsLoading(false);
      });
  };
  /**
   * @method increaseCartItem
   *
   * @description increases item quantity by one
   */
  const increaseCartItem = (item) => {
    setActionButtonsLoading(true);
    // To cancel previous request use:
    cartActionCancelTokenSource.cancel('decrease cart item canceled');
    cartActionCancelTokenSource = new axios.CancelToken.source();

    axios
      .patch(
        apiEndpoint + 'cart',
        { id: item.id, quantity: item.quantity + 1 },
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
          headers: { Authorization: `Bearer ${session.token}` },
          cancelToken: cartActionCancelTokenSource.token,
        }
      )
      .then((res) => {
        setCart(res.data.data);
      })
      .catch((error) => {
        handleGlobalError(error);
        setError(true);
      })
      .finally(() => {
        setActionButtonsLoading(false);
      });
  };

  const classes = useStyles();

  return (
    <Translation>
      {(t) => (
        <TwoEightTwoContainer>
          <Helmet>
            <title>{t('shopping cart')}</title>
          </Helmet>
          {!error ? (
            !loading ? (
              cart.length !== 0 ? (
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    container
                    className={classes.cartItemsContainer}
                  >
                    <Typography variant='h6'>{`${t(
                      'shopping cart'
                    )} (${sumCartItems()})`}</Typography>

                    {/* clear cart */}
                    <ClickAwayListener
                      onClickAway={() => setDeletePopperOpen(false)}
                    >
                      <Box className={classes.clearCart}>
                        <Button
                          onClick={(event) => {
                            setDeletePopperAnchorEl(event.currentTarget);
                            setDeletePopperOpen(true);
                          }}
                          color='primary'
                          size='small'
                          className={classes.button}
                        >
                          {t('clear cart')}
                        </Button>
                        <Popper
                          anchorEl={deletePopperAnchorEl}
                          open={deletePopperOpen}
                          placement='bottom'
                        >
                          <Paper>
                            <Box p={1}>
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  className={classes.centeredFlex}
                                >
                                  <Box mb={1}>
                                    <Typography>
                                      {t(
                                        'are you sure you want to clear your cart?'
                                      )}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  className={classes.centeredFlex}
                                >
                                  <Box px={1}>
                                    <Button
                                      onClick={() => setDeletePopperOpen(false)}
                                    >
                                      {t('no')}
                                    </Button>
                                  </Box>
                                  <Box px={1}>
                                    <Button
                                      onClick={clearCart}
                                      className={classes.button}
                                    >
                                      {t('yes')}
                                    </Button>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Paper>
                        </Popper>
                      </Box>
                    </ClickAwayListener>
                  </Grid>
                  <Grid item xs={12}>
                    <Hidden smUp>
                      <Grid item xs={12} sm={6} className={classes.orderTotal}>
                        <Typography variant='h6'>
                          {`${t('order total')}: ${numeral(sumCart()).format(
                            process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                          )}`}
                        </Typography>
                      </Grid>
                    </Hidden>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.checkoutContainer}
                      >
                        <Link
                          to='/user/checkout'
                          className={classes.undecoratedLink}
                        >
                          <Button
                            aria-label='checkout'
                            variant='contained'
                            fullWidth
                            className={classes.button}
                          >
                            {t('proceed to checkout')}
                          </Button>
                        </Link>
                      </Grid>
                      <Hidden xsDown>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.orderTotal}
                        >
                          <Typography variant='h6'>
                            {`${t('order total')}: ${numeral(sumCart()).format(
                              process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                            )}`}
                          </Typography>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Grid>

                  {/* cart items */}
                  {cart.map((item, idx) => (
                    <Grid key={idx} item xs={12}>
                      <Card className={classes.cartItemCard}>
                        <Grid container spacing={1}>
                          {/* image */}
                          <Grid item xs={5} sm={3} md={2} lg={2} xl={1}>
                            <Link
                              to={`/product?retailer=${item.retailer}&id=${item.retailer_item_id}`}
                            >
                              <Image
                                disableSpinner={true}
                                style={{
                                  padding: '0',
                                  backgroundColor: 'unset',
                                  height: '100%',
                                }}
                                imageStyle={{
                                  position: 'relative',
                                  width: 'unset',
                                  height: 'unset',
                                  maxHeight: '100%',
                                  maxWidth: '100%',
                                }}
                                src={item.image_url}
                              />
                            </Link>
                          </Grid>
                          {/* description */}
                          <Grid item xs={6} sm={8} md={9} lg={9} xl={10}>
                            <Box className={classes.itemDescriptionContainer}>
                              {/* name */}
                              <Grid item xs={12}>
                                <Link
                                  className={classes.undecoratedLink}
                                  to={`/product?retailer=${item.retailer}&id=${item.retailer_item_id}`}
                                >
                                  <Typography className={classes.itemTitle}>
                                    {item.title}
                                  </Typography>
                                </Link>
                              </Grid>
                              {item.variant && (
                                <Grid item xs={12}>
                                  <Typography variant='caption'>
                                    {item.variant.join(' - ')}
                                  </Typography>
                                </Grid>
                              )}
                              {/* price*/}
                              <Grid item xs={12}>
                                <Typography
                                  variant='caption'
                                  className={classes.itemPrice}
                                >
                                  {`${numeral(item.price).format(
                                    process.env
                                      .REACT_APP_DEFAULT_CURRENCY_FORMAT
                                  )}
                                  `}
                                </Typography>
                                <Typography
                                  variant='caption'
                                  className={classes.itemRetailer}
                                >
                                  {(() => {
                                    switch (item.retailer.toLocaleLowerCase()) {
                                      case 'amazon_ca':
                                        return `${t('from')} ${t('amazon')}`;
                                      case 'walmart_ca':
                                        return `${t('from')} ${t('walmart')}`;
                                      case 'aliexpress':
                                        return `${t('from')} ${t(
                                          'aliexpress'
                                        )}`;
                                      default:
                                        return `${t('from')} ${t(
                                          'unknown vendor'
                                        )}`;
                                    }
                                  })()}
                                </Typography>
                              </Grid>
                              {/* quantity  */}
                              <Grid item xs={12}>
                                <div className={classes.quantityContainer}>
                                  <IconButton
                                    onClick={() => decreaseCartItem(item)}
                                    size='small'
                                    disabled={
                                      item.quantity <= 1 || actionButtonsLoading
                                    }
                                  >
                                    <Icon
                                      className={classNames(
                                        'fas fa-minus-circle',
                                        classes.quantityIcon
                                      )}
                                    ></Icon>
                                  </IconButton>
                                  <Box mx={1}>
                                    <Typography>{item.quantity}</Typography>
                                  </Box>
                                  <IconButton
                                    onClick={() => increaseCartItem(item)}
                                    size='small'
                                    disabled={
                                      item.quantity >= 50 ||
                                      actionButtonsLoading
                                    }
                                  >
                                    <Icon
                                      className={classNames(
                                        'fas fa-plus-circle',
                                        classes.quantityIcon
                                      )}
                                    ></Icon>
                                  </IconButton>
                                </div>
                              </Grid>
                            </Box>
                          </Grid>
                          {/* delete */}
                          <Grid
                            item
                            xs={1}
                            className={classes.deleteIconContainer}
                          >
                            <Tooltip
                              classes={{ tooltip: classes.tooltipRoot }}
                              disableFocusListener
                              disableTouchListener
                              arrow
                              title={t('delete')}
                              placement='top'
                            >
                              <span>
                                <IconButton
                                  disableTouchRipple
                                  // disableRipple
                                  disableFocusRipple
                                  className={classes.iconButton}
                                  onClick={() => {
                                    deleteCartItem(item);
                                  }}
                                  disabled={actionButtonsLoading}
                                >
                                  <Icon
                                    className={classNames(
                                      'fas fa-times',
                                      classes.deleteIcon
                                    )}
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}

                  {/* total and checkout */}
                  <Grid item xs={12}>
                    <Hidden smUp>
                      <Grid item xs={12} sm={6} className={classes.orderTotal}>
                        <Typography variant='h6'>
                          {`${t('order total')}: ${numeral(sumCart()).format(
                            process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                          )}`}
                        </Typography>
                      </Grid>
                    </Hidden>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.checkoutContainer}
                      >
                        <Link
                          to='/user/checkout'
                          className={classes.undecoratedLink}
                        >
                          <Button
                            aria-label='checkout'
                            variant='contained'
                            fullWidth
                            className={classes.button}
                          >
                            {t('proceed to checkout')}
                          </Button>
                        </Link>
                      </Grid>
                      <Hidden xsDown>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.orderTotal}
                        >
                          <Typography variant='h6'>
                            {`${t('order total')}: ${numeral(sumCart()).format(
                              process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                            )}`}
                          </Typography>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                // cart is empty
                <Box className={classes.emptyCartText}>
                  <Typography variant='h5'>
                    {t('your cart is empty, continue shopping!')}
                  </Typography>
                </Box>
              )
            ) : (
              // loading
              <Grid container spacing={1}>
                <Grid item container xs={12} spacing={1}>
                  {[...Array(4)].map((_item, idx) => (
                    <Grid container spacing={1} item key={idx} xs={12}>
                      <Grid item xs={5} sm={3} md={2} lg={2} xl={1}>
                        <Skeleton
                          width='100%'
                          height='160px'
                          variant='rect'
                        ></Skeleton>
                      </Grid>
                      <Grid item xs={7} sm={9} md={10} lg={10} xl={11}>
                        <Skeleton width='100%'></Skeleton>
                        <Skeleton width='10%'></Skeleton>
                        <Skeleton width='20%'></Skeleton>
                      </Grid>
                      <Grid item xs={1}></Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <Grid item xs={6}>
                    <Skeleton width='100%' height='40px'></Skeleton>
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton width='100%' height='40px'></Skeleton>
                  </Grid>
                </Grid>
              </Grid>
            )
          ) : (
            // error
            <Grid container>
              <Grid item xs={12}>
                <Box p={2} className={classes.centeredFlex}>
                  <Typography>{t('an unexpected error occurred')}</Typography>
                  <Button onClick={getCart}>
                    {t('click here to refresh')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </TwoEightTwoContainer>
      )}
    </Translation>
  );
};

export default Cart;
