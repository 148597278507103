/**
 * @module MapWithValidator
 */
//React imports
import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import red from "@material-ui/core/colors/red";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import markerLogo from "../../assets/images/Map-marker.png";
const regions = {
  Tripoli: [
    "Alajaylat",
    "Algseibat",
    "Almaya",
    "Alqubah",
    "Alrheibat",
    "Alurban",
    "Arrajban",
    "Arrayaniya",
    "Espiaa",
    "Gasrakhyar",
    "Ghadames",
    "Gharyan",
    "Jadu",
    "Jumayl",
    "Kikla",
    "Mashashita",
    "Mizda",
    "Msallata",
    "Nalut",
    "Regdalin",
    "Sabratha",
    "Sorman",
    "Tajura",
    "Tarhuna",
    "Teji",
    "Tripoli",
    "Yafran",
    "Zaltan",
    "Zawiya",
    "Zintan",
    "Zuwara",
  ],
  Benghazi: [
    "Abyar",
    "Ajdabiya",
    "Alabraq",
    "Awjila",
    "Bayda",
    "Benghazi",
    "Benina",
    "Binjawad",
    "Brega",
    "Derna",
    "Elagheila",
    "Jalu",
    "Kufra",
    "Marj",
    "Martuba",
    "Massah",
    "Musaid",
    "Nofaliya",
    "Qaminis",
    "Qayqab",
    "Raslanuf",
    "Shahhat",
    "Suluq",
    "Susa",
    "Tacnis",
    "Tazirbu",
    "Tobruk",
    "Tocra",
    "Tolmeita",
    "Ummal Rizam",
    "Zella",
  ],
  Misrata: ["Baniwalid", "Khoms", "Misrata", "Sirte", "Tawergha", "Zliten"],
  Sabha: [
    "Brak",
    "Ghat",
    "Hun",
    "Murzuk",
    "Qatrun",
    "Sabha",
    "Sokna",
    "Traghan",
    "Ubari",
    "Ummalaranib",
    "Waddan",
  ],
};
const arabicCityToEnglish = {
  طرابلس: "Tripoli",
  زاوية: "Zawiya",
  صبراتة: "Sabratha",
  زوارة: "Zuwara",
  تاجوراء: "Tajura",
  ترهونة: "Tarhuna",
  غريان: "Gharyan",
  مسلاتة: "Msallata",
  الجميل: "Jumayl",
  صرمان: "Sorman",
  الجسبات: "Algseibat",
  اسبيعة: "Espiaa",
  رقدالين: "Regdalin",
  قصرالاخيار: "Gasrakhyar",
  القبة: "Alqubah",
  الماية: "Almaya",
  العجيلات: "Alajaylat",
  نالوت: "Nalut",
  زلطن: "Zaltan",
  مزدة: "Mizda",
  العربان: "Alurban",
  يفرن: "Yafran",
  الريانينة: "Arrayaniya",
  تيجي: "Teji",
  "ككلة‎": "Kikla",
  الرحيبات: "Alrheibat",
  جادو: "Jadu",
  غدامس: "Ghadames",
  الرجبان: "Arrajban",
  المشاشطة: "Mashashita",
  الزنتان: "Zintan",
  بنغازي: "Benghazi",
  البيضاء: "Bayda",
  إجدابيا: "Ajdabiya",
  طبرق: "Tobruk",
  درنة: "Derna",
  الكفرة: "Kufra",
  المرج: "Marj",
  شحات: "Shahhat",
  العقيلة: "Elagheila",
  أبيار: "Abyar",
  النوفلية: "Nofaliya",
  البريقة: "Brega",
  جالو: "Jalu",
  سلوق: "Suluq",
  "راس لانوف": "Raslanuf",
  "أم الرزم": "Ummal Rizam",
  توكرة: "Tocra",
  قمينس: "Qaminis",
  بنينا: "Benina",
  مسة: "Massah",
  "بن جواد": "Binjawad",
  الأبرق: "Alabraq",
  أوجلة: "Awjila",
  طلميتة: "Tolmeita",
  زلة: "Zella",
  مرتوبة: "Martuba",
  القيقب: "Qayqab",
  امساعد: "Musaid",
  تاكنس: "Tacnis",
  سوسة: "Susa",
  "تازر بو": "Tazirbu",
  "مصراتة‎": "Misrata",
  زليتن: "Zliten",
  الخمس: "Khoms",
  سرت: "Sirte",
  "بني وليد": "Baniwalid",
  تاورغاء: "Tawergha",
  سبها: "Sabha",
  أوباري: "Ubari",
  ودان: "Waddan",
  مرزق: "Murzuk",
  هون: "Hun",
  "براك‎": "Brak",
  القطرون: "Qatrun",
  سوكنة: "Sokna",
  "أم الأرانب": "Ummalaranib",
  غات: "Ghat",
  تراغن: "Traghan",
};
/**
 * @function tReverse
 * @description converts Arabic city to English
 * @param {string} city city in Arabic
 * @returns {string} city in English
 */
const tReverse = (city) =>
  arabicCityToEnglish[city] ? arabicCityToEnglish[city] : city;

/**
 * @component
 * @classdesc Map component with validation
 * @requires GoogleMap
 * @see module:AddressComponent
 */
class MapWithValidator extends ValidatorComponent {
  /**
   * @method errorText
   * @description renders error text
   */
  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <div
        style={{
          fontSize: "12px",
          color: red["500"],
        }}
      >
        {this.getErrorMessage()}
      </div>
    );
  }

  render() {
    const { mapRef, defaultPosition, markerRef, onDragEnd } = this.props;
    return (
      <>
        <GoogleMap ref={mapRef} defaultZoom={5} defaultCenter={defaultPosition}>
          <Marker
            ref={markerRef}
            name={"User Location"}
            draggable={true}
            onDragEnd={onDragEnd}
            icon={markerLogo}
            position={defaultPosition}
          />
        </GoogleMap>
        {this.errorText()}
      </>
    );
  }
}

const MapLocationPicker = withScriptjs(
  withGoogleMap((props) => <MapWithValidator {...props} />)
);
export { MapLocationPicker, regions, tReverse };
