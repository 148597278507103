/**
 * @module Profile
 */
import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Grid, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import TwoEightTwoContainer from '../../../common/TwoEightTwoContainer';
import {
  ShoppingCart as ShoppingCartIcon,
  Home as HomeIcon,
  Person as PersonIcon,
} from '@material-ui/icons/';
//components
import Settings from './Settings';
import Orders from './Orders';
import Address from './Address';
import NoMatch404 from '../../NoMatch404';
import { makeStyles } from '@material-ui/core/styles';
import { Translation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  deselected: {
    color: theme.palette.secondary.contrastText,
  },
  undecoratedLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
  },
  listItemIconRoot: {
    justifyContent: 'center',
    // minWidth: 36
  },
}));

/**
 * @method UserProfilePages
 * @description nested router for user profile pages
 * @requires Settings
 * @requires Address
 * @requires Orders
 */
const UserProfilePages = (props) => {
  const [selected, setSelected] = useState('');

  useEffect(() => {
    const arr = props.location.pathname.split('/');
    setSelected(arr[arr.length - 1]);
  }, [props.location.pathname]);

  const classes = useStyles();
  return (
    <Translation>
      {(t) => (
        <TwoEightTwoContainer>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <ListItem
                // disableGutters
                disableGutters
                button
                onClick={() => props.history.push('/user/profile/settings')}
                className={
                  selected === 'settings'
                    ? classes.selected
                    : classes.deselected
                }
              >
                <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={t('personal information')} />
              </ListItem>
              <ListItem
                // disableGutters
                disableGutters
                button
                onClick={() => props.history.push('/user/profile/address')}
                className={
                  selected === 'address' ? classes.selected : classes.deselected
                }
              >
                <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={t('address')} />
              </ListItem>
              <ListItem
                // disableGutters
                disableGutters
                button
                onClick={() => props.history.push('/user/profile/orders')}
                className={
                  selected === 'orders' ? classes.selected : classes.deselected
                }
              >
                <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary={t('order history')} />
              </ListItem>
            </Grid>
            <Grid item xs={12} md={9}>
              <Switch>
                <Route
                  path='/user/profile/settings'
                  component={Settings}
                  exact
                />
                <Route path='/user/profile/address' component={Address} exact />
                <Route path='/user/profile/orders' component={Orders} exact />
                {/* 404 */}
                <Route component={NoMatch404} />
              </Switch>
            </Grid>
          </Grid>
        </TwoEightTwoContainer>
      )}
    </Translation>
  );
};

export default UserProfilePages;
