/**
 * @module AxiosWrapper
 */
import React from 'react';
import App from './App';
import axios from 'axios';
import { useGlobalState } from './GlobalState';
import jwt from 'jsonwebtoken';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

let session;
let setSession;
let loggedIn;
let setLoggedIn;

axios.interceptors.request.use(
  /**
   * @async
   * @callback axiosInterceptorsMiddleware
   * @description intercepts all axios requests and checks if the request requires user authentication
   * if it does, checks if session token is valid by parsing it and checking its expiry against current time
   * if it's expired, tries to refresh this token by using the session refresh token
   */
  async function (config) {
    if (
      !config.url.endsWith('customers/refresh-token') &&
      config.headers.Authorization &&
      loggedIn &&
      session &&
      session.token &&
      new Date().getTime() / 1000 > jwt.decode(session.token).exp
    ) {
      // console.log("expired token while trying to axios this request", config);
      const refreshTokenResult = await axios({
        method: 'post',
        url: apiEndpoint + 'customers/refresh-token',
        data: { refresh_token: session.refresh_token },
      });
      // console.log("refreshTokenResult", refreshTokenResult);
      if (refreshTokenResult.status && refreshTokenResult.status === 200) {
        //save new session
        setSession(refreshTokenResult.data.data);
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${refreshTokenResult.data.data.token}`,
        };
        localStorage.setItem(
          'session',
          JSON.stringify(refreshTokenResult.data.data)
        );
      } else {
        // log user out
        setLoggedIn(false);
      }

      // console.log(config);
      return config;
    } else {
      return config;
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
/**
 * @method AxiosWrapper
 * @description axios wrapper, adds request interceptor, returns App
 * @see module:axiosInterceptorsMiddleware
 */
function AxiosWrapper() {
  [session, setSession] = useGlobalState('session');
  [loggedIn, setLoggedIn] = useGlobalState('loggedIn');

  return <App />;
}
export default AxiosWrapper;
