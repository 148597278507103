import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DataFetcher from "../../../common/DataFetcher";
import { useGlobalState } from "../../../GlobalState";
import {
  Button,
  Typography,
  Grid,
  Card,
  TextField,
  Box,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Translation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Image from "material-ui-image";
import numeral from "numeral";
import { CircularProgress, Backdrop } from "@material-ui/core";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 8,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  centeredFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  itemTitle: { fontSize: "0.9rem", fontWeight: "600" },
  timer: {
    fontSize: "1.5rem",
    fontWeight: "600",
    marginBottom: "8px",
    marginTop: "8px",
  },
  itemPrice: {
    color: theme.palette.secondary.main,
    marginRight: "3px",
    fontSize: "0.85rem",
    fontWeight: "600",
  },
  itemRetailer: { display: "inline-flex" },

  applyButton: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  // descriptionContainer: { padding: "10px 15px 10px 7px" }, //toto's version
  descriptionContainer: { padding: 4 }, //my version
  orderInfoContainer: {
    alignContent: "flex-start",
  },
  orderPricesContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  horizontalDivider: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginTop: "4px",
    marginBottom: "4px",
  },
  orderPrice: {
    fontWeight: 600,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

/**
 * @name DiscountBox
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const DiscountBox = ({ sessionId, callback, fail }) => {
  const classes = useStyles();
  const [session] = useGlobalState("session");

  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isCodeApplied, setIsCodeApplied] = useState(false);
  const [textfieldValue, setTextfieldValue] = useState("");

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const onChangeHandler = (e) => {
    setError(false);
    setTextfieldValue(e.target.value);
  };

  const onSubmitHandler = () => {
    setError(false);
    setLoading(true);
  };
  const onClearCode = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}orders/discounts/cancel-promo`,
        {
          session_id: sessionId,
        },
        {
          headers: { Authorization: `Bearer ${session.token}` },
        }
      );
      if (response) {
        setIsCodeApplied(false);
        setLoading(false);
        setTextfieldValue("");
        fail();
      }
    } catch (error) {
      setLoading(false);
    }
  };
  // Other

  // Component Render
  return (
    <Box mt={2} style={{ width: "100%" }}>
      <Translation>
        {(t) => (
          <>
            <Box display="flex" justifyContent="space-between">
              <TextField
                id="discount-code"
                label={t("promoCode")}
                color="secondary"
                variant="outlined"
                name="code"
                onChange={onChangeHandler}
                disabled={isCodeApplied}
                value={textfieldValue}
                style={{ flex: 1, marginRight: "5px" }}
              />

              {isCodeApplied ? (
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={onClearCode}
                >
                  {(loading && t("please wait")) ||
                    (!loading && t("clear code"))}
                  {loading && (
                    <CircularProgress
                      size={24}
                      style={{ position: "absolute" }}
                    />
                  )}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={loading || textfieldValue === ""}
                  onClick={onSubmitHandler}
                  className={classes.applyButton}
                >
                  {(loading && t("please wait")) || (!loading && t("apply"))}
                  {loading && (
                    <CircularProgress
                      size={24}
                      style={{ position: "absolute" }}
                    />
                  )}
                </Button>
              )}
            </Box>

            {loading && (
              <DataFetcher
                method="post"
                onError={() => {
                  setLoading(false);
                  setError(true);
                  setIsCodeApplied(false);
                  fail();
                  return null;
                }}
                onLoading={null}
                route="orders/discounts/apply-promo"
                headers={{ Authorization: `Bearer ${session.token}` }}
                requestData={{
                  session_id: sessionId,
                  promo: textfieldValue,
                }}
              >
                {(data) => {
                  setLoading(false);
                  setError(false);
                  setIsCodeApplied(true);
                  callback(data.data);
                  return null;
                }}
              </DataFetcher>
            )}
            {error && <Box color="red">{t("invalid code!")}</Box>}
          </>
        )}
      </Translation>
    </Box>
  );
};

DiscountBox.propTypes = {
  /**
   *
   */
};

export default DiscountBox;
