/**
 * @module ProductImageCarousel
 */
import React from 'react';
import { Typography, Grid, Modal, Card, Box, Icon } from '@material-ui/core';
import Image from 'material-ui-image';
import { withStyles } from '@material-ui/styles';
import { Translation } from 'react-i18next';
import classNames from 'classnames';
import Img from 'react-image';
import { useGlobalState } from '../../GlobalState';

const imageFadeTime = 500;
const styles = (theme) => ({
  imageSelection: {
    maxHeight: '100%',
    maxWidth: '100%',
    boxShadow: 'none',
    paddingTop: 'unset',
    backgroundColor: 'unset',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
    transitionTimingFunction: 'ease-out',
    transition: `opacity ${imageFadeTime}ms`,
    '&:hover': {
      cursor: 'zoom-in',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  modal: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  modalImageContainer: {
    width: '100%',
    height: '100%',
    '&:hover': {
      cursor: 'zoom-out',
    },
  },
  imageSelectionCard: {
    height: '100px',
    // width: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    // boxShadow: shadow,
    borderRadius: '0%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  centeredFlex: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  // imageSelectionContainer: { height: "90px" },
  selectedImage: { opacity: 1, position: 'relative' },
  deselectedImage: { opacity: 0, position: 'absolute' },
  bigImageCard: {
    // height: bigImageMaximumHeight,
    marginBottom: '10px',
    // boxShadow: shadow,
    borderRadius: '0%',
  },
  fullHeight: { height: '100%' },
  modalImage: { maxHeight: '99%', maxWidth: '99%', userSelect: 'none' },
  bigImages: {
    position: 'relative',
    height: '100%',
  },
  arrowContainer: {
    height: '100%',
    display: 'flex',
    position: 'absolute',
    width: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    // zIndex: "5",
    backgroundColor: `${theme.palette.secondary.dark}33`,
    cursor: 'pointer',
    '&:hover': { backgroundColor: `${theme.palette.secondary.veryDark}33` },
  },
  leftArrowContainer: {
    left: '0',
  },
  rightArrowContainer: {
    right: '0',
  },
});
/**
 * @component
 * @classdesc renders product images,
 * consists of big image, small images (image position) and image modal
 * @see module:Product
 * @param {Object} props component props
 * @param {string[]} props.images images array
 */
class ProductImageCarousel extends React.Component {
  state = {
    images: [],
    currentImageIndex: 0,
    isModalOpen: false,
    modalImageIndex: 0,
  };

  /**
   * @method componentDidUpdate
   *
   * @description makes big image width equal to its height
   */
  componentDidUpdate = () => {
    // console.log("product carousel updated");
    // console.log("prevProps ", prevProps);
    // console.log("this.props", this.props);
    // console.log("-----------");
    // prevProps &&
    //   prevProps.images !== this.props.images &&
    //   this.componentDidMount();
    // const bigImage = document.getElementById('imageContainer');
    // if (bigImage.offsetWidth !== bigImage.offsetHeight) {
    //   bigImage.style.height = `${bigImage.offsetWidth}px`;
    // }
  };
  /**
   * @member {Object} timer
   *
   * @description refreshes big image height each second
   */
  timer = setInterval(this.componentDidUpdate, 1000);

  /**
   * @method componentWillUnmount
   *
   * @description removes event listener on resize
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.componentDidUpdate);
    clearTimeout(this.timer);
  }
  /**
   * @method componentDidMount
   *
   * @description adds event listener on resize
   */
  componentDidMount() {
    window.addEventListener('resize', this.componentDidUpdate);

    try {
      let images = this.props.images.map((image) => ({
        img: image,
        selected: false,
      }));
      images[0].selected = true;
      this.setState({ images });
    } catch (error) {
      //
      this.setState({ bigError: true });
    }
  }
  /**
   * @method previousImage
   *
   * @param {Object} event event
   * @description goes to previous image
   */
  previousImage = (e) => {
    let { timeoutHandle, images, modalImageIndex } = this.state;
    if (modalImageIndex === 0) modalImageIndex = images.length - 1;
    else modalImageIndex--;
    this.setState({ timeoutHandle, images, modalImageIndex });
    // stop event propagation so that this doesn't close the modal
    try {
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
    } catch {}
  };
  /**
   * @method nextImage
   *
   * @param {Object} event event
   * @description goes to next image
   */
  nextImage = (e) => {
    let { timeoutHandle, images, modalImageIndex } = this.state;
    if (modalImageIndex === images.length - 1) modalImageIndex = 0;
    else modalImageIndex++;
    this.setState({ timeoutHandle, images, modalImageIndex });
    // stop event propagation so that this doesn't close the modal
    try {
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
    } catch {}
  };
  /**
   * @method changeImageTo
   *
   * @param {number} i image index to change to
   * @description changes image to specific index
   */
  changeImageTo = (i) => {
    let { images, currentImageIndex } = this.state;
    images[currentImageIndex].selected = false;
    currentImageIndex = i;
    images[currentImageIndex].selected = true;
    this.setState({ images, currentImageIndex });
  };
  /**
   * @method renderImagePosition
   *
   * @description renders small images under big image to show all product images
   */
  renderImagePosition() {
    const { images, currentImageIndex } = this.state;
    const { classes } = this.props;
    return (
      <Grid container spacing={2}>
        {images.map((image, idx) => {
          return (
            <Grid
              item
              xs={3}
              className={classes.imageSelectionContainer}
              key={idx}
            >
              <Card
                className={classes.imageSelectionCard}
                onClick={() => {
                  this.changeImageTo(idx);
                }}
              >
                <Image
                  disableSpinner={true}
                  imageStyle={{
                    position: 'relative',
                    width: 'unset',
                    height: 'unset',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    filter:
                      currentImageIndex === idx ? 'opacity(1)' : 'opacity(0.7)',
                  }}
                  style={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                    boxShadow: 'none',
                    paddingTop: 'unset',
                    backgroundColor: 'unset',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyItems: 'center',
                  }}
                  src={image.img}
                  // width="100%"
                />
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  }
  /**
   * @method handleModalOpen
   *
   * @description sets image modal state to open
   */
  handleModalOpen = () => {
    this.setState({
      modalImageIndex: this.state.currentImageIndex,
      isModalOpen: true,
    });
  };

  /**
   * @method handleModalClose
   *
   * @description sets image modal state to closed
   */
  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };
  /**
   * @method handleKeyboardButton
   *
   * @description adds keyboard support to the image modal
   */
  handleKeyboardButton = (event) => {
    // console.log('event.key', event.key);
    if (event.key === 'ArrowRight') {
      this.nextImage();
    } else if (event.key === 'ArrowLeft') {
      this.previousImage();
    } else if (event.key === 'Escape') {
      this.handleModalClose();
    }
  };
  /**
   * @method renderModal
   *
   * @param {Object} event event containing info about pressed button
   * @description renders image modal
   */
  renderModal = () => {
    const { isModalOpen, images, modalImageIndex } = this.state;
    const { classes } = this.props;
    const [language] = useGlobalState('language');
    return images.length !== 0 ? (
      <div>
        <Modal
          onKeyDown={this.handleKeyboardButton}
          open={isModalOpen}
          onClose={this.handleModalClose}
          className={classes.modal}
        >
          <Box
            onClick={this.handleModalClose}
            title='Close'
            className={classNames(
              classes.modalImageContainer,
              classes.centeredFlex,
              classes.fullHeight
            )}
          >
            {images.length > 1 && (
              <div
                title='previous'
                xs={1}
                className={classNames(
                  classes.leftArrowContainer,
                  classes.arrowContainer
                )}
                onClick={this.previousImage}
              >
                <Icon
                  className={
                    language === 'en'
                      ? classNames('fas fa-caret-left', classes.centeredFlex)
                      : classNames('fas fa-caret-right', classes.centeredFlex)
                  }
                />
              </div>
            )}
            <Img
              src={images[modalImageIndex].img}
              className={classes.modalImage}
            />
            {images.length > 1 && (
              <div
                title='next'
                xs={1}
                className={classNames(
                  classes.rightArrowContainer,
                  classes.arrowContainer
                )}
                onClick={this.nextImage}
              >
                <Icon
                  className={
                    language === 'en'
                      ? classNames('fas fa-caret-right', classes.centeredFlex)
                      : classNames('fas fa-caret-left', classes.centeredFlex)
                  }
                />
              </div>
            )}
          </Box>
        </Modal>
      </div>
    ) : null;
  };
  render() {
    const { classes } = this.props;
    const { images, bigError } = this.state;

    return (
      <Translation>
        {() => (
          <div className={classes.container}>
            {!bigError ? (
              <Grid container>
                {/* product image */}
                <Grid item xs={12}>
                  <Card
                    className={classNames(
                      classes.centeredFlex,
                      classes.bigImageCard
                    )}
                    id='imageContainer'
                  >
                    {/* big images */}
                    <div
                      className={classNames(
                        classes.centeredFlex,
                        classes.bigImages
                      )}
                    >
                      {images.map((image) => (
                        <Img
                          key={image.img}
                          onClick={this.handleModalOpen}
                          src={image.img}
                          className={classNames(
                            classes.image,
                            image.selected
                              ? classes.selectedImage
                              : classes.deselectedImage
                          )}
                        />
                      ))}
                      {this.renderModal()}
                    </div>
                  </Card>
                </Grid>

                {/* small images/position */}
                {images.length > 1 && (
                  <Grid item xs={12}>
                    {this.renderImagePosition()}
                  </Grid>
                )}
              </Grid>
            ) : (
              <Typography>This product has no images</Typography>
            )}
          </div>
        )}
      </Translation>
    );
  }
}

export default withStyles(styles)(ProductImageCarousel);
