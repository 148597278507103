/**
 * @module Homepage
 */
import React, { useState, useEffect, useCallback } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Translation } from 'react-i18next';

import ImageCarousel from './ImageCarousel/';
import Shortcuts from './Shortcuts/';
import TopPicks from './TopPicks/';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const useStyles = makeStyles({
  parent: {
    display: 'flex',
    flexDirection: 'column',
  },
});

/**
 * @method Homepage
 * @description homepage component\
 * renders 3 things,
 * 1. Slider Image Carousel
 * 2. Featured Categories (Shortcuts)
 * 3. Top picks items
 * @see module:ImageCarousel
 * @see module:Shortcuts
 * @see module:TopPicks
 */
const Homepage = (props) => {
  const [homepageData, setHomepageData] = useState({});

  const classes = useStyles();
  /**
   * @method getData
   *
   * @description gets homepage data which are slider images and shorctus
   */
  const getData = useCallback(() => {
    axios
      .get(apiEndpoint + 'home', {
        timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
      })
      .then((res) => {
        setHomepageData(res.data.data);
      })
      .catch((error) => {
        // console.log(error);
        setTimeout(getData, 1000);
      });
  }, []);

  useEffect(getData, [getData]);

  return (
    <Translation>
      {(t) => (
        <Box className={classes.parent}>
          <Helmet>
            <title>{t('netjeek')}</title>
          </Helmet>

          <ImageCarousel images={homepageData.slider} />
          <Box my={2}>
            <Shortcuts shortcuts={homepageData.shortcuts} />
          </Box>
          <TopPicks />
        </Box>
      )}
    </Translation>
  );
};

export default Homepage;
