/**
 * @module Router
 */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Translation } from 'react-i18next';

//components
import AppBar from './common/AppBar';
import Footer from './common/Footer';

import Homepage from './components/Homepage';
import Search from './components/Search';
import Product from './components/Product';
import Authentication from './components/Authentication';
import UserPages from './components/User/';
import AuxPages from './components/AuxPages';
import Temp from './components/Temp';
import NoMatch404 from './components/NoMatch404';
import VerifyEmail from './components/VerifyEmail';
import OrderSuccess from './components/OrderSuccess/OrderSuccess';
import OrderFail from './components/OrderFail/OrderFail';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  appBar: { flex: '0 1 auto' },
  body: { flex: '1 1 auto' },
  footer: { flex: '0 1 40px' },
}));

/**
 * @method AppRouter
 * @see module:AppBar
 * @see module:Footer
 * @description
 * the router renders header, main app area and the footer.\
 * the header and footer are shown at all times.\
 * the router switch is split into 3 categories,
 * 1. pages only logged in users can see, /user/...
 * 2. pages only logged out users can see, /auth/...
 * 3. other pages anyone can see.
 * Authentication, AuxPages and UserPages are nested routers
 */
const AppRouter = (props) => {
  const classes = useStyles();

  return (
    <Translation>
      {(t) => (
        <Router>
          <div className={classes.root}>
            <div>
              <AppBar
                showAuthor={() => {
                  console.warn('Author', t('siteauthor'));
                }}
                className={classes.appBar}
              />
            </div>
            <div className={classes.body}>
              <Switch>
                <Route path='/' component={Homepage} exact />
                {/* user must be logged out to see these pages */}
                <Route path='/auth' component={Authentication} />
                {/* any user can see these pages */}
                <Route path='/search' component={Search} />
                <Route path='/product' component={Product} />
                <Route path='/aux' component={AuxPages} />
                <Route path='/verify-email' component={VerifyEmail} />
                <Route path='/order-success' component={OrderSuccess} />
                <Route path='/order-fail' component={OrderFail} />

                {/* only logged in users can see these pages */}
                <Route path='/user' component={UserPages} />

                {/* TEMP ROUTE */}
                {/* FELT CUTE, MIGHT DELETE LATER */}
                <Route path='/temp' exact component={Temp} />

                {/* 404 */}
                <Route component={NoMatch404} />
              </Switch>
            </div>
            <div>
              <Footer className={classes.footer} />
            </div>
          </div>
        </Router>
      )}
    </Translation>
  );
};

export default AppRouter;
