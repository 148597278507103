/**
 * @module App
 */
import React, { useEffect, useCallback } from 'react';
import Router from './Router';
//translations and RTL
import { initReactI18next, I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { create } from 'jss';
import rtl from 'jss-rtl';
//material ui
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { StylesProvider, jssPreset } from '@material-ui/styles';
//import translations
import arabicTranslations from './translations/ar.json';
import englishTranslations from './translations/en.json';
import { useGlobalState } from './GlobalState';
import axios from 'axios';
import 'core-js/features/promise';

//theme
const globalThemeProps = {
  palette: {
    primary: {
      // veryLight: '#ecde51c4',
      // light: '#bfb55e',
      main: 'rgb(57,183,210)',
      // dark: "#C5B523",
      // dark: '#807726',
      // veryDark: '#4D4717',
      contrastText: '#fff',
    },
    secondary: {
      veryLight: '#f2f2f2',
      light: '#eaeaea',
      main: '#59595b',
      dark: '#414142',
      veryDark: '#1a1a1a',
      contrastText: '#000',
    },
  },

  props: {
    MuiPaper: {
      square: true,
    },
  },
  overrides: {
    // MuiTypography: { root: { fontSize: "30rem" } },
    MuiOutlinedInput: {
      input: { padding: '10px 14px' },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 13px) scale(1)',
      },
    },
    MuiPaper: {
      elevation1: { boxShadow: '0px 0 10px -4px rgba(0, 0, 0, 0.25)' },
    },
  },
};

//Configure JSS and RTL theme
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const rtlTheme = createMuiTheme({
  direction: 'rtl',
  ...globalThemeProps,
  typography: {
    fontFamily: 'inherit',
    fontSize: 13,
  },
});
const ltrTheme = createMuiTheme({
  ...globalThemeProps,
  typography: {
    fontFamily: 'inherit',
  },
});

//config i18next
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultLanguage: 'en',
    fallbackLng: 'en',
    otherLanguages: ['ar'],
    keySeparator: false,
    resources: {
      en: {
        translation: englishTranslations,
      },
      ar: {
        translation: arabicTranslations,
      },
    },
    lng: localStorage.getItem('language') || 'en',
    // keySeparator: false, // we do not use keys in form messages.welcome
    // interpolation: {
    //   escapeValue: false // react already safes from xss
    // }
  });
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const handleGlobalError = (error) => {
  console.log('global error.response', error.response);
  if (
    error &&
    error.response &&
    error.response.status &&
    error.response.status === 401
  ) {
    localStorage.removeItem('session');
    window.location.assign('/auth/sign-in?expired=true');
  }
};

/**
 * @method App
 * @requires I18nextProvider
 * @requires MuiThemeProvider
 * @requires StylesProvider
 * @description App initializes I18N, MUI themes
 * and returns the appropriate theme provider depending on the language
 * nested inside the I18N provider, then returns the router
 */
const App = () => {
  const [language] = useGlobalState('language');
  // console.log("language in global state", language);
  // console.log("language in local storage", localStorage.getItem("language"));

  const [loggedIn] = useGlobalState('loggedIn');
  const [session] = useGlobalState('session');
  const [, setUserData] = useGlobalState('userData');

  /**
   * @method getUserData
   * @description whenever the session token changes, or the loggedIn state from false to true,
   * the app automatically tries to fetch user data and sets in the global state
   */
  const getUserData = useCallback(() => {
    if (loggedIn && session && session.token) {
      console.log('App is getting user data');
      axios
        .get(apiEndpoint + 'customers/me', {
          headers: { Authorization: `Bearer ${session.token}` },
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
        })
        .then((res) => {
          // console.log('user data res', res);
          setUserData(res.data.data);
        })
        .catch((error) => {
          handleGlobalError(error);
          // console.log('error', error);
          setTimeout(getUserData, 1000);
        });
    }
  }, [loggedIn, session, setUserData]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <I18nextProvider i18n={i18n}>
      {language === 'ar' ? (
        <StylesProvider jss={jss}>
          <MuiThemeProvider theme={rtlTheme}>
            <Router />
          </MuiThemeProvider>
        </StylesProvider>
      ) : (
        <MuiThemeProvider theme={ltrTheme}>
          <Router />
        </MuiThemeProvider>
      )}
    </I18nextProvider>
  );
};

export default App;
