import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import GlobalState from "./GlobalState";
import * as serviceWorker from "./serviceWorker";
import WebFont from "webfontloader";

/**
 * @file Entry point to the application
 * Uses WebFont to download the two fonts used in the app then calls global state, the real entry point to the app
 * arabic font: Cairo
 * english font: Source sans pro
 */

WebFont.load({
  google: {
    families: ["Source+Sans+Pro:400,600", "Cairo"]
  }
});

ReactDOM.render(<GlobalState />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
