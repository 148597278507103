/**
 * @module Signup
 */
//React imports
import React, { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Checkbox,
  Box,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
//Other libraries
import { Translation } from "react-i18next";
import axios from "axios";
import {
  ValidatorComponent,
  ValidatorForm,
  TextValidator,
} from "react-material-ui-form-validator";
import { useGlobalState } from "../../../GlobalState";
import Helmet from "react-helmet";

import "./styles.css";
import { makeStyles } from "@material-ui/core/styles";
import MuiPhoneNumber from "material-ui-phone-number";
import arabicCountries from "../../../translations/ar-countries.json";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
/**
 * @method CheckboxValidatorElement
 * @description checkbox with validaation,
 * i.e. form cannot be submitted without the checkbox being checked
 * @see module:Signup
 */
class CheckboxValidatorElement extends ValidatorComponent {
  input = React.createRef();
  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      value,
      ...rest
    } = this.props;

    return (
      <Box>
        <Checkbox {...rest} ref={this.input} />
        {this.errorText()}
      </Box>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <div
        style={{
          // right: 0,
          fontSize: "12px",
          color: red["500"],
          position: "absolute",
          marginTop: "-10px",
        }}
      >
        {this.getErrorMessage()}
      </div>
    );
  }
}

const errorCodesToMessages = {
  666: "network error, please try again",
  1000: "first name is missing",
  1001: "last name is missing",
  1002: "e-mail is missing",
  1003: "password is missing",
  2000: "first name is less than 2 characters or more than 20 characters",
  2001: "last name is less than 2 characters or more than 20 characters",
  2002: "e-mail is more than 254 characters",
  2003: "password is less than 8 characters or more than 255 characters",
  2004: "phone number is more than 20 characters",
  3000: "e-mail is not valid",
  3001: "password is not valid",
  4000: "e-mail already exists",
};

const useStyles = makeStyles((theme) => ({
  whiteBackgroundLabel: { backgroundColor: "white" },
  root: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    margin: "40px 0px",
    alignItems: "center",
    justifyItems: "center",
    // height: "100%"
  },
  paper: {
    minWidth: 350,
    maxWidth: 700,
    width: "55%",
    minHeight: "200px",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    display: "flex",
  },
  form: {
    padding: "10px 20px",
    margin: "10px",
    width: "100%",
  },
  centeredFlex: {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    alignContent: "center",
  },
  mainButton: {
    width: "100%",
    minWidth: 200,
    maxWidth: 320,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },

  redText: {
    color: "red",
  },
  inlineBlock: { display: "inline-block" },
  absolutePosition: { position: "absolute" },
  flexStart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  errorContainer: { marginBottom: 4 },
}));
/**
 * @method Signup
 * @description signup component
 * @see module:Authentication
 * @requires {@link CheckboxValidatorElement}
 */
const Signup = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    repeatPassword: "",
    acceptLicenseAgreement: false,
    firstName: "",
    lastName: "",
  });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setLoggedIn] = useGlobalState("loggedIn");
  const [, setSession] = useGlobalState("session");
  const [language] = useGlobalState("language");
  const [userData, setUserData] = useGlobalState("userData");

  // console.log("language", language);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const emailRef = useRef(null);
  const handleBlur = (event) => {
    emailRef.current.validate(event.target.value);
  };
  const handleCheckboxChange = () => {
    setFormData((formData) => ({
      ...formData,
      acceptLicenseAgreement: !formData.acceptLicenseAgreement,
    }));
  };

  /**
   * @method handleSubmit
   *
   * @description if signup is successful,
   * saves session to local storage and sets loggedIn state in global state to true,
   * then redirects to a page where user should enter their address
   * @see module:AddAddressInitial
   */
  const handleSubmit = useCallback(() => {
    const {
      firstName: first_name,
      lastName: last_name,
      email,
      password,
      phoneNumber: phone,
    } = formData;
    setLoading(true);
    setErrors([]);

    axios
      .post(
        apiEndpoint + "customers/register",
        {
          first_name,
          last_name,
          email,
          phone,
          password,
        },
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
        }
      )
      .then((res) => {
        // console.log('sign up res', res);
        // cosnt token = res.data.token
        // console.log('token', token)
        // setUserData()
        setLoading(false);
        localStorage.setItem("session", JSON.stringify(res.data.data));
        setLoggedIn(true);
        setSession(res.data.data);
        props.history.push("/user/add-address");
      })
      .catch((error) => {
        setLoading(false);

        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          setErrors(error.response.data.errors);
        } else {
          setErrors([{ code: 666, message: "timeout" }]);
        }
      });
  }, [formData, props.history, setLoggedIn, setSession]);

  ValidatorForm.addValidationRule("isTruthy", (value) => value);
  ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
    if (value !== formData.password) {
      return false;
    }
    return true;
  });
  ValidatorForm.addValidationRule("minLength2", (value) => {
    if (!value) return true;
    return value.length >= 2 ? true : false;
  });
  ValidatorForm.addValidationRule("minLength8", (value) => {
    if (!value) return true;
    //
    //
    return value.length >= 8 ? true : false;
  });
  ValidatorForm.addValidationRule("maxLength20", (value) => {
    if (!value) return true;
    return value.length <= 20 ? true : false;
  });
  ValidatorForm.addValidationRule("maxLength254", (value) => {
    if (!value) return true;
    //
    return value.length <= 254 ? true : false;
  });
  ValidatorForm.addValidationRule("maxLength255", (value) => {
    if (!value) return true;
    return value.length <= 255 ? true : false;
  });

  ValidatorForm.addValidationRule("isComplexPassword", (value) => {
    return value.match(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}/
    );
  });

  const classes = useStyles();
  // console.log("errors", errors);
  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t("netjeek - sign up")}</title>
          </Helmet>
          <Box my={3}>
            <Typography>{t("welcome to netjeek, please sign up")}</Typography>
          </Box>
          <Paper className={classes.paper}>
            <ValidatorForm
              onSubmit={handleSubmit}
              className={classes.form}
              instantValidate={false}
            >
              <Grid container>
                {errors.length > 0 && (
                  <Grid item xs={12} className={classes.errorContainer}>
                    {errors.length === 1 ? (
                      errors.map((error, idx) => (
                        <Typography key={idx} className={classes.redText}>
                          {t(errorCodesToMessages[error.code])}
                        </Typography>
                      ))
                    ) : (
                      <ul>
                        {errors.map((error, index) => (
                          <li className={classes.redText} key={index}>
                            {t(errorCodesToMessages[error.code])}
                          </li>
                        ))}
                      </ul>
                    )}
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <Box pb={3} pr={{ xs: 0, md: 1 }}>
                    <TextValidator
                      key={language}
                      variant="outlined"
                      label={t("first name")}
                      onChange={handleChange}
                      name="firstName"
                      type="text"
                      validators={["required", "minLength2", "maxLength20"]}
                      errorMessages={[
                        t("this field is required"),
                        t("names must be between 2 and 20 characters"),
                        t("names must be between 2 and 20 characters"),
                      ]}
                      value={formData.firstName}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box pb={3} pl={{ xs: 0, md: 1 }}>
                    <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      variant="outlined"
                      label={t("last name")}
                      onChange={handleChange}
                      name="lastName"
                      type="text"
                      validators={["required", "minLength2", "maxLength20"]}
                      errorMessages={[
                        t("this field is required"),
                        t("names must be between 2 and 20 characters"),
                        t("names must be between 2 and 20 characters"),
                      ]}
                      value={formData.lastName}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pb={3}>
                    <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      variant="outlined"
                      ref={emailRef}
                      label={t("email")}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="email"
                      value={formData.email}
                      validators={["required", "isEmail", "maxLength254"]}
                      errorMessages={[
                        t("this field is required"),
                        t("email is not valid"),
                        t("email can't exceed 254 characters"),
                      ]}
                      fullWidth
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box pb={3} pr={{ xs: 0, md: 1 }}>
                    <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      variant="outlined"
                      label={t("password")}
                      onChange={handleChange}
                      name="password"
                      type="password"
                      validators={[
                        "required",
                        "minLength8",
                        "maxLength255",
                        "isComplexPassword",
                      ]}
                      errorMessages={[
                        t("this field is required"),
                        t(
                          "Password length must be between 8 and 255 characters"
                        ),
                        t(
                          "Password length must be between 8 and 255 characters"
                        ),
                        t(
                          "For your own safety, your password must contain at least 1 uppercase, 1 lowercase and 1 special character"
                        ),
                      ]}
                      value={formData.password}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box pb={3} pl={{ xs: 0, md: 1 }}>
                    <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      variant="outlined"
                      label={t("repeat password")}
                      onChange={handleChange}
                      name="repeatPassword"
                      type="password"
                      validators={["isPasswordMatch"]}
                      errorMessages={[t("password mismatch")]}
                      value={formData.repeatPassword}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pb={3}>
                    <MuiPhoneNumber
                      localization={language === "ar" ? arabicCountries : {}}
                      countryCodeEditable={false}
                      defaultCountry={"ly"}
                      onChange={(value) =>
                        setFormData({ ...formData, phoneNumber: value })
                      }
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      variant="outlined"
                      label={t("phone number")}
                      fullWidth
                    />
                    {/* <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      variant="outlined"
                      label={t("phone number")}
                      onChange={handleChange}
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      validators={["maxLength20"]}
                      errorMessages={[t("phone number is not valid")]}
                      fullWidth
                    /> */}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pb={2} className={classes.flexStart}>
                    <Box className={classes.inlineBlock}>
                      <CheckboxValidatorElement
                        validators={["isTruthy"]}
                        errorMessages={[t("you must accept license agreement")]}
                        onChange={handleCheckboxChange}
                        checked={formData.acceptLicenseAgreement}
                        value={formData.acceptLicenseAgreement} // <---- you must provide this prop, it will be used only for validation
                      />
                    </Box>
                    <Box className={classes.inlineBlock}>
                      <Typography>
                        {`${t("i accept")} `}

                        <Link
                          to="/aux/user-license-agreement"
                          onClick={(event) => {
                            event.preventDefault();
                            window.open("/aux/user-license-agreement");
                          }}
                        >
                          {t("user license agreement")}
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} className={classes.centeredFlex}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    className={classes.mainButton}
                  >
                    {(loading && t("please wait")) ||
                      (!loading && t("sign up"))}
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.absolutePosition}
                      />
                    )}
                  </Button>
                </Grid>

                <Grid item xs={12} className={classes.centeredFlex}>
                  <Box mt={1}>
                    <Typography variant="subtitle2">
                      {`${t("already have an account?")} `}
                      <Link to="/auth/sign-in">{t("sign in")}</Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Paper>
        </>
      )}
    </Translation>
  );
};

export default Signup;
