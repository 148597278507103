/**
 * @module Authentication
 */
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import Image from "material-ui-image";
import { useGlobalState } from "../../GlobalState";
//components
import RedirectToHomepage from "./RedirectToHomepage";
import Signup from "./Signup";
import Signin from "./Signin";
import AttemptResetPassword from "./AttemptResetPassword";
import ResetPassword from "./ResetPassword";

import NoMatch404 from "../NoMatch404";
import { makeStyles } from "@material-ui/core/styles";

const netgeek_en_logo = require("../../assets/images/Logo-en.png");
const netgeek_ar_logo = require("../../assets/images/Logo-ar.png");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    margin: "40px 0px",
    alignItems: "center",
    justifyItems: "center",
    // height: "100%"
  },
}));

/**
 * @method Authentication
 * @description authentication nested router /auth/...
 * checks if user is logged in
 * - if true, redirects to homepage
 * - if false, renders the appropriate page component
 * @requires Signin
 * @requires Signup
 * @requires ResetPassword
 * @requires AttemptResetPassword
 */
const Authentication = () => {
  const classes = useStyles();
  const [loggedIn] = useGlobalState("loggedIn");
  return (
    <main className={classes.root}>
      <Link to="/">
        <Image
          disableSpinner={true}
          style={{
            padding: "0",
            backgroundColor: "unset",
            textAlign: "center",
          }}
          imageStyle={{
            position: "relative",
            width: "30%",
            height: "unset",
          }}
          src={
            localStorage.getItem("language") == "en"
              ? netgeek_en_logo
              : netgeek_ar_logo
          }
        />
      </Link>
      {loggedIn ? (
        <RedirectToHomepage />
      ) : (
        <Switch>
          <Route path="/auth/sign-in" component={Signin} exact />
          <Route path="/auth/sign-up" component={Signup} exact />
          <Route
            path="/auth/attempt-reset-password"
            component={AttemptResetPassword}
            exact
          />
          <Route path="/auth/reset-password" component={ResetPassword} />
          {/* 404 */}
          <Route component={NoMatch404} />
        </Switch>
      )}
    </main>
  );
};

export default Authentication;
