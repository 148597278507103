/**
 * @module TwoEightTwoContainer
 */
import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: { padding: "0 12px", height: "100%" },
  rootContainer: { height: "100%" }
}));

/**
 * @method TwoEightTwoContainer
 * @description padded container component used overall the site
 */

const TwoEightTwoContainer = props => {
  const { children } = props;
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item lg={2} md={1} xs={false} />
      <Grid item lg={8} md={10} xs={12}>
        <Grid container className={classes.rootContainer}>
          {React.Children.toArray(children)}
        </Grid>
      </Grid>
      <Grid item lg={2} md={1} xs={false} />
    </Grid>
  );
};
export default TwoEightTwoContainer;
