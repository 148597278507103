/**
 * @module Footer
 */
//React imports
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Toolbar,
  Typography,
  Grid,
  List,
  ListItemText,
  Link as MaterialLink,
  ListItem,
  Divider,
  Box,
  Container,
  Hidden,
} from "@material-ui/core";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { Translation } from "react-i18next";
import TwoEightTwoContainer from "../TwoEightTwoContainer";
import classNames from "classnames";
import fbLogo from "./fb_logo.png";
import instaLogo from "./insta_logo.png";
import twitterLogo from "./twitter_logo.png";
import youtubeLogo from "./youtube_logo.png";
import whatsappLogo from "./whatsapp_logo.png";
import googlePlayLogo from "./googleplay_logo.png";
import appStoreLogo from "./appstore_logo.png";
import masterCardLogo from "../../assets/images/master-card.png";
import americanExpressLogo from "../../assets/images/amex.png";
import gPayLogo from "../../assets/images/google-pay.png";
import jcbLogo from "../../assets/images/jcb.png";
import moamaletLogo from "../../assets/images/moamalat.png";
import paypalLogo from "../../assets/images/paypal.png";
import sadadLogo from "../../assets/images/saddad.png";
import tadawulLogo from "../../assets/images/tadawul.png";
import visaLogo from "../../assets/images/visa.png";
import footerShape from "./footer-shape.png";
import { makeStyles } from "@material-ui/core/styles";
// import topArrowIcon from '@material-ui/icons/KeyboardArrowUp';
import topArrowIcon from "./top-arrow.png";
import Script from "react-load-script";
const netjeekLogo = require("./netjeekLogoClean.png");

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    display: isMobile ? "none" : "flex",
    flexDirection: "column",
  },
  container: {
    display: isMobile ? "none" : "flex",
    alignItems: "center",
    justifyItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  gridContainer: {
    // marginTop: "8px",
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
  },
  footerGridItem: {},
  goToTopBar: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    userSelect: "none",
    cursor: "pointer",
    minHeight: 0,
    backgroundColor: "#fff",
    transition: "height ease-out 0.5s",
    paddingLeft: "54px",
  },
  firstBar: {
    backgroundColor: "#fff",
    padding: "32px 0px",
  },
  secondBar: {
    backgroundColor: "#fff",
    // padding: '32px 0px',
    minHeight: 30,
    // maxHeight: 50
  },
  undecoratedLink: {
    textDecoration: "none",
    color: theme.palette.primary.contrastText,
  },
  rowSpaceFlex: {
    display: "flex",
    justifyContent: "flex-start",
  },
  centeredFlex: {
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  contrastText: {
    color: theme.palette.secondary.light,
  },
  yellowHover: {
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  inlineText: {
    display: "inline-flex",
  },
  contactUsItemContainer: { margin: "0px 0" },
  contactUsIcon: { fontSize: 13 },
  paymentIconsContainer: { width: "100%", paddingTop: 16, display: "contents" },
  paymentIcon: { fontSize: 60, padding: 4 },
  smallGreyText: {
    fontSize: 16,
    color: theme.palette.secondary.main,
  },
  divider: {
    minWidth: 70,
    maxWidth: 200,
  },
  firstContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  contactUsText: { fontSize: 17, fontWeight: 700, lineHeight: 1.5 },
  copyrightText: { fontSize: 12, color: theme.palette.secondary.light },
  goToTopText: {
    fontSize: 20,
    // fontWeight: 'bold',
    color: "#000",
  },
  topArrow: {
    fontWeight: "bold",
    // fontSize: 50,
    height: "24px",
    marginRight: "8px",
    paddingBottom: "12px",
    color: theme.palette.primary.main,
  },
  listHeaderStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    letterSpacing: 1,
    color: "rgb(19,25,77)",
  },
  listItemStyle: {
    color: "rgb(176,166,169) !important",
    fontSize: "18px",
  },
  logoStyle: {
    height: "32px",
  },
  downloadAppStyle: {
    height: "48px",
  },
  copyrightStyle: {
    whiteSpace: "nowrap",
    fontSize: "15px",
    fontWeight: "bold",
    color: "rgb(19,25,77)",
  },
  paymentLogoStyle: {
    height: "60px",
  },
  footerMenuItemStyle: {
    color: "rgb(23,194,222)",
  },
  skewedBox: {
    transform: `skew(-40deg, 0deg)`,
    height: "100%",
    width: "20px",
    margin: "0px 2px",
  },
  footerContainer: {
    marginLeft: "auto",
  },
  chatWidgetContainer: {
    "& #zsiqbtn": {
      width: "100% !important",
      height: "auto !important",
      zIndex: "10 !important",
    },
    "& img": {
      width: "100% !important",
    },
  },
}));
const VerticalDivider = (props) => {
  const { margin } = props;
  const classes = makeStyles(() => ({
    divider: {
      height: "90%",
      margin: "16px 0px",
      borderRight: "2px solid rgb(219,219,219)",
    },
  }))();
  return <div className={classes.divider} />;
};

/**
 * @method Footer
 * @description footer component
 * @see module:Router
 */
const Footer = () => {
  const classes = useStyles();
  const [windowHasVerticalScroll, setWindowHasVerticalScroll] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setWindowHasVerticalScroll(
        document.body.scrollHeight > document.body.clientHeight * 1.5 &&
          window.scrollY > 0
      );
    }, 800);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const chatWidget = useMemo(() => {
    var d = document;
    const s = d.createElement("script");
    s.type = "text/javascript";
    s.id = "zsiqscript";
    s.defer = true;
    s.src = "https://salesiq.zoho.com/widget";
    const t = d.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(s, t);
    return <div id="zsiqwidget"></div>;
  }, []);

  return (
    <Translation>
      {(t) => (
        <div className={classes.root}>
          <Toolbar
            style={{
              height: windowHasVerticalScroll ? 50 : 0,
            }}
            disableGutters
            className={classNames(classes.goToTopBar)}
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            {windowHasVerticalScroll && (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box display="flex" alignItems="start" height="100%">
                  <img src={topArrowIcon} className={classes.topArrow} />
                </Box>
                <Typography className={classes.goToTopText}>
                  {t("top of page")}
                </Typography>
              </Box>
            )}
          </Toolbar>

          <Toolbar
            disableGutters
            position="static"
            className={classNames(classes.container, classes.firstBar)}
          >
            <Container maxWidth="xl">
              <Grid container className={classes.gridContainer}>
                <Grid item xs={6} md={2} className={classes.footerGridItem}>
                  <Box>
                    <Typography className={classes.listHeaderStyle}>
                      {t("my account")}
                    </Typography>
                    <Box display="flex" flexDirection="column">
                      <MaterialLink
                        href="user/cart"
                        className={classes.listItemStyle}
                      >
                        {t("my cart")}
                      </MaterialLink>
                      <MaterialLink
                        href="/user/profile/orders"
                        className={classes.listItemStyle}
                      >
                        {t("my orders")}
                      </MaterialLink>
                      <MaterialLink
                        href="user/profile/address"
                        className={classes.listItemStyle}
                      >
                        {t("my addresses")}
                      </MaterialLink>
                      <MaterialLink
                        href="/user/profile/settings"
                        className={classes.listItemStyle}
                      >
                        {t("account settings")}
                      </MaterialLink>
                      <MaterialLink
                        href="/user/profile/settings"
                        className={classes.listItemStyle}
                      >
                        {t("account summary")}
                      </MaterialLink>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} md={2} className={classes.footerGridItem}>
                  <Box>
                    <Typography className={classes.listHeaderStyle}>
                      {t("buying on")}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <MaterialLink
                      href="/aux/how-to-buy"
                      className={classes.listItemStyle}
                    >
                      {t("how to buy")}
                    </MaterialLink>
                    <MaterialLink
                      href="/aux/return-policy"
                      className={classes.listItemStyle}
                    >
                      {t("return policy")}
                    </MaterialLink>
                    <MaterialLink
                      href="/aux/help"
                      className={classes.listItemStyle}
                    >
                      {t("help")}
                    </MaterialLink>
                  </Box>
                </Grid>
                <Grid item xs={6} md={2} className={classes.footerGridItem}>
                  <Box>
                    <Typography className={classes.listHeaderStyle}>
                      {t("become a seller")}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <MaterialLink
                      href="/aux/seller"
                      className={classes.listItemStyle}
                    >
                      {t("seller")}
                    </MaterialLink>
                    <MaterialLink
                      href="/aux/advertise-with-us"
                      className={classes.listItemStyle}
                    >
                      {t("advertise with us")}
                    </MaterialLink>
                  </Box>
                </Grid>
                <Hidden mdDown>
                  <Grid item>
                    <VerticalDivider margin={0} />
                  </Grid>
                </Hidden>
                <Grid item xs={6} md={2} className={classes.footerGridItem}>
                  {/* <VerticalDivider margin={0} /> */}
                  <Box>
                    <Typography className={classes.listHeaderStyle}>
                      {t("connect with us")}
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Grid container className={classes.gridContainer}>
                      <Grid item>
                        <MaterialLink href="https://www.facebook.com/Netjeekcom-112480257273603/?modal=admin_todo_tour">
                          <img
                            src={fbLogo}
                            alt="fb-logo"
                            className={classes.logoStyle}
                          />
                        </MaterialLink>
                      </Grid>
                      <Grid item>
                        <MaterialLink href="https://www.instagram.com/netjeek/">
                          <img
                            src={instaLogo}
                            alt="insta-logo"
                            className={classes.logoStyle}
                          />
                        </MaterialLink>
                      </Grid>
                      <Grid item>
                        <MaterialLink href="https://twitter.com/home?lang=en">
                          <img
                            src={twitterLogo}
                            alt="twitter-logo"
                            className={classes.logoStyle}
                          />
                        </MaterialLink>
                      </Grid>
                      <Grid item>
                        <MaterialLink href="https://youtube.com">
                          <img
                            src={youtubeLogo}
                            alt="youtube-logo"
                            className={classes.logoStyle}
                          />
                        </MaterialLink>
                      </Grid>
                      <Grid item>
                        <MaterialLink href="https://wa.me/218915013333">
                          <img
                            src={whatsappLogo}
                            alt="whatsapp-logo"
                            className={classes.logoStyle}
                          />
                        </MaterialLink>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid container className={classes.gridContainer}>
                      <Grid item>
                        <MaterialLink href="https://www.apple.com/ios/app-store/">
                          <img
                            src={appStoreLogo}
                            className={classes.downloadAppStyle}
                          />
                        </MaterialLink>
                      </Grid>
                      <Grid item>
                        <MaterialLink href="https://play.google.com/store">
                          <img
                            src={googlePlayLogo}
                            className={classes.downloadAppStyle}
                          />
                        </MaterialLink>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Hidden mdDown>
                  <Grid item>
                    <VerticalDivider margin={0} />
                  </Grid>
                </Hidden>
                <Grid
                  item
                  xs={6}
                  md={2}
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                  className={classes.chatWidgetContainer}
                >
                  {chatWidget}
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
          <Toolbar
            disableGutters
            position="static"
            className={classes.secondBar}
          >
            <Container maxWidth="xl">
              <Grid
                container
                item
                xs={12}
                md={12}
                className={classes.gridContainer}
              >
                <Grid item>
                  <img
                    src={masterCardLogo}
                    alt="masterCard-logo"
                    className={classes.paymentLogoStyle}
                  />
                </Grid>
                <Grid item>
                  <img
                    src={visaLogo}
                    alt="visa-logo"
                    className={classes.paymentLogoStyle}
                  />
                </Grid>
                <Grid item>
                  <img
                    src={americanExpressLogo}
                    alt="american-express-logo"
                    className={classes.paymentLogoStyle}
                  />
                </Grid>
                <Grid item>
                  <img
                    src={paypalLogo}
                    alt="paypal-logo"
                    className={classes.paymentLogoStyle}
                  />
                </Grid>
                <Grid item>
                  <img
                    src={gPayLogo}
                    alt="g-pay-logo"
                    className={classes.paymentLogoStyle}
                  />
                </Grid>
                <Grid item>
                  <img
                    src={jcbLogo}
                    alt="jcb-logo"
                    className={classes.paymentLogoStyle}
                  />
                </Grid>
                <Grid item>
                  <img
                    src={moamaletLogo}
                    alt="moamalet-logo"
                    className={classes.paymentLogoStyle}
                  />
                </Grid>
                <Grid item>
                  <img
                    src={tadawulLogo}
                    alt="tadawul-logo"
                    className={classes.paymentLogoStyle}
                  />
                </Grid>
                <Grid item>
                  <img
                    src={sadadLogo}
                    alt="sadad-logo"
                    className={classes.paymentLogoStyle}
                  />
                </Grid>
              </Grid>

              <Grid container className={classes.gridContainer}>
                <Grid
                  item
                  xs={12}
                  md={2}
                  style={{
                    textAlign: "center",
                    alignItems: "flex-start",
                    display: "flex",
                    marginTop: "10px",
                  }}
                >
                  <Typography className={classes.copyrightStyle}>
                    © 2020 Netjeek.com
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={8}
                  className={
                    classes.gridContainer + " " + classes.footerContainer
                  }
                  style={{
                    alignItems: "center",

                    position: "relative",
                  }}
                >
                  <Grid item>
                    <MaterialLink
                      href="/aux/about-us"
                      className={classes.footerMenuItemStyle}
                    >
                      {t("about us")}
                    </MaterialLink>
                  </Grid>
                  <Grid item>
                    <MaterialLink
                      href="/aux/careers"
                      className={classes.footerMenuItemStyle}
                    >
                      {t("careers")}
                    </MaterialLink>
                  </Grid>
                  <Grid item>
                    <MaterialLink
                      href="/aux/privacy-policy"
                      className={classes.footerMenuItemStyle}
                    >
                      {t("privacy policy")}
                    </MaterialLink>
                  </Grid>
                  <Grid item>
                    <MaterialLink
                      href="/aux/terms-and-conditions"
                      className={classes.footerMenuItemStyle}
                    >
                      {t("terms and conditions")}
                    </MaterialLink>
                  </Grid>
                  <Grid item>
                    <MaterialLink
                      href="/aux/contact-us"
                      className={classes.footerMenuItemStyle}
                    >
                      {t("contact us")}
                    </MaterialLink>
                  </Grid>
                  <Grid
                    item
                    style={{
                      height: "100%",
                      display: "flex",
                      alignSelf: "flex-end",
                    }}
                  >
                    <Box
                      className={[classes.skewedBox].join(" ")}
                      style={{ background: "#2a2f60", marginLeft: "10px" }}
                    ></Box>
                    <Box
                      className={[classes.skewedBox].join(" ")}
                      style={{ background: "#3bc7e1" }}
                    ></Box>
                    <Box
                      className={[classes.skewedBox].join(" ")}
                      style={{ background: "#fcc557" }}
                    ></Box>
                  </Grid>
                  {/* <Hidden lgDown>
                    <Grid item xs={1}>
                      <img
                        src={footerShape}
                        alt="footer-shape"
                        style={{ height: "39px" }}
                      />
                    </Grid>
                  </Hidden> */}
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </div>
      )}
    </Translation>
  );
};

export default Footer;
