import React, { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import Script from 'react-load-script';

export default function ContactUs() {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Box my={3}>
      <Script
        url='https://desk.zoho.com/portal/api/feedbackwidget/541304000000162001?orgId=725577290&displayType=embeded'
        onCreate={() => setIsLoading(true)}
        onError={() => setIsLoading(false)}
        onLoad={() => setIsLoading(false)}
      />

      {isLoading ? (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='500px'
        >
          <CircularProgress color='primary' />
        </Box>
      ) : (
          <div id='zsfeedbackwidgetdiv' />
        )}
    </Box>
  );
}
