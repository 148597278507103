/**
 * @module StripeWrapper
 */
import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { CircularProgress, Typography, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useGlobalState } from '../../../GlobalState';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { handleGlobalError } from './../../../App';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mainButton: {
    marginTop: '4px',
    width: '100%',
    minWidth: 200,
    maxWidth: 320,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  centeredFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

/**
 * @method StripeWrapper
 * @description initializes and calls stripe
 * @param {Object} props component props
 * @param {boolean} paymentError if true, an error has occurred in parent component (Checkout) and payment cannot proceed
 * @see module:Checkout
 */
const StripeWrapper = (props) => {
  const [session] = useGlobalState('session');
  const [error, setError] = useState(false);
  const { paymentError } = props;
  const history = useHistory();
  /**
   * @callback anonymous
   *
   * @description calls to the api to generate a stripe session ID
   * and then redirects to a stripe page with it
   */
  useEffect(() => {
    const { sessionId: session_id, deliveryNotes: customer_notes } = props;
    let cancelTokenSource = new axios.CancelToken.source();

    !paymentError &&
      axios
        .post(
          apiEndpoint + 'orders/checkout',
          { session_id, customer_notes },
          {
            timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
            headers: { Authorization: `Bearer ${session.token}` },
            cancelToken: cancelTokenSource.token,
          }
        )
        .then((res) => {
          const stripe = window.Stripe(
            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
          );
          // console.log('stripe token data res', res.data.data);
          const { stripe_token: stripeSessionId } = res.data.data;
          // console.log('res.data', res.data);
          // console.log('stripeSessionId', stripeSessionId);
          stripe
            .redirectToCheckout({ sessionId: stripeSessionId })
            .then(function () {
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `result.error.message`.
            });
        })
        .catch((error) => {
          handleGlobalError(error);
          setError(true);
          // console.log('error', error);
        });

    return () => {
      // To cancel previous request use:
      cancelTokenSource.cancel('Operation canceled');
      cancelTokenSource = new axios.CancelToken.source();
    };
  }, [props, paymentError]);

  const classes = useStyles();
  return (
    <Translation>
      {(t) => (
        <div className={classes.root}>
          {!error ? (
            !paymentError ? (
              <CircularProgress />
            ) : (
              <>
                <Typography>{t('payment failed, please try again')}</Typography>
                <Button
                  variant='contained'
                  className={classes.mainButton}
                  onClick={() => {
                    history.push('/user/checkout');
                    history.go();
                  }}
                >
                  {t('click here to refresh')}
                </Button>
              </>
            )
          ) : (
            <Box p={2} className={classes.centeredFlex}>
              <Typography>{t('an unexpected error occurred')}</Typography>
              <Button onClick={() => history.go()}>
                {t('click here to refresh')}
              </Button>
            </Box>
          )}
        </div>
      )}
    </Translation>
  );
};

export default StripeWrapper;
