/**
 * @module Shortcuts
 */

//React imports
import React from "react";
import Image from "material-ui-image";
import { Link } from "react-router-dom";
import { Typography, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Translation } from "react-i18next";
import { styles } from "../styles";
import TwoEightTwoContainer from "../../../common/TwoEightTwoContainer";
import { makeStyles } from "@material-ui/core/styles";

const useStyles2 = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  root: { margin: "30px 0" },
  featuredCategoriesText: { fontSize: 14 },
  multiColorLine: { marginBottom: "10px" },
}));

/**
 * @method Shortcuts
 * @description featured categories component
 * @param {Object} props component props
 * @param {Array.<{search_key: string, image_url: string, retailer: string}>} props.shortcuts
 */

const Shortcuts = (props) => {
  const { shortcuts } = props;
  const classes2 = useStyles2();
  const classes = useStyles();
  return (
    <Translation>
      {(t) => (
        <TwoEightTwoContainer className={classes.root}>
          {/* name and divider */}
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.featuredCategoriesText}>
              {t("featured categories").toLocaleUpperCase()}
            </Typography>
          </Grid>

          {/* colored line */}
          <Grid item container xs={12} className={classes.multiColorLine}>
            <Grid item xs={2} className={classes2.multiColorLine1} />
            <Grid item xs={10} className={classes2.multiColorLine2} />
          </Grid>

          {/* grid items map*/}
          <Grid container spacing={1}>
            {shortcuts
              ? shortcuts.map((shortcut, idx) => {
                  const {
                    image_url: imageUrl,
                    retailer,
                    search_key: searchKey,
                  } = shortcut;
                  return (
                    <Grid key={idx} item xs={6} sm={4}>
                      <Link
                        to={`/search?retailer=${retailer}&query=${searchKey}`}
                      >
                        <Image
                          disableSpinner={true}
                          style={{
                            padding: "0",
                            backgroundColor: "unset",
                            height: "100%",
                          }}
                          imageStyle={{
                            position: "relative",
                            width: "100%",
                            height: "unset",
                          }}
                          src={imageUrl}
                        />
                      </Link>
                    </Grid>
                  );
                })
              : [...Array(4)].map((_item, idx) => (
                  <Grid item xs={6} sm={4} key={idx}>
                    <Skeleton height="260px" width="100%" />
                  </Grid>
                ))}
          </Grid>
        </TwoEightTwoContainer>
      )}
    </Translation>
  );
};

export default Shortcuts;
