/**
 * @module DataFetcher
 */
import {
  useState,
  // , useEffect, useRef
} from 'react';
import axios from 'axios';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { handleGlobalError } from './../../App';

// console.log("axios", axios);
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

let cancelTokenSource = new axios.CancelToken.source();

/**
 * @method DataFetcher
 * @description an attempt at a data fetching API,
 * that implements the infamous Function as Child Component (or FaCC) pattern,
 * used it once in Review component
 * @see module:Review
 * @param {Object} props component props
 * @param {function|class} props.onError react component to show when error happens
 * @param {function|class} props.onLoading react component to show when request is en route
 * @param {string} props.route api sub route for the request
 * @param {Object} props.headers request headers object
 * @param {string} props.method request method
 * @param {Object} props.requestData post request options object

 */
const DataFetcher = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  // useWhyDidYouUpdate("Data Fetcher", props);

  const {
    onError: errorComponent,
    onLoading: loadingComponent,
    route: subRoute,
    headers,
    method,
    requestData,
  } = props;

  useDeepCompareEffect(() => {
    setIsLoading(true);
    setError(false);

    const requestOptions = {
      timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
      cancelToken: cancelTokenSource.token,
      headers: headers,
    };

    const route = apiEndpoint + subRoute;
    const promise =
      method === 'get'
        ? axios.get(route, requestOptions)
        : axios[method](route, requestData, requestOptions);

    promise
      .then((res) => {
        // console.log("res", res);
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        handleGlobalError(error);
        setError(true);
        setIsLoading(false);
      });

    return () => {
      cancelTokenSource.cancel('Christmas is canceled!');
      cancelTokenSource = new axios.CancelToken.source();
    };
  }, [method, requestData, subRoute]);

  return !isLoading
    ? !error
      ? props.children(data)
      : typeof errorComponent === 'function'
      ? errorComponent()
      : errorComponent
    : loadingComponent;
};

export default DataFetcher;
