import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import Image from "material-ui-image";

import { Translation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
const netgeek_en_logo = require("../../assets/images/Logo-en.png");
const netgeek_ar_logo = require("../../assets/images/Logo-ar.png");

const useStyles = makeStyles((theme) => ({
  centeredFlex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  absolute: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh !important",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    background: "#fff",
    zIndex: 100,
  },
}));
export default function OrderSuccess() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Translation>
      {(t) => (
        <>
          <MobileView>
            <div className={classes.absolute}>
              <Box className={classes.centeredFlex} style={{ padding: "20px" }}>
                <Image
                  disableSpinner={true}
                  style={{ padding: "0", textAlign: "center" }}
                  imageStyle={{
                    maxHeight: "50px",
                    // maxWidth: "80%",
                    maxWidth: "100%",
                    position: "relative",
                    height: "unset",
                    width: "unset",
                    marginBottom: "10px",
                  }}
                  src={
                    localStorage.getItem("language") === "en"
                      ? netgeek_en_logo
                      : netgeek_ar_logo
                  }
                />
                <Typography variant="h5" gutterBottom>
                  {t("thank you for your order")}
                </Typography>
                <Typography variant="subtitle1">
                  {/* Your order number is #2001539.  */}

                  {t(
                    "we have sent your order confirmation via email. we will send you an update when your order is shipped"
                  )}
                </Typography>
                <Box my={2}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      window.ReactNativeWebView &&
                      window.ReactNativeWebView.postMessage("close")
                    }
                  >
                    {t("continue shopping")}
                  </Button>
                </Box>
              </Box>
            </div>
          </MobileView>
          <BrowserView>
            <div className={classes.centeredFlex}>
              <Box className={classes.centeredFlex}>
                <Typography variant="h5" gutterBottom>
                  {t("thank you for your order")}
                </Typography>
                <Typography variant="subtitle1">
                  {/* Your order number is #2001539.  */}

                  {t(
                    "we have sent your order confirmation via email. we will send you an update when your order is shipped"
                  )}
                </Typography>
                <Box my={2}>
                  <Button variant="outlined" onClick={() => history.push("/")}>
                    {t("continue shopping")}
                  </Button>
                </Box>
              </Box>
            </div>
          </BrowserView>
        </>
      )}
    </Translation>
  );
}
