import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';

import { Translation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  centeredFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));
export default function OrderSuccess() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Translation>
      {(t) => (
        <div className={classes.centeredFlex}>
          <Box className={classes.centeredFlex}>
            <Typography variant='h5' gutterBottom>
              {t('order creation, please try again')}
            </Typography>

            <Box my={2}>
              <Button
                variant='outlined'
                onClick={() => history.push('/user/checkout')}
              >
                {t('try again')}
              </Button>
            </Box>
          </Box>
        </div>
      )}
    </Translation>
  );
}
