/**
 * @module RedirectToHomepage
 */
import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Translation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import { useGlobalState } from '../../GlobalState';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const useStyles = makeStyles(() => ({
  box: {
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    display: 'flex',
    height: '100%',
    padding: 16,
  },
  paper: {
    width: 600,
    minHeight: '200px',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    display: 'flex',
  },
}));

/**
 * @method VerifyEmail
 * @description this component tries to verify user email address based on
 * email and token received from the url
 */
const VerifyEmail = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [userData, setUserData] = useGlobalState('userData');
  const { is_email_verified: isEmailVerified } = userData;

  const location = useLocation();
  const { email, token } = queryString.parse(location.search);
  const classes = useStyles();

  useEffect(() => {
    // if (!isEmailVerified && Object.keys(userData).length)
    axios
      .post(apiEndpoint + 'customers/verify-email', {
        email,
        token,
      })
      .then((res) => {
        setUserData({ ...userData, is_email_verified: true });
        setError(false);
      })
      .catch((err) => {
        // console.log('err', err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Translation>
      {(t) => (
        <div className={classes.box}>
          <Paper className={classes.paper}>
            {!isEmailVerified
              ? !loading
                ? !error
                  ? t(
                      'your email has been verified successfully, now you can use all site features'
                    )
                  : t('request failed, please try again')
                : t('please wait')
              : t(
                  'your email has been verified successfully, now you can use all site features'
                )}
          </Paper>
        </div>
      )}
    </Translation>
  );
};

export default VerifyEmail;
