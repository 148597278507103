/**
 * @module TermsAndConditions
 */
import React, { useRef, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import { useGlobalState } from "../../../GlobalState";

const useStyles = makeStyles(() => ({
  root: { direction: "rtl", height: "100%" },
  page: {
    width: "100%",
    height: "100%",
    // minHeight: "400px",
    // maxHeight: "1000px"
  },
  iframeContainer: { height: "100%" },
}));

/**
 * @method TermsAndConditions
 * @description Privacy Policy component,
 * uses iframe to display imported privacy policy html file
 */
function TermsAndConditions() {
  const classes = useStyles();
  const [language] = useGlobalState("language");
  const iframeEl = useRef(null);
  const [iframeHeight, setIframeHeight] = useState(0);

  const calcHeight = () =>
    setIframeHeight(iframeEl.current.contentWindow.document.body.scrollHeight);
  return (
    <>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <Box p={2} className={classes.root}>
        <Grid container className={classes.iframeContainer}>
          <iframe
            src={
              language === "en"
                ? "/terms-and-conditions-en/index.html"
                : "/terms-and-conditions-ar/index.html"
            }
            title="Terms and Conditions"
            className={classes.page}
            align="center"
            frameborder="0"
            style={{
              border: 0,
              minHeight: "100%",
              overflow: "hidden",
              height: iframeHeight
            }}
            ref={iframeEl}
            onLoad={calcHeight}
          />
        </Grid>
      </Box>
    </>
  );
}

export default TermsAndConditions;
