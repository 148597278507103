/**
 * @module Review
 */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DataFetcher from "../../../common/DataFetcher";
import { useGlobalState } from "../../../GlobalState";
import {
  Button,
  Typography,
  Grid,
  Card,
  TextField,
  Box,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Translation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Image from "material-ui-image";
import numeral from "numeral";
import { CircularProgress, Backdrop } from "@material-ui/core";
import DiscountBox from "./DiscountBox";
import VoucherBox from "./VoucherBox";

import axios from "axios";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 8,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  centeredFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  itemTitle: { fontSize: "0.9rem", fontWeight: "600" },
  timer: {
    fontSize: "1.5rem",
    fontWeight: "600",
    marginBottom: "8px",
    marginTop: "8px",
  },
  itemPrice: {
    color: theme.palette.secondary.main,
    marginRight: "3px",
    fontSize: "0.85rem",
    fontWeight: "600",
  },
  itemRetailer: { display: "inline-flex" },
  mainButton: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  // descriptionContainer: { padding: "10px 15px 10px 7px" }, //toto's version
  descriptionContainer: { padding: 4 }, //my version
  orderInfoContainer: {
    alignContent: "flex-start",
  },
  orderPricesContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  horizontalDivider: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginTop: "4px",
    marginBottom: "4px",
  },
  orderPrice: {
    fontWeight: 600,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

/**
 * @method Review
 * @description lists current items in cart and displays summary about the order
 * for the user to confirm before checking out
 * @param {Object} props component props
 * @param {Object} props.addressData the address the user entered (or confirmed)
 * to calculate shipping fees based on
 * @param {function} props.handleNext function to go to next step
 * @param {function} props.handleBack function to go to previous step
 * @param {function} props.handleTextFieldChange function to propagate delivery
 * notes field contents to parent Checkout
 * @param {function} props.setSessionId function to propagate session ID to parent Checkout
 * @param {function} props.setTimeoutError function to set parent error state to true when timer
 * reaches zero so that parent component Checkout can show an error message
 * @see module:Checkout
 */
const Review = (props) => {
  const classes = useStyles();
  const [session] = useGlobalState("session");

  const [displayVoucherBox, setDisplayVoucherBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [voucher, setVoucher] = useState(0);
  //timer
  const [endTime, setEndTime] = useState();
  const {
    addressData,
    handleNext,
    goToStep,
    handleBack,
    handleTextFieldChange,
    setSessionId,
    setTimeoutError,
  } = props;

  const history = useHistory();

  /**
   * @method calculateTimeLeft
   *
   * @returns {number} time left in the timer
   * @description calculates remaining time until timer reaches zero
   */
  const calculateTimeLeft = () => {
    const difference = endTime - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  /**
   * @callback anonymous
   *
   * @description initializes timer
   */
  useEffect(() => setEndTime(+new Date() + 600000), []);
  /**
   * @callback anonymous
   *
   * @description checks for timeout
   */
  useEffect(() => {
    const difference = endTime - new Date();
    if (!isNaN(difference) && difference < 0) setTimeoutError(true);
  }, [timeLeft, endTime, setTimeoutError]);
  /**
   * @callback anonymous
   *
   * @description counts down each second
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  const {
    streetAddress: street_address,
    city,
    region: state,
    zip,
    nearestLandmark: nearest_landmark,
    position: geo_location,
  } = addressData;

  /**
   * @method sumItems
   *
   * @param {Object} items items in cart
   * @returns {number} the prices of all items
   * @description sums the prices of all items
   */
  const sumItems = (items) => {
    return items.reduce(
      (acc, current) => acc + current.price * current.quantity,
      0
    );
  };

  const onApplyDiscount = (data) => {
    setSessionId(data.session_id);
    if (data.discount) {
      setDiscount(data.discount.amount);
    }
  };
  const onApplyVoucher = (data) => {
    setSessionId(data.session_id);
    if (data.discount) {
      setVoucher(data.discount.amount);
    }
  };

  const onApplyDiscountFail = () => {
    setDiscount(0);
  };

  const onApplyVoucherFail = () => {
    setVoucher(0);
  };
  const handleFreeOrder = (e, sessionId) => {
    setIsLoading(true);
    console.log("sessionId", sessionId);
    try {
      const result = axios.post(
        apiEndpoint + "orders/create-zero-order",
        { session_id: sessionId },
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
          headers: { Authorization: `Bearer ${session.token}` },
        }
      );
      if (result) {
        setIsLoading(false);
        history.push("/order-success");
      }
    } catch (error) {
      setIsLoading(false);
      history.push("/order-fail");
    }
  };
  return (
    <Grid container spacing={2}>
      <Translation>
        {(t) => (
          <DataFetcher
            method="post"
            onError={
              <Grid item xs={12}>
                <Box p={2} className={classes.centeredFlex}>
                  <Typography>{t("an unexpected error occurred")}</Typography>
                  <Button onClick={() => history.go()}>
                    {t("click here to refresh")}
                  </Button>
                </Box>
              </Grid>
            }
            onLoading={
              <>
                <Backdrop
                  className={classes.backdrop}
                  open={true}
                  // onClick={() => setIsBackdropOpen(false)}
                >
                  <CircularProgress color="primary" />
                </Backdrop>
                <Grid item xs={12} md={8}>
                  {[...Array(4)].map((_item, idx) => (
                    <Skeleton key={idx} width="100%" height="100px" />
                  ))}
                </Grid>
                <Grid item xs={12} md={4}>
                  <Skeleton width="100%" height="30px" />
                  <Skeleton width="100%" height="150px" />
                </Grid>
              </>
            }
            route="orders/summary"
            headers={{ Authorization: `Bearer ${session.token}` }}
            requestData={{
              shipping_address: {
                street_address,
                city: city,
                state: state,
                country: "Libya",
                zip,
                nearest_landmark,
                geo_location,
              },
            }}
            // requestData={{
            //   city: 'Tripoli',
            //   country: 'Libya',
            //   geo_location: { lng: 20.865198245312484, lat: 28.20343627032132 },
            //   nearest_landmark: 'aaaaa',
            //   state: 'Tripoli',
            //   street_address: '1a1a1a1',
            //   zip: '1234',
            // }}
          >
            {({ data }) => {
              setSessionId(data.session_id);
              // console.log('review data', data);
              let totalPrice = parseFloat(
                sumItems(data.items) + data.shipping_fees - discount - voucher
              ).toFixed(3);
              return (
                <>
                  <Grid item container spacing={1} xs={12} md={8}>
                    {data.items.map((item, idx) => (
                      <Grid item xs={12} key={idx}>
                        <Card>
                          <Grid container spacing={1}>
                            {/* image */}
                            <Grid
                              item
                              xs={5}
                              sm={3}
                              xl={2}
                              className={classes.flexEnd}
                            >
                              <Link
                                to={`/product?retailer=${item.retailer}&id=${item.retailer_item_id}`}
                              >
                                <Image
                                  disableSpinner={true}
                                  style={{
                                    padding: "0",
                                    backgroundColor: "unset",
                                    height: "100%",
                                  }}
                                  imageStyle={{
                                    position: "relative",
                                    width: "unset",
                                    height: "unset",
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                  }}
                                  src={item.image_url}
                                />
                              </Link>
                            </Grid>
                            {/* description */}
                            <Grid item xs={7} sm={9} xl={10}>
                              <Box className={classes.descriptionContainer}>
                                {/* name */}
                                <Grid item xs={12}>
                                  <Typography className={classes.itemTitle}>
                                    {item.title}
                                  </Typography>
                                </Grid>
                                {item.variant && (
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      {item.variant.join(" - ")}
                                    </Typography>
                                  </Grid>
                                )}
                                {/* price*/}
                                <Grid item xs={12}>
                                  <Typography
                                    variant="caption"
                                    className={classes.itemPrice}
                                  >
                                    {`${numeral(item.price).format(
                                      process.env
                                        .REACT_APP_DEFAULT_CURRENCY_FORMAT
                                    )}`}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    className={classes.itemRetailer}
                                  >
                                    {(() => {
                                      switch (
                                        item.retailer.toLocaleLowerCase()
                                      ) {
                                        case "amazon_ca":
                                          return `${t("from")} ${t("amazon")}`;
                                        case "walmart_ca":
                                          return `${t("from")} ${t("walmart")}`;
                                        case "aliexpress":
                                          return `${t("from")} ${t(
                                            "aliexpress"
                                          )}`;
                                        default:
                                          return `${t("from")} ${t(
                                            "unknown vendor"
                                          )}`;
                                      }
                                    })()}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="caption">{`${t(
                                    "Quantity"
                                  )}: ${item.quantity}`}</Typography>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  {/* checkout timer and notes */}
                  <Grid
                    item
                    xs={12}
                    md={4}
                    container
                    className={classes.orderInfoContainer}
                  >
                    {/* timer */}
                    {(timeLeft.seconds || timeLeft.minutes) && (
                      <>
                        <Grid item xs={12} className={classes.centeredFlex}>
                          <Typography style={{ textAlign: "center" }}>
                            {t("please check out before the timer runs out")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.centeredFlex}>
                          <Typography className={classes.timer}>
                            {`${numeral(timeLeft.minutes).format(
                              "00"
                            )}:${numeral(timeLeft.seconds).format("00")}`}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {/* prices */}
                    <Grid item xs={12} className={classes.orderPricesContainer}>
                      <Typography>{t("order total")}</Typography>
                      <Typography className={classes.orderPrice}>
                        {numeral(sumItems(data.items)).format(
                          process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.orderPricesContainer}>
                      <Typography>{t("shipping fees")}</Typography>
                      <Typography className={classes.orderPrice}>
                        {numeral(data.shipping_fees).format(
                          process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                        )}
                      </Typography>
                    </Grid>
                    {discount > 0 && (
                      <Grid
                        item
                        xs={12}
                        className={classes.orderPricesContainer}
                      >
                        <Typography>{t("discount")}</Typography>
                        <Typography className={classes.orderPrice}>
                          {numeral(discount * -1).format(
                            process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                          )}
                        </Typography>
                      </Grid>
                    )}
                    {/* {voucher > 0 && (
                      <Grid
                        item
                        xs={12}
                        className={classes.orderPricesContainer}
                      >
                        <Typography>{t('voucher')}</Typography>
                        <Typography className={classes.orderPrice}>
                          {numeral(voucher * -1).format(
                            process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                          )}
                        </Typography>
                      </Grid>
                    )} */}
                    <Grid item xs={12}>
                      <div className={classes.horizontalDivider} />
                    </Grid>
                    <Grid item xs={12} className={classes.orderPricesContainer}>
                      <Typography>{t("grand total")}</Typography>
                      <Typography className={classes.orderPrice}>
                        {numeral(
                          parseFloat(
                            sumItems(data.items) + data.shipping_fees - discount
                          ).toFixed(3)
                        ).format(process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT)}
                      </Typography>
                    </Grid>
                    {/* delivery notes */}

                    <Grid
                      item
                      xs={12}
                      style={{ display: voucher <= 0 ? "flex" : "none" }}
                    >
                      <DiscountBox
                        sessionId={data.session_id}
                        callback={onApplyDiscount}
                        fail={onApplyDiscountFail}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box pt={2}>
                        <TextField
                          multiline
                          variant="outlined"
                          label={t("delivery notes")}
                          onChange={(event) =>
                            handleTextFieldChange(event.target.value)
                          }
                          fullWidth
                          rows="4"
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {!displayVoucherBox && (
                        <Typography>
                          <Button
                            onClick={() => {
                              setDisplayVoucherBox(true);
                              return false;
                            }}
                          >
                            {t("Have a voucher?")}
                          </Button>
                        </Typography>
                      )}
                      {displayVoucherBox && (
                        <VoucherBox
                          sessionId={data.session_id}
                          callback={onApplyVoucher}
                          fail={onApplyVoucherFail}
                        />
                      )}
                    </Grid>
                    {voucher > 0 && (
                      <Typography>
                        {t("Voucher amount")}:{" "}
                        {numeral(parseFloat(voucher).toFixed(3)).format(
                          process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                        )}
                      </Typography>
                    )}
                    {/* back and next buttons */}
                    {sumItems(data.items) +
                      data.shipping_fees -
                      discount -
                      voucher >
                    0 ? (
                      <Grid item xs={12} className={classes.buttons}>
                        <Box mr={1}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(e, gateway) => handleNext(e, "sadaad")}
                            className={classes.mainButton}
                          >
                            {t("pay via sadaad")} (
                            {(() => {
                              // numeral.locale("ly");
                              return numeral(
                                parseFloat(
                                  sumItems(data.items) +
                                    data.shipping_fees -
                                    discount -
                                    voucher
                                ).toFixed(3) * data.exchange_rate_usd_lyd
                              ).format("0,0.00");
                              // numeral.locale("en");
                            })()}
                            &nbsp;
                            {t("LYD")})
                          </Button>
                        </Box>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.mainButton}
                        >
                          {t("pay via stripe")} (
                          {numeral(
                            parseFloat(
                              sumItems(data.items) +
                                data.shipping_fees -
                                discount -
                                voucher
                            ).toFixed(3)
                          ).format(
                            process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                          )}
                          )
                        </Button>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Box mt={1}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => handleFreeOrder(e, data.session_id)}
                            className={classes.mainButton}
                            disabled={isLoading}
                          >
                            {isLoading && (
                              <CircularProgress
                                color="primary"
                                size={24}
                                style={{ position: "absolute" }}
                              />
                            )}
                            {t("palce order")}
                          </Button>
                        </Box>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Box mt={2}>
                        <Button onClick={handleBack}>{t("back")}</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          </DataFetcher>
        )}
      </Translation>
    </Grid>
  );
};
export default Review;
