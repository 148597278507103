/**
 * @module AuxPages
 */
import React from "react";
import { Route, Switch } from "react-router-dom";
//components
import PrivacyPolicy from "./PrivacyPolicy/";
import NoMatch404 from "../NoMatch404";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import Careers from "./Careers";
import HowToBuy from "./HowToBuy";
import ReturnPolicy from "./ReturnPolicy";
import Help from "./Help";
import Seller from "./Seller";
import AdvertiseWithUs from "./AdvertiseWithUs";
import TermsAndConditions from "./TermsAndConditions";
import UserLicenseAgreement from "./UserLicenseAgreement";
/**
 * @method AuxPages
 * @description auxilary pages (as I call them) nested router\
 * this includes rarely visited pages in the site like privacy policy
 * @see module:PrivacyPolicy
 */
const AuxPages = () => {
  return (
    <Switch>
      <Route path="/aux/how-to-buy" component={HowToBuy} />
      <Route path="/aux/return-policy" component={ReturnPolicy} />
      <Route path="/aux/help" component={Help} />
      <Route path="/aux/seller" component={Seller} />
      <Route path="/aux/advertise-with-us" component={AdvertiseWithUs} />
      <Route path="/aux/about-us" component={AboutUs} />
      <Route path="/aux/careers" component={Careers} />
      <Route path="/aux/privacy-policy" component={PrivacyPolicy} />
      <Route path="/aux/contact-us" component={ContactUs} />
      <Route path="/aux/terms-and-conditions" component={TermsAndConditions} />
      <Route
        path="/aux/user-license-agreement"
        component={UserLicenseAgreement}
      />

      {/* 404 */}
      <Route component={NoMatch404} />
    </Switch>
  );
};

export default AuxPages;
