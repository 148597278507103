/**
 * @module Search
 */
//React imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from 'material-ui-image';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import {
  Button,
  Typography,
  withStyles,
  Grid,
  Paper,
  CircularProgress,
  Card,
  Box,
  Tooltip,
} from '@material-ui/core';
//Other libraries
import { Translation } from 'react-i18next';
import axios from 'axios';
import { Skeleton, Rating } from '@material-ui/lab';

import TwoEightTwoContainer from '../../common/TwoEightTwoContainer';
import classNames from 'classnames';

import Helmet from 'react-helmet';
import numeral from 'numeral';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const styles = (theme) => {
  const numberOfLines = 2;
  const lineHeight = 21;
  return {
    content: {
      flexGrow: 1,
      padding: '12px 0',
      overflow: 'auto',
      height: '100%',
    },
    errorBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    itemPaper: {
      boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.1)',
      height: '100%',
      borderRadius: '0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    itemImage: {
      overflow: 'hidden',
      maxHeight: '98%',
      maxWidth: '98%',
      userSelect: 'none',
    },
    imageCard: {
      // height: "250px",
      width: '100%',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      justifyItems: 'center',
      boxShadow: 'none',
      borderRadius: '0',
      '&:hover': {
        filter: 'brightness(90%)',
      },
    },
    titlePriceContainer: {
      textAlign: 'center',
      paddingBottom: '4px',
      margin: '4px',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      justifyItems: 'center',
    },
    twoLineText: {
      maxWidth: '100%',
      overflow: 'hidden',
      display: '-webkit-box',
      lineHeight: `${lineHeight}px`,
      maxHeight: `${lineHeight * numberOfLines}px`,
      WebkitLineClamp: `${numberOfLines}`,
      WebkitBoxOrient: 'vertical',
    },
    centeredFlex: {
      alignItems: 'center',
      justifyItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    boldFont: { fontWeight: 600 },
    mainButton: {
      width: '100%',
      minWidth: 200,
      maxWidth: 320,
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    undecoratedLink: { textDecoration: 'none' },
    displayMoreContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    relativePosition: { position: 'relative' },
    absolutePosition: { position: 'absolute' },
  };
};

/**
 * @component
 * @classdesc search page component
 */
class Search extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    itemList: [],
    loading: true,
    page: 1,
    error: false,
    displayMore: true,
    moreLoading: false,
  };

  cancelTokenSource = new axios.CancelToken.source();

  componentDidMount() {
    this.getData();
  }
  /**
   * @description searches with current item and retailer and saves result in state
   * @param {boolean} append
   * - if false, replaces current search items, happens when you search for a new keyword
   * - if true, appends new results to old ones, when you press get more items
   */
  getData = (append = false) => {
    this.setState({ error: false });
    const { location } = this.props;
    const { query, retailer } = queryString.parse(location.search);
    const { page } = this.state;
    // console.log("page", page);
    // To cancel previous request
    typeof this.cancelTokenSource != typeof undefined &&
      this.cancelTokenSource.cancel('search canceled');

    this.cancelTokenSource = new axios.CancelToken.source();

    axios
      .post(
        apiEndpoint + 'search/',
        {
          retailer: retailer.toLocaleUpperCase(),
          search_key: query,
        },
        {
          params: {
            page,
          },
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
          cancelToken: this.cancelTokenSource.token,
        }
      )
      .then((res) => {
        this.setState({
          loading: false,
          moreLoading: false,
          itemList: append
            ? this.state.itemList.concat(res.data.data)
            : res.data.data,
          displayMore: res.data.data.length >= 10,
        });
      })
      .catch((error) => {
        // console.log('search error', error);
        if (!append && !axios.isCancel(error)) this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ moreLoading: false });
      });
  };
  /**
   * @description refreshes search results if necessary
   * @param {Object} oldProps used to compare previous and current key
   */
  componentDidUpdate(oldProps) {
    if (oldProps.location.key !== this.props.location.key) {
      this.setState({ loading: true });
      this.getData();
    }
  }
  /**
   * @description gets more search items
   */
  handleGetMoreItems = () => {
    this.setState({ moreLoading: true, page: this.state.page + 1 }, () => {
      this.getData(true);
    });
  };
  /**
   * @description dictates how many 1/12s of the screen each item should take,
   * depending on the screen width
   */
  gridItemSize = { xs: 6, sm: 4, md: 3, lg: 3, xl: 2 };

  render() {
    const { classes } = this.props;
    const { itemList, loading, error, displayMore, moreLoading } = this.state;
    const { query } = queryString.parse(this.props.location.search);
    const resultIsEmpty = itemList.length === 0;
    return (
      <Translation>
        {(t) => (
          <main className={classes.content}>
            <Helmet>
              <title>{`${query} - ${t('search results')}`}</title>
            </Helmet>
            <TwoEightTwoContainer>
              {!error ? (
                !loading ? (
                  !resultIsEmpty ? (
                    <>
                      {itemList.map((item) => {
                        let {
                          image_url: imageUrl,
                          price,
                          rating,
                          retailer,
                          retailer_item_id: retailerItemId,
                          title,
                        } = item;
                        if (typeof price != 'number') {
                          price = price.trim();
                          price = price.replace('US', '');
                          price = price.replace('- ', ' - $');
                        }
                        // console.log("item image", imageUrl);

                        return (
                          <Grid
                            key={retailerItemId}
                            item
                            {...this.gridItemSize}
                          >
                            <Link
                              to={`/product?retailer=${retailer}&id=${retailerItemId}`}
                              className={classes.undecoratedLink}
                            >
                              <Paper className={classes.itemPaper}>
                                <Card className={classes.imageCard}>
                                  <Image
                                    disableSpinner={true}
                                    style={{
                                      padding: 2,
                                      backgroundColor: 'unset',
                                      height: '100%',
                                      display: 'flex',
                                      alignContent: 'center',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      justifyItems: 'center',
                                    }}
                                    imageStyle={{
                                      position: 'relative',
                                      width: 'unset',
                                      height: 'unset',
                                      maxHeight: '160px',
                                      maxWidth: '100%',
                                    }}
                                    src={imageUrl}
                                    className={classes.itemImage}
                                  />
                                </Card>

                                <div className={classes.titlePriceContainer}>
                                  <Tooltip
                                    disableFocusListener
                                    disableTouchListener
                                    arrow
                                    title={title}
                                    placement='bottom'
                                    enterDelay={700}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      className={classNames(
                                        classes.twoLineText,
                                        classes.boldFont
                                      )}
                                    >
                                      {title}
                                    </Typography>
                                  </Tooltip>
                                  <Typography variant='inherit'>
                                    {typeof price === 'number'
                                      ? numeral(price).format(
                                          process.env
                                            .REACT_APP_DEFAULT_CURRENCY_FORMAT
                                        )
                                      : price}
                                  </Typography>
                                  <Rating
                                    value={rating}
                                    precision={0.5}
                                    size='small'
                                    readOnly
                                  />
                                </div>
                              </Paper>
                            </Link>
                          </Grid>
                        );
                      })}

                      {displayMore && (
                        <Box pt={3} className={classes.displayMoreContainer}>
                          <Box className={classes.relativePosition}>
                            <Button
                              disabled={moreLoading}
                              className={classes.mainButton}
                              onClick={this.handleGetMoreItems}
                              variant='contained'
                            >
                              {t('load more items')}
                              {moreLoading && (
                                <CircularProgress
                                  size={24}
                                  className={classes.absolutePosition}
                                />
                              )}
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </>
                  ) : (
                    //found no items
                    <Grid item xs={12}>
                      <Box className={classes.errorBox}>
                        <Typography>{t('no results found')}</Typography>
                      </Box>
                    </Grid>
                  )
                ) : (
                  // loading items
                  <Grid item container xs={12} spacing={1}>
                    {[...Array(10)].map((_item, idx) => (
                      <Grid item {...this.gridItemSize} key={idx}>
                        <Skeleton height={160} width='100%' variant='rect' />
                        <Skeleton height={20} width='80%' />
                        <Skeleton height={20} width='20%' />
                        <Skeleton height={20} width='40%' />
                      </Grid>
                    ))}
                  </Grid>
                )
              ) : (
                //an error has occurred
                <Box
                  p={2}
                  style={{ width: '100%' }}
                  className={classes.centeredFlex}
                >
                  <Typography>
                    {t('an unexpected error occurred. please try again')}
                  </Typography>
                </Box>
              )}
            </TwoEightTwoContainer>
          </main>
        )}
      </Translation>
    );
  }
}

export default withStyles(styles)(Search);
