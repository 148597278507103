/**
 * @module ErrorMessageBar
 */
//React imports
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Toolbar,
  Typography,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  Box,
  CircularProgress,
} from "@material-ui/core";
//Other libraries
import { Translation } from "react-i18next";
import classNames from "classnames";
import { useGlobalState } from "../../GlobalState";
import axios from "axios";
import { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import queryString from "query-string";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
let cancelTokenSource = new axios.CancelToken.source();
const useStyles = makeStyles((theme) => ({
  errorMessageDialog: {
    minHeight: 100,
    minWidth: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  centeredFlex: {
    alignItems: "center",
    justifyItems: "center",
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
  },
  errorMessageBar: {
    backgroundColor: theme.palette.secondary.dark,
    minHeight: 0,
  },
  errorMessage: { color: theme.palette.secondary.light },
  fullWidth: { width: "100%" },
  linkLikeButton: {
    color: theme.palette.primary.main,
    "&:hover": { color: theme.palette.primary.light },
  },
}));

/**
 * @method ErrorMessageBar
 * @description renders the error message bar under the app bar that tells the user
 * their email is unverified
 */
const ErrorMessageBar = () => {
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const [session] = useGlobalState("session");
  const [userData] = useGlobalState("userData");

  const classes = useStyles();
  const { email, is_email_verified: isEmailVerified } = userData;

  const location = useLocation();
  const { verify_email: verifyEmail } = queryString.parse(location.search);

  /**
   * @method resendVerificationEmail
   * @description sends a request to resend verification email
   */
  const resendVerificationEmail = useCallback(() => {
    cancelTokenSource.cancel("Operation canceled");
    cancelTokenSource = new axios.CancelToken.source();
    setErrorMessageDialogOpen(true);
    setLoading(true);
    axios
      .post(
        apiEndpoint + "customers/resend-verification-email",
        {},
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
          headers: { Authorization: `Bearer ${session.token}` },
          cancelToken: cancelTokenSource.token,
        }
      )
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, [session.token]);

  useEffect(() => {
    if (verifyEmail) {
      setErrorMessageDialogOpen(true);
      resendVerificationEmail();
    }
  }, [verifyEmail, resendVerificationEmail]);

  // console.log("isEmailVerified", isEmailVerified);
  return (
    <Translation>
      {(t) => (
        <Collapse in={isEmailVerified === false}>
          <Toolbar
            disableGutters
            position="static"
            className={classes.errorMessageBar}
          >
            <Box
              p={0.25}
              className={classNames(classes.fullWidth, classes.centeredFlex)}
            >
              <Typography className={classes.errorMessage} variant="caption">
                {`${t("your email")} "${email}" ${t("is not verified")}. ${t(
                  "please check your inbox for instructions or click"
                )} `}
                <Link
                  to="/"
                  className={classes.linkLikeButton}
                  onClick={(event) => {
                    event.preventDefault();
                    setErrorMessageDialogOpen(true);
                    resendVerificationEmail();
                  }}
                >
                  {t("here")}
                </Link>
                {` ${t("to resend")}`}
              </Typography>
            </Box>
            <Dialog
              open={errorMessageDialogOpen}
              onClose={() => setErrorMessageDialogOpen(false)}
            >
              <DialogContent className={classes.errorMessageDialog}>
                {!loading ? (
                  <DialogContentText>
                    {success
                      ? `${t("verification email has been sent")}. ${t(
                          "check your e-mail for instructions"
                        )}.`
                      : t("request failed, please try again")}
                  </DialogContentText>
                ) : (
                  <CircularProgress size={24} />
                )}
              </DialogContent>
            </Dialog>
          </Toolbar>
        </Collapse>
      )}
    </Translation>
  );
};
export default ErrorMessageBar;
