/**
 * @module ItemCarousel
 */
import React, { useState, useEffect } from 'react';
import Image from 'material-ui-image';
import { Link } from 'react-router-dom';
import { Typography, Grid, Card, Box, Tooltip } from '@material-ui/core';
import { Translation } from 'react-i18next';
import classNames from 'classnames';
import { styles } from '../styles';
import { Carousel } from 'antd';
// import "antd/dist/antd.css";
import '../carousel.css';
import numeral from 'numeral';
import useWidth from './../../../common/useWidth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export const widthsToCards = {
  xs: 6,
  sm: 4,
  md: 3,
  lg: 3,
  xl: 2,
};
/**
 * @method ItemCarousel
 * @description top picks item carousel. each carousel view contains a number of items
 * depending on the page width
 *
 * @param {Object} props
 * @param {Object} props.item item contains title and items array
 * @param {Object} props.carouselRef reference carried down from top picks and passed down to carousel
 * @see module:sliceAndDice
 */
const ItemCarousel = (props) => {
  // const [updated, setUpdated] = useState(false);
  const [productArrays, setProductArrays] = useState([[]]);
  const width = useWidth();
  const classes = useStyles();
  const { item, carouselRef } = props;

  useEffect(() => {
    /**
     * @method sliceAndDice
     * @description converts item.items from an array of items, to an array of arrays of items
     * so that each of those arrays can be rendered into a carousel page/view
     */
    const sliceAndDice = () => {
      const numberOfCards = 12 / widthsToCards[width];

      if (item.items.length !== 0) {
        let productArrays = [];

        for (let i = 0; i < Math.ceil(item.items.length / numberOfCards); ++i) {
          productArrays.push(
            item.items.slice(numberOfCards * i, numberOfCards * (i + 1))
          );
        }
        setProductArrays(productArrays);
      }
    };
    sliceAndDice();
  }, [width, item.items]);

  return (
    <Translation>
      {(t) => (
        <Carousel
          ref={carouselRef}
          dots={false}
          effect='fade'
          easing='ease-out'
        >
          {productArrays.map((array, idx) => (
            <Box
              // className={classes.productsContainer}
              key={idx}
              // p={1}
            >
              <Grid
                key={idx}
                container
                spacing={1}
                className={classes.productsContainer}
              >
                {array.map((product, idx2) => {
                  let {
                    image_url: imageUrl,
                    price,
                    retailer_item_id: retailerItemId,
                    title,
                  } = product;

                  if (typeof price != 'number') {
                    price = price.trim();
                    price = price.replace('US', '');
                    price = price.replace('- ', ' - $');
                  }
                  const { retailer } = item;
                  return (
                    <Grid
                      key={idx2}
                      className={classes.topPicksCardContainer}
                      item
                      {...widthsToCards}
                    >
                      <Link
                        to={`/product?retailer=${retailer}&id=${retailerItemId}`}
                        className={classes.undecoratedLink}
                      >
                        <Card className={classes.productCard}>
                          <Image
                            disableSpinner={true}
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              justifyItems: 'center',
                              alignContent: 'center',
                              justifyContent: 'center',
                              // height: "200px",
                              // maxHeight: "200px",
                              padding: '0',
                              width: '100%',
                              height: '100%',
                            }}
                            imageStyle={{
                              position: 'relative',
                              width: 'unset',
                              height: 'unset',
                              maxWidth: '100%',
                              maxHeight: '170px',
                            }}
                            className={classes.topPicksImage}
                            src={imageUrl}
                          />

                          <div className={classes.typographyContainer}>
                            <Tooltip
                              disableFocusListener
                              disableTouchListener
                              arrow
                              title={title}
                              placement='top'
                              enterDelay={700}
                            >
                              <Typography
                                variant='h6'
                                className={classNames(
                                  classes.twoLineText,
                                  classes.productTitle
                                )}
                              >
                                {title}
                              </Typography>
                            </Tooltip>
                            <Typography
                              variant='h6'
                              className={classes.itemPrice}
                            >
                              {typeof price === 'number'
                                ? numeral(price).format(
                                    process.env
                                      .REACT_APP_DEFAULT_CURRENCY_FORMAT
                                  )
                                : price}
                            </Typography>
                          </div>
                        </Card>
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          ))}
        </Carousel>
      )}
    </Translation>
  );
};

export default ItemCarousel;
