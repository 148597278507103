/**
 * @module Address
 */
import React from 'react';
import Helmet from 'react-helmet';
import { Paper, DialogContent, DialogTitle } from '@material-ui/core';
import AddressAndLocationPicker from '../../../../common/AddressAndLocationPicker';
import { makeStyles } from '@material-ui/core/styles';
import { Translation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: { marginBottom: '10px' },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  dialogContent: { padding: 12 },
}));

/**
 * @method Address
 * @description shows an address picker for the user to change their current address and map location
 * @requires AddressAndLocationPicker
 */
const Address = () => {
  const classes = useStyles();
  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t('change shipping address')}</title>
          </Helmet>
          <Paper className={classes.paper}>
            <DialogTitle className={classes.dialogTitle}>
              {t('change shipping address')}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <AddressAndLocationPicker loadInfo={true} />
            </DialogContent>
          </Paper>
        </>
      )}
    </Translation>
  );
};

export default Address;
