/**
 * @module Checkout
 */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  StepConnector,
  Box,
} from "@material-ui/core";
import Image from "material-ui-image";
//components
import AddressForm from "./AddressForm";
import Review from "./Review";
import StripeWrapper from "./StripeWrapper";
import SadaadWrapper from "./SadaadWrapper";

import { Helmet } from "react-helmet";
import queryString from "query-string";
import { Link, Redirect } from "react-router-dom";
import useWidth from "../../../common/useWidth";
import { Translation } from "react-i18next";
import { useGlobalState } from "../../../GlobalState";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    minHeight: 150,
    width: 1000,
    maxWidth: "95%",

    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  stepperHorizontal: {
    padding: theme.spacing(3, 0, 5),
    width: "100%",
  },
  stepperVertical: {
    padding: "24px 0",
  },
  verticalStepperConnector: { padding: "8px 0" },
  verticalStepperConnectorLine: { minHeight: "6px" },

  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    width: "100%",
  },
  mainButton: {
    width: "100%",
    minWidth: 200,
    maxWidth: 320,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  centeredFlex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  timeout: {
    width: "100%",
    minHeight: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const steps = [
  "confirm shipping address",
  "review your order",
  "payment",
  // 'order placed',
];

const netjeek_en_logo = require("../../../assets/images/Logo-en.png");
const netjeek_ar_logo = require("../../../assets/images/Logo-ar.png");

/**
 * @method Checkout
 * @description checkout page component
 * @requires AddressForm
 * @requires Review
 * @requires StripeWrapper
 */
const Checkout = (props) => {
  //user input
  const [addressData, setAddressData] = useState({});
  const [deliveryNotes, setDeliveryNotes] = useState("");
  //session ID
  const [sessionId, setSessionId] = useState(null);
  //error
  const [paymentError, setPaymentError] = useState(false);
  const [timeoutError, setTimeoutError] = useState(false);
  const [userData] = useGlobalState("userData");

  //email verified stuff
  const isEmailVerified = userData.is_email_verified;
  const [redirect, setRedirect] = useState(false);

  //Payment Gateway
  const [gateway, setGateway] = useState("");
  //width
  const width = useWidth();
  //stepper components
  /**
   * @method getStepContent
   *
   * @param {number} step current step number
   * @description depending on the current checkout step, renders the appropriate component
   */
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddressForm handleNext={handleNext} addressSetter={setAddressData} />
        );
      case 1:
        return (
          <Review
            addressData={addressData}
            handleNext={handleNext}
            handleBack={handleBack}
            goToStep={goToStep}
            handleTextFieldChange={setDeliveryNotes}
            setSessionId={setSessionId}
            setTimeoutError={setTimeoutError}
          />
        );
      case 2:
        return gateway === "sadaad" ? (
          <SadaadWrapper sessionId={sessionId} paymentError={paymentError} />
        ) : (
          <StripeWrapper
            paymentError={paymentError}
            sessionId={sessionId}
            deliveryNotes={deliveryNotes}
          />
        );
      // case 3:
      //   return (
      //     <Translation>
      //       {(t) => (
      //         <>
      //           <div className={classes.centeredFlex}>
      //             <Typography variant='h5' gutterBottom>
      //               {t('thank you for your order')}
      //             </Typography>
      //             <Typography variant='subtitle1'>
      //               {/* Your order number is #2001539.  */}

      //               {t(
      //                 'we have sent your order confirmation via email. we will send you an update when your order is shipped'
      //               )}
      //             </Typography>
      //           </div>
      //         </>
      //       )}
      //     </Translation>
      //   );
      default:
        throw new Error("Unknown step");
    }
  }
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    let { success } = queryString.parse(props.location.search);

    if (success === "false") {
      setActiveStep(2);
      setPaymentError(true);
    } else if (success === "true") {
      setActiveStep(3);
      props.history.push("/order-success");
    }
    // console.log('success', success);
  }, [props.location.search]);
  /**
   * @method handleNext
   *
   * @description goes to next step in checkout process
   */
  const handleNext = (e, gateway) => {
    // console.log('gateway', gateway);
    setGateway(gateway);
    setActiveStep(activeStep + 1);
  };

  /**
   * @method handleBack
   *
   * @description goes to previous step in checkout process
   */
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const goToStep = (step) => {
    setActiveStep(step);
  };
  // redirect effect
  useEffect(() => {
    let timer;
    if (isEmailVerified === false) {
      timer = setTimeout(() => {
        setRedirect(true);
      }, 2500);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isEmailVerified]);

  return (
    <Translation>
      {(t) => (
        <main className={classes.root}>
          <Helmet>
            <title>{t("order checkout")}</title>
          </Helmet>
          <Link to="/">
            <Image
              disableSpinner={true}
              style={{
                padding: "0",
                backgroundColor: "unset",
                height: "100%",
                textAlign: "center",
              }}
              imageStyle={{
                position: "relative",
                // width: "120px",
                width: "30%",
                height: "unset",
              }}
              src={
                localStorage.getItem("language") === "en"
                  ? netjeek_en_logo
                  : netjeek_ar_logo
              }
            />
          </Link>
          {redirect && <Redirect to="/?verify_email=true"></Redirect>}
          <Paper className={classes.paper}>
            {isEmailVerified === false ? (
              <Typography component="h2" variant="h6" align="center">
                {t(
                  "your email hasn't been verified, you'll be redirected to the homepage"
                )}
              </Typography>
            ) : !timeoutError ? (
              <>
                <Typography component="h1" variant="h4" align="center">
                  {t("checkout")}
                </Typography>
                <Stepper
                  orientation={width === "xs" ? "vertical" : "horizontal"}
                  alternativeLabel={width !== "xs"}
                  className={
                    width === "xs"
                      ? classes.stepperVertical
                      : classes.stepperHorizontal
                  }
                  connector={
                    width === "xs" ? (
                      <StepConnector
                        classes={{
                          lineVertical: classes.verticalStepperConnectorLine,
                        }}
                        className={classes.verticalStepperConnector}
                      />
                    ) : (
                      <StepConnector />
                    )
                  }
                  activeStep={activeStep}
                >
                  {steps.map((label, idx) => (
                    <Step key={label}>
                      <StepLabel error={idx === 2 && paymentError}>
                        {t(label)}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {getStepContent(activeStep)}
              </>
            ) : (
              <div className={classes.timeout}>
                <Box mb={2}>
                  <Typography>
                    {t("time's up! please refresh this page and try again")}
                  </Typography>
                </Box>
                <Button
                  className={classes.mainButton}
                  variant="contained"
                  onClick={() => {
                    props.history.go();
                  }}
                >
                  {t("refresh")}
                </Button>
              </div>
            )}
          </Paper>
        </main>
      )}
    </Translation>
  );
};
export default Checkout;
