/**
 * @module NoMatch404
 */
//React imports
import React from "react";
import { Link } from "react-router-dom";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Translation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    minHeight: "200px"
  }
}));
/**
 * @method NoMatch404
 * @description this is the 404 component each router uses
 * when it doesn't find the designated route
 * @see module:Router
 * @see module:Authentication
 * @see module:AuxPages
 * @see module:User
 * @see module:Profile
 */
const NoMatch404 = () => {
  const classes = useStyles();
  return (
    <Translation>
      {t => (
        <div className={classes.container}>
          <div className={classes.centeredFlex}>
            <Typography className={classes.centeredFlex} variant="h1">
              {":("}
            </Typography>
          </div>

          <Box ml={3}>
            <Typography variant="h1">404</Typography>
            <Typography>
              {t("couldn't find the page you're looking for")}
            </Typography>
            <Link to="/">
              <Typography>
                {t("click here to return to the homepage")}
              </Typography>
            </Link>
          </Box>
        </div>
      )}
    </Translation>
  );
};

export default NoMatch404;
