export const styles = (theme) => {
  const twoLines = 2;
  // const oneLine = 1;
  const lineHeight = 21;
  return {
    multiColorLine1: {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    multiColorLine2: {
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    card: {
      border: `1px solid ${theme.palette.secondary.veryLight}`,
      width: "100%",
      "&:hover": {
        opacity: "0.8",
      },
    },
    image: {
      maxWidth: "100%",
    },
    imageContainer: {
      position: "absolute",
      zIndex: "2",
      height: "100%",
    },
    typography: {
      margin: "1px",
    },

    centeredFlex: {
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      alignContent: "center",
      justifyContent: "center",
    },

    twoLineText: {
      maxWidth: "100%",
      overflow: "hidden",
      display: "-webkit-box",
      lineHeight: `${lineHeight}px`,
      maxHeight: `${lineHeight * twoLines}px`,
      WebkitLineClamp: `${twoLines}`,
      WebkitBoxOrient: "vertical",
    },

    topPicksCardContainer: {
      // display: "inline-block",
      // height: "100%",
      width: "100%",
    },
    productCard: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: 4,
    },
    productTitle: { padding: "0 4px", fontSize: 14, fontWeigth: "600" },
    topPicksImage: {
      maxHeight: "100%",
      maxWidth: "100%",
      userSelect: "none",
    },
    itemPrice: {
      color: theme.palette.secondary.veryDark,
      fontSize: 14,
      fontWeight: 600,
    },
    productsContainer: {
      display: "flex !important",
      padding: 16,
    },
    maxHeight: { height: "100%" },
    undecoratedLink: { textDecoration: "none" },
  };
};
