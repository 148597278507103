/**
 * @module RedirectToHomepage
 */
import React, { useState, useEffect } from "react";
import { Paper, Box } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Translation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  paper: {
    width: 600,
    minHeight: "200px",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    display: "flex"
  }
}));
/**
 * @method RedirectToHomepage
 * @description this component shows a message saying the user is already logged in
 * then redirects to the homepage
 * @see module:Authentication
 */
const RedirectToHomepage = props => {
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setRedirect(true);
    }, 2500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const classes = useStyles();

  return (
    <Translation>
      {t => (
        <Box m={4}>
          {redirect && <Redirect to="/"></Redirect>}
          <Paper className={classes.paper}>
            {t(
              "You're already logged in and you'll be redirected to the homepage"
            )}
          </Paper>
        </Box>
      )}
    </Translation>
  );
};

export default RedirectToHomepage;
