/**
 * @module Settings
 */
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Translation } from 'react-i18next';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useGlobalState } from '../../../../GlobalState';
import { makeStyles } from '@material-ui/core/styles';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { handleGlobalError } from './../../../../App';
import MuiPhoneNumber from 'material-ui-phone-number';
import arabicCountries from '../../../../translations/ar-countries.json';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const useStyles = makeStyles((theme) => ({
  paper: { marginBottom: '10px' },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  mainButton: {
    width: '100%',
    minWidth: 200,
    maxWidth: 320,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  textField: {
    minWidth: '150px',
    width: '100%',
    maxWidth: '250px',
    paddingLeft: '10px',
  },
  dialogContent: {
    padding: '8px 16px',
  },

  text: { width: '100px' },

  centerAlignedFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
  },
  whiteBackgroundLabel: { backgroundColor: 'white' },
  absolutePosition: { position: 'absolute' },
}));

/**
 * @method Settings
 * @description user settings page component
 */

const Settings = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    customerNumber: '',
  });
  const [language] = useGlobalState('language');
  const [isAccountInfoLoading, setIsAccountInfoLoading] = useState(false);
  const [isAccountInfoError, setIsAccountInfoError] = useState(false);
  const [
    isRequestPasswordResetLoading,
    setIsRequestPasswordResetLoading,
  ] = useState(false);
  const [requestPasswordResetText, setRequestPasswordResetText] = useState('');
  const [userData, setUserData] = useGlobalState('userData');
  const [session] = useGlobalState('session');

  /**
   * @callback anonymous
   *
   * @description gets user's current data
   */
  useDeepCompareEffect(() => {
    userData &&
      setFormData({
        firstName: userData.first_name,
        lastName: userData.last_name,
        phone: userData.phone,
        email: userData.email,
        customerNumber: userData.account_number,
      });
  }, [userData]);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    setIsAccountInfoLoading(true);
    setIsAccountInfoError(false);
    const {
      firstName: first_name,
      lastName: last_name,
      phoneNumber: phone,
    } = formData;

    axios
      .patch(
        apiEndpoint + 'customers/me',
        {
          phone,
          first_name,
          last_name,
        },
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
          cancelToken: null,
          headers: { Authorization: `Bearer ${session.token}` },
        }
      )
      .then((res) => {
        setUserData((userData) => ({
          ...userData,
          first_name,
          last_name,
          phone,
        }));
      })
      .catch((error) => {
        handleGlobalError(error);
        setIsAccountInfoError(true);
      })
      .finally(() => {
        setIsAccountInfoLoading(false);
      });
  };
  /**
   * @method handleRequestPasswordReset
   *
   * @description sends a request to the api to reset password
   */
  const handleRequestPasswordReset = () => {
    setIsRequestPasswordResetLoading(true);
    setRequestPasswordResetText('');
    axios
      .post(
        apiEndpoint + 'customers/attempt-password-reset',
        {},
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
          headers: { Authorization: `Bearer ${session.token}` },
        }
      )
      .then((res) => {
        setIsRequestPasswordResetLoading(false);

        setRequestPasswordResetText('check your e-mail for instructions');
      })
      .catch((error) => {
        handleGlobalError(error);
        setIsRequestPasswordResetLoading(false);

        setRequestPasswordResetText(
          'An unexpected error occurred, please try again'
        );
      });
  };

  const classes = useStyles();
  ValidatorForm.addValidationRule('minLength2', (value) => {
    if (!value) return true;
    return value.length >= 2 ? true : false;
  });
  ValidatorForm.addValidationRule('maxLength20', (value) => {
    if (!value) return true;
    return value.length <= 20 ? true : false;
  });

  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t('personal information')}</title>
          </Helmet>
          <Paper className={classes.paper}>
            <DialogTitle className={classes.dialogTitle}>
              {t('account settings')}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <ValidatorForm
                onSubmit={handleSubmit}
                className={classes.form}
                instantValidate={false}
              >
                <Grid container>
                  {isAccountInfoError && (
                    <Grid item xs={12}>
                      <Typography style={{ color: 'red' }}>
                        {t('error updating user data')}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box pb={3}>
                      <Grid container>
                        <Grid item xs={3} className={classes.centerAlignedFlex}>
                          <Typography>{t('customer number')}</Typography>
                        </Grid>
                        <Grid item xs={9} className={classes.centerAlignedFlex}>
                          <TextValidator
                            InputLabelProps={{
                              classes: {
                                root: classes.whiteBackgroundLabel,
                              },
                            }}
                            disabled
                            className={classes.textField}
                            variant='outlined'
                            name='customerNumber'
                            value={formData.customerNumber}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box pb={3}>
                      <Grid container>
                        <Grid item xs={3} className={classes.centerAlignedFlex}>
                          <Typography>{t('first name')}</Typography>
                        </Grid>
                        <Grid item xs={9} className={classes.centerAlignedFlex}>
                          <TextValidator
                            InputLabelProps={{
                              classes: {
                                root: classes.whiteBackgroundLabel,
                              },
                            }}
                            className={classes.textField}
                            variant='outlined'
                            onChange={handleChange}
                            name='firstName'
                            validators={[
                              'required',
                              'minLength2',
                              'maxLength20',
                            ]}
                            errorMessages={[
                              t('this field is required'),
                              t('names must be between 2 and 20 characters'),
                              t('names must be between 2 and 20 characters'),
                            ]}
                            value={formData.firstName}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box pb={3}>
                      <Grid container>
                        <Grid item xs={3} className={classes.centerAlignedFlex}>
                          <Typography>{t('last name')}</Typography>
                        </Grid>
                        <Grid item xs={9} className={classes.centerAlignedFlex}>
                          <TextValidator
                            InputLabelProps={{
                              classes: {
                                root: classes.whiteBackgroundLabel,
                              },
                            }}
                            className={classes.textField}
                            variant='outlined'
                            onChange={handleChange}
                            name='lastName'
                            validators={[
                              'required',
                              'minLength2',
                              'maxLength20',
                            ]}
                            errorMessages={[
                              t('this field is required'),
                              t('names must be between 2 and 20 characters'),
                              t('names must be between 2 and 20 characters'),
                            ]}
                            value={formData.lastName}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box pb={3}>
                      <Grid container>
                        <Grid item xs={3} className={classes.centerAlignedFlex}>
                          <Typography>{t('mobile phone')}</Typography>
                        </Grid>
                        <Grid item xs={9} className={classes.centerAlignedFlex}>
                          <MuiPhoneNumber
                            localization={
                              language === 'ar' ? arabicCountries : {}
                            }
                            value={formData.phone}
                            defaultCountry={'ly'}
                            onChange={(value) =>
                              setFormData({ ...formData, phoneNumber: value })
                            }
                            InputLabelProps={{
                              classes: {
                                root: classes.whiteBackgroundLabel,
                              },
                            }}
                            variant='outlined'
                            className={classes.textField}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box pb={3}>
                      <Grid container>
                        <Grid item xs={3} className={classes.centerAlignedFlex}>
                          <Typography>{t('email')}</Typography>
                        </Grid>
                        <Grid item xs={9} className={classes.centerAlignedFlex}>
                          <TextValidator
                            InputLabelProps={{
                              classes: {
                                root: classes.whiteBackgroundLabel,
                              },
                            }}
                            className={classes.textField}
                            disabled
                            variant='outlined'
                            name='email'
                            value={formData.email}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant='contained'
                      type='submit'
                      disabled={isAccountInfoLoading}
                      className={classes.mainButton}
                    >
                      {t('save')}
                      {isAccountInfoLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.absolutePosition}
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </DialogContent>
          </Paper>
          <Paper className={classes.paper}>
            <DialogTitle className={classes.dialogTitle}>
              {t('change password')}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>{t(requestPasswordResetText)}</Typography>
                  <Button
                    variant='contained'
                    disabled={isRequestPasswordResetLoading}
                    className={classes.mainButton}
                    onClick={handleRequestPasswordReset}
                  >
                    {t('request password change')}
                    {isRequestPasswordResetLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.absolutePosition}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Paper>
        </>
      )}
    </Translation>
  );
};

export default Settings;
