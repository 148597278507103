/**
 * @module AddAddressInitial
 */
//React imports
import React from "react";
import { Link } from "react-router-dom";
import { Typography, Paper, Box } from "@material-ui/core";
//Other libraries
import { Translation } from "react-i18next";
import Image from "material-ui-image";
import AddressAndLocationPicker from "../../../common/AddressAndLocationPicker";
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
const netjeek_en_logo = require("../../../assets/images/Logo-en.png");
const netjeek_ar_logo = require("../../../assets/images/Logo-ar.png");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    margin: "40px 0px",
    alignItems: "center",
    justifyItems: "center",
  },
  paper: {
    width: "95%",
    minWidth: "300px",
    maxWidth: "1200px",
    minHeight: "200px",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    display: "flex",
    padding: 16,
  },
  centeredFlex: { justifyContent: "center", display: "flex" },
}));

/**
 * @method AddAddressInitial
 * @description component to add user address for the first time once they signed up
 * @requires AddressAndLocationPicker
 * @see module:Signup
 */
const AddAddressInitial = (props) => {
  const classes = useStyles();

  return (
    <Translation>
      {(t) => (
        <main className={classes.root}>
          <Helmet>
            <title>{t("add your address")}</title>
          </Helmet>
          <Link to="/">
            <Image
              disableSpinner={true}
              style={{ padding: "0", backgroundColor: "unset", height: "100%" }}
              imageStyle={{
                position: "relative",
                width: "30%",
                height: "unset",
              }}
              src={
                localStorage.getItem("language") === "en"
                  ? netjeek_en_logo
                  : netjeek_ar_logo
              }
            />
          </Link>
          <Box mt={2}>
            <Typography>
              {t("welcome to netjeek, please add your shipping address")}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography>
              {t(
                "you can skip this step for now, you'll be reminded to add it before checking out"
              )}
            </Typography>
          </Box>
          <Paper className={classes.paper}>
            <AddressAndLocationPicker loadInfo={false} />
          </Paper>
          {/* skip for later */}

          <Box mt={1} className={classes.centeredFlex}>
            <Typography variant="subtitle2">
              <Link to="/">{t("skip for later")}</Link>
            </Typography>
          </Box>
        </main>
      )}
    </Translation>
  );
};

export default AddAddressInitial;
