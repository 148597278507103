/**
 * @module ReturnPolicy
 */
import React, { useRef, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import { useGlobalState } from "../../../GlobalState";

const useStyles = makeStyles(() => ({
  root: { direction: "rtl", height: "100%" },
  page: {
    width: "100%",
    height: "100%",
    // minHeight: "400px",
    // maxHeight: "1000px"
  },
  iframeContainer: { height: "100%" },
}));

/**
 * @method ReturnPolicy
 * @description Return Policy component,
 * uses iframe to display imported Return policy html file
 */
function ReturnPolicy() {
  const classes = useStyles();
  const [language] = useGlobalState("language");
  const iframeEl = useRef(null);
  const [iframeHeight, setIframeHeight] = useState(0);

  const calcHeight = () =>
    setIframeHeight(iframeEl.current.contentWindow.document.body.scrollHeight);

  return (
    <>
      <Helmet>
        <title>Return Policy</title>
      </Helmet>
      <Box p={2} className={classes.root}>
        <Grid container className={classes.iframeContainer}>
          <iframe
            src={
              language === "en"
                ? "/return-policy-en/index.html"
                : "/return-policy-ar/index.html"
            }
            title="Return Policy"
            className={classes.page}
            align="center"
            frameborder="0"
            style={{
              border: 0,
              minHeight: "100%",
              overflow: "hidden",
              height: iframeHeight
            }}
            ref={iframeEl}
            onLoad={calcHeight}
          />
        </Grid>
      </Box>
    </>
  );
}

export default ReturnPolicy;
