/**
 * @module IconWithCircle
 */
import React from "react";
import { IconButton, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

/**
 * @method IconWithCircle
 * @description helper component that renders an icon button with a circle around it
 * @param {number} r circle radius in pixels
 * @param {function} onClickFunction the function attached to the button
 * @param {string} fontAwesomeClassName icon class name in font awesome
 */
const IconWithCircle = props => {
  const { r, onClickFunction, fontAwesomeClassName } = props;

  const classes = makeStyles(theme => ({
    svg: { position: "absolute" },
    icon: {
      fontSize: r / 1.7,
      zIndex: 2,
      color: theme.palette.secondary.veryDark
    }
  }))();

  return (
    <IconButton color="secondary" onClick={onClickFunction}>
      <Icon className={classNames(fontAwesomeClassName, classes.icon)} />
    </IconButton>
  );
};

export default IconWithCircle;
