/**
 * @module AddressForm
 */
//React imports
import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Paper,
  CircularProgress,
  Box,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//Other libraries
import { Translation, useTranslation } from 'react-i18next';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import red from '@material-ui/core/colors/red';
import { useGlobalState } from '../../../GlobalState';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  MapLocationPicker,
  regions,
  tReverse,
} from '../../../common/AddressAndLocationPicker/MapWithValidator';

export const useStyles = makeStyles((theme) => ({
  whiteBackgroundLabel: { backgroundColor: 'white' },
  form: {
    // padding: "10px",
    // margin: "10px",
    width: '100%',
  },

  mainButton: {
    maxWidth: 50,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  mapContainerElement: { height: '100%', minHeight: '300px' },
  flexEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  absolutePosition: { position: 'absolute' },
  getCurrentLocationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
  },
  getCurrentLocationButton: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  applyButton: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
}));

/**
 * @method AddressForm
 * @description address form fetches current user address for the user to verify before checking out
 * @param {Object} props component props
 * @param {function} props.addressSetter function to propagate the address to parent component Checkout
 * @see module:module:Checkout
 */
const AddressForm = (props) => {
  const map = useRef(null);
  const marker = useRef(null);
  const [formData, setFormData] = useState({
    streetAddress: '',
    region: '',
    city: '',
    zip: '',
    nearestLandmark: '',
    position: {
      lat: 32.8560912,
      lng: 13.0787207,
    },
  });
  const [loading, setLoading] = useState(false);

  const [userData] = useGlobalState('userData');

  const { t } = useTranslation();

  const classes = useStyles();
  const { addressSetter } = props;
  useEffect(() => moveMapToMarker(), [formData]);

  /**
   * @method getCurrentLocation
   * @description gets user's current location from the browser geolocation
   */
  const getCurrentLocation = () => {
    //get user position
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData((formData) => ({
            ...formData,
            position: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          }));

          moveMapToMarker();
        },
        (error) => {},
        { enableHighAccuracy: true }
      );
    }
  };
  /**
   * @callback anonymous
   * @description gets existing user address from user data
   */
  useDeepCompareEffect(() => {
    //get user info
    const { shipping_address: address } = userData;
    if (address) {
      setFormData({
        streetAddress: address.street_address,
        region: address.state,
        city: t(address.city),
        zip: address.zip,
        nearestLandmark: address.nearest_landmark,
        position: address.geo_location,
      });
    }
  }, [userData]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      city: event.target.name === 'region' ? '' : formData.city,
      [event.target.name]: event.target.value,
    });
  };

  const handleLocationChange = (newPosition) => {
    setFormData({
      ...formData,
      position: {
        lng: newPosition.latLng.lng(),
        lat: newPosition.latLng.lat(),
      },
    });

    // formData.streetAddress = address;
    moveMapToMarker();
  };

  /**
   * @method moveMapToMarker
   * @description moves map position to current position
   */
  const moveMapToMarker = () => {
    try {
      map.current.panTo(marker.current.getPosition());
    } catch (error) {}
  };

  const handleSubmit = () => {
    let formData2 = { ...formData };
    formData2.city = tReverse(formData.city);
    addressSetter(formData2);
    props.handleNext();
  };
  /**
   * @callback anonymous
   * @description adds validation rules once component mounts
   */
  useEffect(() => {
    //add validations
    ValidatorForm.addValidationRule('maxLength12', (value) => {
      if (!value) return true;
      return value.length <= 12 ? true : false;
    });
    ValidatorForm.addValidationRule('maxLength255', (value) => {
      if (!value) return true;
      return value.length <= 255 ? true : false;
    });
    ValidatorForm.addValidationRule('maxLength500', (value) => {
      if (!value) return true;
      return value.length <= 500 ? true : false;
    });
    ValidatorForm.addValidationRule('isValidLocation', validateLocation);
  }, []);
  /**
   * @method validateLocation
   * @param {Object} position position to validate
   * @returns {boolean} whether location is valid or not
   * @description verifies that location is in Libya
   */
  const validateLocation = (position) => {
    const valid =
      position.lng > 9 &&
      position.lng < 26 &&
      position.lat > 19 &&
      position.lat < 34;

    return valid;
  };

  return (
    <Translation>
      {(t) => (
        <ValidatorForm
          onSubmit={handleSubmit}
          className={classes.form}
          instantValidate={false}
        >
          <Grid container spacing={2}>
            {/* form */}
            <Grid item xs={12} sm={12} md={5}>
              <Grid container>
                <Grid item xs={12}>
                  <Box pb={3}>
                    <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      variant='outlined'
                      label={t('street address')}
                      onChange={handleChange}
                      name='streetAddress'
                      type='text'
                      validators={['required', 'maxLength255']}
                      errorMessages={[
                        t('this field is required'),
                        t("address mustn't exceed 255 characters"),
                      ]}
                      value={formData.streetAddress}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pb={3}>
                    <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      select
                      variant='outlined'
                      label={t('region')}
                      onChange={handleChange}
                      name='region'
                      validators={['required']}
                      errorMessages={[t('this field is required')]}
                      value={formData.region}
                      fullWidth
                    >
                      {Object.keys(regions).map((region) => (
                        <MenuItem key={region} value={region}>
                          {t(region)}
                        </MenuItem>
                      ))}
                    </TextValidator>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box pb={3} pr={1}>
                    <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      select
                      variant='outlined'
                      label={t('city')}
                      onChange={handleChange}
                      name='city'
                      // type="text"
                      validators={['required']}
                      errorMessages={[t('this field is required')]}
                      value={formData.city}
                      fullWidth
                      disabled={formData.region === ''}
                    >
                      {formData.region !== '' ? (
                        regions[formData.region]
                          .map((city) => t(city))
                          .sort()
                          .map((city) => (
                            <MenuItem key={city} value={city}>
                              {city}
                            </MenuItem>
                          ))
                      ) : (
                        <MenuItem />
                      )}
                    </TextValidator>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box pb={3} pl={1}>
                    <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      variant='outlined'
                      label={t('zip')}
                      onChange={handleChange}
                      name='zip'
                      type='text'
                      validators={['maxLength12']}
                      errorMessages={[
                        t("zip code mustn't exceed 12 characters"),
                      ]}
                      value={formData.zip}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      multiline
                      rows='4'
                      variant='outlined'
                      label={t('nearest landmark')}
                      onChange={handleChange}
                      name='nearestLandmark'
                      type='text'
                      validators={['required', 'maxLength500']}
                      errorMessages={[
                        t('this field is required'),
                        t("nearest landmark mustn't exceed 500 characters"),
                      ]}
                      value={formData.nearestLandmark}
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* map */}
            <Grid item xs={12} sm={12} md={7}>
              {/* map */}

              <MapLocationPicker
                mapRef={map}
                markerRef={marker}
                defaultPosition={formData.position}
                onDragEnd={handleLocationChange}
                isMarkerShown
                googleMapURL={process.env.REACT_APP_GOOGLE_MAPS_API}
                loadingElement={<div className={classes.mapContainerElement} />}
                containerElement={
                  <div className={classes.mapContainerElement} />
                }
                mapElement={
                  <Paper
                    style={{
                      height: '100%',
                      width: '100%',
                      border: validateLocation(formData.position)
                        ? 'none'
                        : `1px solid ${red['500']}`,
                    }}
                  />
                }
                circleOptions={{
                  fillOpacity: 0,
                  radius: 3,
                  strokeColor: '#f00',
                  strokeOpacity: 0,
                }}
                validators={['isValidLocation']}
                errorMessages={[t('please choose a valid location')]}
                value={formData.position}
              />
            </Grid>

            {/* get current location button */}
            <Grid item xs={12} className={classes.getCurrentLocationContainer}>
              <Button
                variant='contained'
                className={classes.getCurrentLocationButton}
                onClick={getCurrentLocation}
              >
                {t('get current location')}
              </Button>
            </Grid>

            {/* save button */}

            <Grid item xs={12} className={classes.flexEnd}>
              <Button
                variant='contained'
                type='submit'
                disabled={loading}
                color='primary'
                className={classes.mainButton}
              >
                {(loading && t('please wait')) || (!loading && t('next'))}
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.absolutePosition}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      )}
    </Translation>
  );
};

export default AddressForm;
