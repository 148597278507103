/**
 * @module ImageCarousel
 */
import React, { useState } from 'react';
import Img from 'react-image';
import { Carousel } from 'antd';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, CircularProgress, Box, Backdrop } from '@material-ui/core';
// import "antd/dist/antd.css";
import '../carousel.css';
import './carouselStyles.css';
import Image from 'material-ui-image';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    height: '3px',
    transform: 'translate(0px, -20px)',
    width: '20px',
    backgroundColor: theme.palette.primary.main,
    justifySelf: 'center',
  },
  skeletonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  hiddenImages: { display: 'none' },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
/**
 * @method ImageCarousel
 * @description image carousel component
 * @param {Object} props component props
 * @param {string[]} props.images images array
 */
const ImageCarousel = (props) => {
  const classes = useStyles();
  const { images } = props;
  const [isBackdropOpen, setIsBackdropOpen] = useState(true);
  return images ? (
    <>
      <Carousel autoplay autoplaySpeed={4000} lazyLoad pauseOnHover>
        {images.map((img, idx) => (
          <Image
            // disableSpinner
            key={idx}
            src={img}
            animationDuration={1000}
            aspectRatio={1000 / 350}
          />
        ))}
      </Carousel>
      {/* render hidden images */}
      <div className={classes.hiddenImages}>
        {images && images.map((img, idx) => <Img key={idx} src={img} />)}
      </div>
    </>
  ) : (
    <Box>
      <Skeleton
        variant='rect'
        height={350}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <CircularProgress color='primary' style={{ position: 'absolute' }} /> */}
      </Skeleton>
      <Backdrop
        className={classes.backdrop}
        open={isBackdropOpen}
        onClick={() => setIsBackdropOpen(false)}
      >
        <CircularProgress color='primary' />
      </Backdrop>
      <Box className={classes.skeletonContainer}>
        <Skeleton variant='rect' height={10} className={classes.skeleton} />
      </Box>
    </Box>
  );
};

export default ImageCarousel;
