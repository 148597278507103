/**
 * @module AddressAndLocationPicker
 */
//React imports
import React, { useRef, useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  CircularProgress,
  Box,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//Other libraries
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import red from "@material-ui/core/colors/red";
import { useGlobalState } from "../../GlobalState";
import useDeepCompareEffect from "use-deep-compare-effect";
import { handleGlobalError } from "./../../App";
import { MapLocationPicker, regions, tReverse } from "./MapWithValidator";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const errorCodesToMessages = {
  666: "network error, please try again",
  1000: "street address is missing",
  1001: "state is missing",
  1002: "city is missing",
  1003: "geo location is missing",
  2000: "street address is more than 255 characters",
  2001: "zip is more than 12 characters",
  2002: "nearest landmark is more than 500 characters",
  3000: "state is invalid",
  3001: "city is invalid",
  3002: "location on map is invalid",
  5000: "unauthorized",
};
const useStyles = makeStyles((theme) => ({
  form: {
    // padding: "8px",
    width: "100%",
  },
  centeredFlex: {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    alignContent: "center",
  },

  mainButton: {
    width: "100%",
    minWidth: 200,
    maxWidth: 320,

    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  redText: {
    color: "red",
  },
  whiteBackgroundLabel: { backgroundColor: "white" },
  mapContainerElement: { height: "100%", minHeight: "300px" },
  progress: { position: "absolute" },
  getCurrentLocationContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "4px !important",
    paddingBottom: "4px !important",
  },
  getCurrentLocationButton: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

/**
 * @method AddressAndLocationPicker
 * @description address picker and map component
 * @requires  module:MapWithValidator
 * @param {Object} props component props
 * @param {boolean} props.loadInfo whether the component should load user info
 * from the userData object from the global state and populate
 * the fields with it or not
 */
const AddressAndLocationPicker = (props) => {
  const [session] = useGlobalState("session");
  const map = useRef(null);
  const marker = useRef(null);
  const [formData, setFormData] = useState({
    streetAddress: "",
    region: "",
    city: "",
    zip: "",
    nearestLandmark: "",
    position: {
      lat: 32.8560912,
      lng: 13.0787207,
    },
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [userData, setUserData] = useGlobalState("userData");
  const { t } = useTranslation();
  const classes = useStyles();
  // const history = useHistory();

  useEffect(() => moveMapToMarker(), [formData]);
  /**
   * @method getCurrentLocation
   * @description gets user's current location from the browser geolocation
   */

  const getCurrentLocation = () => {
    //get user position
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData((formData) => ({
            ...formData,
            position: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          }));

          moveMapToMarker();
        },
        () => {},
        { enableHighAccuracy: true }
      );
    }
  };

  /**
   * @callback anoynmous
   * @description gets user's current address that's in user data
   */
  useDeepCompareEffect(() => {
    if (props.loadInfo) {
      //get user info
      const { shipping_address: address } = userData;
      if (address) {
        setFormData({
          streetAddress: address.street_address,
          region: address.state,
          city: t(address.city),
          zip: address.zip || "",
          nearestLandmark: address.nearest_landmark,
          position: address.geo_location,
        });
      }
    }
  }, [props.loadInfo, userData]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      city: event.target.name === "region" ? "" : formData.city,
      [event.target.name]: event.target.value,
    });
  };

  const handleLocationChange = (newPosition) => {
    setFormData({
      ...formData,
      position: {
        lng: newPosition.latLng.lng(),
        lat: newPosition.latLng.lat(),
      },
    });

    // formData.streetAddress = address;
    moveMapToMarker();
  };
  /**
   * @method moveMapToMarker
   * @description moves map position to current position
   */
  const moveMapToMarker = () => {
    try {
      map.current.panTo(marker.current.getPosition());
    } catch (error) {}
  };

  const handleSubmit = useCallback(() => {
    let {
      streetAddress: street_address,
      region: state,
      city,
      zip,
      nearestLandmark: nearest_landmark,
      position: geo_location,
    } = formData;

    city = tReverse(city);

    setLoading(true);
    setErrors([]);

    const shipping_address = {
      street_address,
      state: state,
      city: city,
      zip,
      nearest_landmark,
      geo_location,
      country: "Libya",
    };

    axios
      .patch(
        apiEndpoint + "customers/me",
        {
          shipping_address,
        },
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      )
      .then(() => {
        setLoading(false);
        setUserData((userData) => ({
          ...userData,
          shipping_address,
        }));
        !props.loadInfo && props.history.push("/");
        // props.updateUserData(res.data);
      })
      .catch((error) => {
        handleGlobalError(error);
        setLoading(false);

        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          setErrors(error.response.data.errors);
        } else {
          setErrors([{ code: 666, message: "timeout" }]);
        }
      });
  }, [formData, props.history, props.loadInfo, session.token, setUserData]);

  useEffect(() => {
    //add validations
    ValidatorForm.addValidationRule("maxLength12", (value) => {
      if (!value) return true;
      return value.length <= 12 ? true : false;
    });
    ValidatorForm.addValidationRule("maxLength255", (value) => {
      if (!value) return true;
      return value.length <= 255 ? true : false;
    });
    ValidatorForm.addValidationRule("maxLength500", (value) => {
      if (!value) return true;
      return value.length <= 500 ? true : false;
    });
    ValidatorForm.addValidationRule("isValidLocation", validateLocation);
  }, []);
  /**
   * @method validateLocation
   * @param {Object} position position to validate
   * @returns {boolean} whether location is valid or not
   * @description verifies that location is in Libya
   */
  const validateLocation = (position) => {
    const valid =
      position.lng > 9 &&
      position.lng < 26 &&
      position.lat > 19 &&
      position.lat < 34;

    return valid;
  };

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      className={classes.form}
      instantValidate={false}
    >
      <Grid container spacing={2}>
        {/* errors */}
        {errors.length > 0 && (
          <Grid item xs={12}>
            <ul>
              {errors.map((error, index) => (
                <li key={index} className={classes.redText}>
                  {t(errorCodesToMessages[error.code])}
                </li>
              ))}
            </ul>
          </Grid>
        )}
        {/* form */}
        <Grid item xs={12} sm={12} md={5}>
          <Grid container>
            <Grid item xs={12}>
              <Box pb={3}>
                <TextValidator
                  InputLabelProps={{
                    classes: {
                      root: classes.whiteBackgroundLabel,
                    },
                  }}
                  variant="outlined"
                  label={t("street address")}
                  onChange={handleChange}
                  name="streetAddress"
                  type="text"
                  validators={["required", "maxLength255"]}
                  errorMessages={[
                    t("this field is required"),
                    t("address mustn't exceed 255 characters"),
                  ]}
                  value={formData.streetAddress}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box pb={3}>
                <TextValidator
                  InputLabelProps={{
                    classes: {
                      root: classes.whiteBackgroundLabel,
                    },
                  }}
                  select
                  variant="outlined"
                  label={t("region")}
                  onChange={handleChange}
                  name="region"
                  validators={["required"]}
                  errorMessages={[t("this field is required")]}
                  value={formData.region}
                  fullWidth
                >
                  {Object.keys(regions).map((region) => (
                    <MenuItem key={region} value={region}>
                      {t(region)}
                    </MenuItem>
                  ))}
                </TextValidator>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box pb={3} pr={1}>
                <TextValidator
                  InputLabelProps={{
                    classes: {
                      root: classes.whiteBackgroundLabel,
                    },
                  }}
                  select
                  variant="outlined"
                  label={t("city")}
                  onChange={handleChange}
                  name="city"
                  // type="text"
                  validators={["required"]}
                  errorMessages={[t("this field is required")]}
                  value={formData.city}
                  fullWidth
                  disabled={formData.region === ""}
                >
                  {formData.region !== "" ? (
                    regions[formData.region]
                      .map((city) => t(city))
                      .sort()
                      .map((city) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))
                  ) : (
                    <MenuItem />
                  )}
                </TextValidator>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box pb={3} pl={1}>
                <TextValidator
                  InputLabelProps={{
                    classes: {
                      root: classes.whiteBackgroundLabel,
                    },
                  }}
                  variant="outlined"
                  label={t("zip")}
                  onChange={handleChange}
                  name="zip"
                  type="text"
                  validators={["required", "maxLength12"]}
                  errorMessages={[
                    t("this field is required"),
                    t("zip code mustn't exceed 12 characters"),
                  ]}
                  value={formData.zip}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <TextValidator
                  InputLabelProps={{
                    classes: {
                      root: classes.whiteBackgroundLabel,
                    },
                  }}
                  multiline
                  rows="4"
                  variant="outlined"
                  label={t("nearest landmark")}
                  onChange={handleChange}
                  name="nearestLandmark"
                  type="text"
                  validators={["required", "maxLength500"]}
                  errorMessages={[
                    t("this field is required"),
                    t("nearest landmark mustn't exceed 500 characters"),
                  ]}
                  value={formData.nearestLandmark}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* map */}
        <Grid item xs={12} sm={12} md={7}>
          {/* map */}

          <MapLocationPicker
            mapRef={map}
            markerRef={marker}
            defaultPosition={formData.position}
            onDragEnd={handleLocationChange}
            isMarkerShown
            googleMapURL={process.env.REACT_APP_GOOGLE_MAPS_API}
            loadingElement={<div className={classes.mapContainerElement} />}
            containerElement={<div className={classes.mapContainerElement} />}
            mapElement={
              <Paper
                style={{
                  height: "100%",
                  width: "100%",
                  border: validateLocation(formData.position)
                    ? "none"
                    : `1px solid ${red["500"]}`,
                }}
              />
            }
            circleOptions={{
              fillOpacity: 0,
              radius: 3,
              strokeColor: "#f00",
              strokeOpacity: 0,
            }}
            validators={["isValidLocation"]}
            errorMessages={[t("please choose a valid location")]}
            value={formData.position}
          />
        </Grid>
        <Grid item xs={12} className={classes.getCurrentLocationContainer}>
          <Button
            variant="contained"
            className={classes.getCurrentLocationButton}
            onClick={getCurrentLocation}
          >
            {t("get current location")}
          </Button>
        </Grid>
        {/* save button */}

        <Grid item xs={12} className={classes.centeredFlex}>
          <Box pt={1.25}>
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              className={classes.mainButton}
            >
              {(loading && t("please wait")) || (!loading && t("save"))}
              {loading && (
                <CircularProgress size={24} className={classes.progress} />
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default withRouter(AddressAndLocationPicker);
