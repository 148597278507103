/**
 * @module Orders
 */
import React, { useEffect, useState, useCallback } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useGlobalState } from '../../../../GlobalState';
import axios from 'axios';
import Image from 'material-ui-image';
import { Link } from 'react-router-dom';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  IconButton,
  useTheme,
  Box,
  Grid,
  Tooltip,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Card,
  Button,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  LastPage as LastPageIcon,
  Info as InfoIcon,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import useWidth from '../../../../common/useWidth';
import moment from 'moment';
import '../../../../common/addMomentJsLibyanLocale';
import { handleGlobalError } from './../../../../App';

const successBlueColor = '#0089FF';

/**
 * enum for shipment statuses
 * @enum {string}
 */
const statusToText = {
  RECEIVED_IN_MAIN_HUB: 'received in main hub',
  SHIPPED_TO_LIBYA: 'shipped to libya',
  RECEIVED_IN_LIBYA: 'received in libya',
  RECEIVED_IN_LOCAL_HUB: 'received in local hub',
  OUT_FOR_DELIVERY: 'out for delivery',
  RETURNED_TO_LOCAL_HUB: 'returned to local hub',
  DELIVERED: 'delivered',
  PLACED: 'order placed',
};
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const useStyles = makeStyles((theme) => ({
  orderPriceContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: { justifyContent: 'flex-start' },
  },
  orderStatusContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: { justifyContent: 'flex-start' },
  },
  centeredFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  expansionPanelSummary: {
    backgroundColor: theme.palette.primary.main,
  },
  whiteText: { color: 'white' },
  flex: {
    display: 'flex',
  },
  stepper: {
    padding: '24px 0',
  },
  completedStepBlue: {
    color: `${successBlueColor} !important`,
  },
  verticalStepper: { padding: '8px 0' },
  fixedHeightBox: { heigth: 24 },
  columnFlex: { display: 'flex', flexDirection: 'column' },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  orderPricesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  orderPrice: {
    fontWeight: 600,
  },
}));

const useStyles2 = makeStyles((theme) => ({
  redText: { color: 'red' },
  itemTitle: { fontSize: '0.9rem', fontWeight: '600' },
  itemPrice: {
    color: theme.palette.secondary.main,
    marginRight: '3px',
    fontSize: '0.85rem',
    fontWeight: '600',
  },
  itemRetailer: { display: 'inline-flex' },
  orderPricesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  // descriptionContainer: { padding: "10px 15px 10px 7px" }, //toto's version
  descriptionContainer: { padding: 4 }, //my version
  orderPrice: {
    fontWeight: 600,
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  horizontalDivider: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginTop: '4px',
    marginBottom: '4px',
  },
}));
/**
 * @method OrderDetails
 * @description shows specific order details
 * @param {Object} props component props
 * @param {string} orderId order ID to request order details with
 * @param {boolean} isToFetchData changes to true when component is to fetch data now
 */
const OrderDetails = (props) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [session] = useGlobalState('session');
  const classes = useStyles2();
  const { t } = useTranslation();
  const { shippingFees, total, subTotal } = props;

  /**
   * @callback anonymous
   *
   * @description calls to the api to get order details
   */
  useEffect(() => {
    setError(false);
    items.length === 0 && setLoading(true);
    let cancelTokenSource = new axios.CancelToken.source();
    const { orderId, isToFetchData } = props;
    isToFetchData &&
      items.length === 0 &&
      axios
        .get(apiEndpoint + 'customers/me/orders/items', {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
          headers: { Authorization: `Bearer ${session.token}` },
          cancelToken: cancelTokenSource.token,
          params: { order_id: orderId },
        })
        .then((res) => {
          // console.log('order get res', res.data);
          setItems(res.data.data);
          setError(false);
        })
        .catch((error) => {
          handleGlobalError(error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });

    return () => {
      // To cancel previous request use:
      cancelTokenSource.cancel('Operation canceled');
      cancelTokenSource = new axios.CancelToken.source();
    };
  }, [session.token, props, items.length]);

  return !error ? (
    !loading ? (
      <Grid container spacing={1}>
        {items.map((item, idx) => (
          <Grid key={idx} item xs={12}>
            <Card>
              <Grid container spacing={1}>
                {/* image */}
                <Grid
                  item
                  xs={5}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                  className={classes.centeredFlex}
                >
                  <Link
                    to={`/product?retailer=${item.retailer}&id=${item.retailer_item_id}`}
                  >
                    <Image
                      disableSpinner={true}
                      style={{
                        padding: '0',
                        backgroundColor: 'unset',
                        height: '100%',
                      }}
                      imageStyle={{
                        position: 'relative',
                        width: 'unset',
                        height: 'unset',
                        maxHeight: '100%',
                        maxWidth: '100%',
                      }}
                      src={item.image_url}
                    />
                  </Link>
                </Grid>
                {/* description */}
                <Grid item xs={7} sm={8} md={9} lg={9} xl={10}>
                  <Box className={classes.descriptionContainer}>
                    {/* name */}
                    <Grid item xs={12}>
                      <Typography className={classes.itemTitle}>
                        {item.title}
                      </Typography>
                    </Grid>
                    {item.variant && (
                      <Grid item xs={12}>
                        <Typography variant='caption'>
                          {item.variant.join(' - ')}
                        </Typography>
                      </Grid>
                    )}
                    {/* price*/}
                    <Grid item xs={12}>
                      <Typography
                        variant='caption'
                        className={classes.itemPrice}
                      >
                        {`${numeral(item.price).format(
                          process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                        )}`}
                      </Typography>
                      <Typography
                        variant='caption'
                        className={classes.itemRetailer}
                      >
                        {(() => {
                          switch (item.retailer.toLocaleLowerCase()) {
                            case 'amazon_ca':
                              return `${t('from')} ${t('amazon')}`;
                            case 'walmart_ca':
                              return `${t('from')} ${t('walmart')}`;
                            case 'aliexpress':
                              return `${t('from')} ${t('aliexpress')}`;
                            default:
                              return `${t('from')} ${t('unknown vendor')}`;
                          }
                        })()}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='caption'>{`${t('quantity')}: ${
                        item.quantity
                      }`}</Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} className={classes.flexEnd} container>
          <Grid item xs={12} sm={6} container>
            <Grid item xs={12} className={classes.orderPricesContainer}>
              <Typography>{t('order total')}</Typography>
              <Typography className={classes.orderPrice}>
                {numeral(subTotal).format(
                  process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.orderPricesContainer}>
              <Typography>{t('shipping fees')}</Typography>
              <Typography className={classes.orderPrice}>
                {numeral(shippingFees).format(
                  process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                )}
              </Typography>
            </Grid>
            {props.order_discounts && props.order_discounts.length > 0 && (
              props.order_discounts.map((discount,idx)=>{
                return (
                  <Grid
                  key={discount.code}
                  item
                  xs={12}
                  className={classes.orderPricesContainer}
                >
                  <Typography>{discount.type.toLowerCase() ==='promo'? t('discount'):t('voucher')}</Typography>
                  <Typography className={classes.orderPrice}>
                      {numeral(discount.amount).format(
                      process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                    )} 
                  </Typography>
                </Grid>
                )
              })
              )}
            <Grid item xs={12}>
              <div className={classes.horizontalDivider} />
            </Grid>
            <Grid item xs={12} className={classes.orderPricesContainer}>
              <Typography>{t('grand total')}</Typography>
              <Typography className={classes.orderPrice}>
                {numeral(total).format(
                  process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ) : (
      [...Array(4)].map((_item, idx) => (
        <Box key={idx} p={1}>
          <Skeleton variant='rect' width='100%' height='100px' />
        </Box>
      ))
    )
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.redText}>
          {t('an unexpected error occurred. please try again')}
        </Typography>
      </Grid>
    </Grid>
  );
};
/**
 * @method Orders
 * @description orders page component
 * @requires OrderDetails
 */
const Orders = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [orders, setOrders] = useState([]);
  const [pageOptions, setPageOptions] = useState({
    currentPage: 1,
  });
  const width = useWidth();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  /**
   * @method handleExpandedPanelChange
   *
   * @description changes expanded order accordion
   */
  const handleExpandedPanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [session] = useGlobalState('session');
  const [language] = useGlobalState('language');
  /**
   * @method getData
   *
   * @param {number} page page number to get orders
   * @description gets brief about orders
   */
  const getData = useCallback((page = 1) => {
    setError(false);
    setLoading(true);
    setExpanded(false);
    axios
      .get(apiEndpoint + 'customers/me/orders', {
        timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
        headers: { Authorization: `Bearer ${session.token}` },
        params: { page: page },
      })
      .then((res) => {
        setOrders(res.data.data);
        const {
          current_page: currentPage,
          per_page: ordersPerPage,
          total_records: totalRecords,
        } = res.data.meta_data;
        setPageOptions({ currentPage, ordersPerPage, totalRecords });
      })
      .catch((error) => {
        handleGlobalError(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);
  /**
   * @method TablePaginationActions
   * @member {class} TablePaginationActions
   *
   * @description renders pagination
   * @param {number} count total number of orders
   * @param {number} page current page
   * @param {number} rowsPerPage number of orders shown per page
   */
  const TablePaginationActions = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage } = props;
    /**
     * @method handleFirstPageButtonClick
     * @description goes to first page
     */
    const handleFirstPageButtonClick = () => {
      getData(1);
    };
    /**
     * @method handleBackButtonClick
     * @description goes to previous page
     */
    const handleBackButtonClick = () => {
      getData(page - 1);
    };
    /**
     * @method handleNextButtonClick
     * @description goes to next page
     */
    const handleNextButtonClick = () => {
      getData(page + 1);
    };
    /**
     * @method handleLastPageButtonClick
     * @description goes to last page
     */
    const handleLastPageButtonClick = () => {
      getData(Math.max(0, Math.ceil(count / rowsPerPage)));
    };

    return (
      <Translation>
        {(t) => (
          <Box py={2} className={classes.flexEnd}>
            {page >= 0 && (
              <Typography>
                {`${t('page')} ${page} ${t('of')} ${Math.ceil(
                  count / rowsPerPage
                )}`}
              </Typography>
            )}
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 1 || loading}
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 1 || loading}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRightIcon />
              ) : (
                <KeyboardArrowLeftIcon />
              )}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) || loading}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeftIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) || loading}
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
          </Box>
        )}
      </Translation>
    );
  };
  const classes = useStyles();

  const { ordersPerPage, totalRecords, currentPage } = pageOptions;

  useEffect(() => {
    // console.log('-----------------');
    // console.log('logging orders stuff');
    // console.log('orders', orders);
    // console.log('statusToText', statusToText);
    // console.log('moment', moment);
    // console.log('-----------------');
  }, [orders]);

  const Orders = () =>
    orders.map((order, idx) => {
      const {
        // customer_notes,
        // items_fully_received: itemsFullyReceived,
        // updatedAt,
        // customer_id: customerId,
        // shipping_address: shippingAddress,
        // status,
        additional_fees_notes: adminNotes,
        eta,
        id,
        order_id: orderId,
        createdAt: orderDate,
        sub_total: subTotal,
        shipping_fees: shippingFees,
        additional_fees: additionalCharges,
        shipping_address: shippingAdress,
        order_discounts,
        total,
      } = order;

      const {
        street_address,
        state,
        city,
        nearest_landmark,
        zip,
      } = shippingAdress;

      let { order_trace: orderTrace } = order;
      //add placed status to order trace
      orderTrace = {
        0: { status: 'PLACED', timestamp: orderDate },
        ...orderTrace,
      };
      //get completed steps based on last status with a timestamp
      let numberOfCompletedSteps = 0;
      for (let i in Object.values(orderTrace)) {
        if (Object.values(orderTrace)[i].timestamp) {
          numberOfCompletedSteps = i;
        }
      }
      numberOfCompletedSteps++;
      //slice ordertrace to get list of completed steps
      const completedSteps = Object.values(orderTrace).slice(
        0,
        numberOfCompletedSteps
      );
      //order is delivered if all steps are complete
      const isOrderDelivered = numberOfCompletedSteps === 8;

      return (
        <Box my={1} key={idx}>
          <ExpansionPanel
            className={classes.expansionPanel}
            expanded={expanded === idx}
            onChange={handleExpandedPanelChange(idx)}
          >
            <ExpansionPanelSummary
              className={classes.expansionPanelSummary}
              style={{
                backgroundColor: isOrderDelivered && successBlueColor,
              }}
              IconButtonProps={{
                style: { color: isOrderDelivered && 'white' },
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Grid container spacing={2}>
                {/* order placed on */}
                <Grid item xs={12} sm={4}>
                  <Typography
                    className={isOrderDelivered ? classes.whiteText : null}
                  >
                    {moment(orderDate)
                      .locale(language)
                      .format(process.env.REACT_APP_DEFAULT_DATE_FORMAT)}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.orderStatusContainer}
                >
                  <Typography
                    className={isOrderDelivered ? classes.whiteText : null}
                  >
                    {t(
                      statusToText[
                        completedSteps[completedSteps.length - 1].status
                      ]
                    )}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.orderPriceContainer}
                >
                  <Typography
                    className={isOrderDelivered ? classes.whiteText : null}
                  >
                    {numeral(total).format(
                      process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.columnFlex}>
              {/* Order Tracking */}
              <Stepper
                orientation={width === 'xs' ? 'vertical' : 'horizontal'}
                alternativeLabel={width !== 'xs'}
                className={width === 'xs' ? null : classes.stepper}
                connector={
                  <StepConnector
                    className={width === 'xs' ? classes.verticalStepper : null}
                  />
                }
                activeStep={completedSteps.length}
              >
                {Object.keys(orderTrace).map((statusCode, idx) =>
                  orderTrace[statusCode].timestamp ? (
                    <Tooltip
                      arrow
                      disableFocusListener
                      disableTouchListener
                      key={idx}
                      title={moment(orderTrace[statusCode].timestamp)
                        .locale(language)
                        .format(process.env.REACT_APP_DEFAULT_DATE_FORMAT)}
                    >
                      <Step completed className={classes.fixedHeightBox}>
                        <StepLabel
                          StepIconProps={{
                            classes: {
                              root: classes.completedStepBlue,
                            },
                          }}
                        >
                          {t(statusToText[orderTrace[statusCode].status])}
                        </StepLabel>
                      </Step>
                    </Tooltip>
                  ) : (
                    <Step key={idx}>
                      <StepLabel
                        StepIconProps={
                          idx < numberOfCompletedSteps
                            ? {
                                classes: {
                                  root: classes.completedStepBlue,
                                },
                              }
                            : {}
                        }
                      >
                        {t(statusToText[orderTrace[statusCode].status])}
                      </StepLabel>
                    </Step>
                  )
                )}
              </Stepper>
              {/* Order Info */}
              <Box py={2}>
                <Grid container spacing={2}>
                  {/* Order Placed On */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                    className={classes.orderPricesContainer}
                  >
                    <Typography>{t('order placed on')}</Typography>
                    <Typography className={classes.orderPrice}>
                      {moment(orderDate)
                        .locale(language)
                        .format(process.env.REACT_APP_DEFAULT_DATE_FORMAT)}
                    </Typography>
                  </Grid>
                  {/* order ID */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                    className={classes.orderPricesContainer}
                  >
                    <Typography>{t('order id')}</Typography>
                    <Typography className={classes.orderPrice}>
                      {orderId}
                    </Typography>
                  </Grid>
                  {/* eta */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                    className={classes.orderPricesContainer}
                  >
                    <Typography>
                      {isOrderDelivered ? t('delivered on') : t('eta')}
                    </Typography>
                    <Typography className={classes.orderPrice}>
                      {isOrderDelivered
                        ? moment(orderTrace[700].timestamp)
                            .locale(language)
                            .format(process.env.REACT_APP_DEFAULT_DATE_FORMAT)
                        : moment(eta)
                            .locale(language)
                            .format(process.env.REACT_APP_DEFAULT_DATE_FORMAT)}
                    </Typography>
                  </Grid>
                  {/* additional fees */}
                  {additionalCharges && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      xl={4}
                      className={classes.orderPricesContainer}
                    >
                      <Typography>{t('additional fees')}</Typography>
                      <Box style={{ display: 'flex' }}>
                        {adminNotes && (
                          <Tooltip
                            disableFocusListener
                            disableTouchListener
                            arrow
                            title={adminNotes}
                          >
                            <InfoIcon />
                          </Tooltip>
                        )}
                        <Typography className={classes.orderPrice}>
                          {numeral(additionalCharges).format(
                            process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {/* status */}

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                    className={classes.orderPricesContainer}
                  >
                    <Typography>{t('status')}</Typography>
                    <Typography className={classes.orderPrice}>
                      {t(
                        statusToText[
                          completedSteps[completedSteps.length - 1].status
                        ]
                      )}
                    </Typography>
                  </Grid>

                  {/* total */}

                  {/* <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                    className={classes.orderPricesContainer}
                  >
                    <Typography>{t("total")}</Typography>
                    <Typography className={classes.orderPrice}>
                      {numeral(total).format(
                        process.env.REACT_APP_DEFAULT_CURRENCY_FORMAT
                      )}
                    </Typography>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                    className={classes.orderPricesContainer}
                  >
                    <Typography>{t('street address')}</Typography>
                    <Typography className={classes.orderPrice}>
                      {street_address}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                    className={classes.orderPricesContainer}
                  >
                    <Typography>{t('region')}</Typography>
                    <Typography className={classes.orderPrice}>
                      {t(state)}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                    className={classes.orderPricesContainer}
                  >
                    <Typography>{t('city')}</Typography>
                    <Typography className={classes.orderPrice}>
                      {t(city)}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                    className={classes.orderPricesContainer}
                  >
                    <Typography>{t('zip')}</Typography>
                    <Typography className={classes.orderPrice}>
                      {zip}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={6}
                    className={classes.orderPricesContainer}
                  >
                    <Typography>{t('nearest landmark')}</Typography>
                    <Typography className={classes.orderPrice}>
                      {nearest_landmark}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              {/* Order Items */}
              <OrderDetails
                orderId={id}
                isToFetchData={expanded === idx}
                shippingFees={shippingFees}
                total={total}
                subTotal={subTotal}
                order_discounts={order_discounts}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>
      );
    });

  return (
    <>
      <Helmet>
        <title>{t('order history')}</title>
      </Helmet>
      {!error ? (
        !loading ? (
          totalRecords !== 0 ? (
            <>
              <Orders />
              <TablePaginationActions
                rowsPerPage={ordersPerPage}
                count={totalRecords}
                page={currentPage}
                onChangePage={getData}
              />
            </>
          ) : (
            <Box py={8} px={4} className={classes.centeredFlex}>
              <Typography>{t("you haven't placed any orders")}</Typography>
            </Box>
          )
        ) : (
          [...Array(3)].map((_item, idx) => (
            <Box my={1} key={idx}>
              <Skeleton variant='rect' width='100%' height='50px' />
            </Box>
          ))
        )
      ) : (
        <Box p={2} className={classes.centeredFlex}>
          <Typography>{t('an unexpected error occurred')}</Typography>
          <Button onClick={() => getData(pageOptions.currentPage)}>
            {t('click here to refresh')}
          </Button>
        </Box>
      )}
    </>
  );
};

export default Orders;
