/**
 * @module Signin
 */
//React imports
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Typography,
  Paper,
  CircularProgress,
  Box,
  Grid,
} from '@material-ui/core';
//Other libraries
import { Translation } from 'react-i18next';
import axios from 'axios';
import { useGlobalState } from '../../../GlobalState';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const errorCodesToMessages = {
  666: 'network error, please try again',
  1000: 'email is missing',
  1001: 'password is missing',
  3000: 'e-mail is not valid',
  5000: 'invalid e-mail or password',
  5001: 'account is inactive',
};
const useStyles = makeStyles((theme) => ({
  whiteBackgroundLabel: { backgroundColor: 'white' },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    margin: '40px 0px',
    alignItems: 'center',
    justifyItems: 'center',
    // height: "100%"
  },
  paper: {
    minWidth: 300,
    maxWidth: 600,
    width: '45%',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    display: 'flex',
  },
  form: {
    padding: '10px 20px',
    margin: '10px',
    width: '100%',
  },
  centeredFlex: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  mainButton: {
    width: '100%',
    minWidth: 200,
    maxWidth: 320,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  secondaryButton: {
    width: '100%',
    minWidth: 200,
    maxWidth: 320,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.veryDark,
    '&:hover': {
      backgroundColor: theme.palette.secondary.veryLight,
      color: theme.palette.secondary.veryDark,
    },
  },
  redText: {
    color: 'red',
  },
  absolutePosition: { position: 'absolute' },
  undecoratedLink: { textDecoration: 'none' },
}));

/**
 * @method Login
 * @description login component
 * @see module:Authentication
 * @see module:loginSubmit
 */
const Login = (props) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setLoggedIn] = useGlobalState('loggedIn');
  const [, setSession] = useGlobalState('session');

  const { expired } = queryString.parse(props.location.search);

  const handleChange = (event) => {
    const temp = { ...formData };
    temp[event.target.name] = event.target.value;
    setFormData(temp);
  };
  const emailRef = useRef(null);
  const handleBlur = (event) => {
    emailRef.current.validate(event.target.value);
  };

  /**
   * @method handleSubmit
   *
   * @description if login is successful,
   * saves session to local storage and sets loggedIn state in global state to true
   */
  const handleSubmit = () => {
    const { email, password } = formData;
    setLoading(true);
    setErrors([]);

    axios
      .post(
        apiEndpoint + 'customers/login',
        {
          email,
          password,
        },
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
        }
      )
      .then((res) => {
        // console.log("signin res", res);
        // setLoading(false);
        setSession(res.data.data);
        localStorage.setItem('session', JSON.stringify(res.data.data));
        setLoggedIn(true);
        props.history.goBack();

        // props.history.push("/");
      })
      .catch((error) => {
        setLoading(false);

        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          setErrors(error.response.data.errors);
        } else {
          setErrors([{ code: 666, message: 'timeout' }]);
        }
      });
  };

  const classes = useStyles();
  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t('netjeek - sign in')}</title>
          </Helmet>

          <Box py={2}>
            <Typography>{t('welcome to netjeek, please sign in')}</Typography>
          </Box>
          <Paper className={classes.paper}>
            <ValidatorForm
              onSubmit={handleSubmit}
              className={classes.form}
              instantValidate={false}
            >
              <Grid container>
                {expired === 'true' && (
                  <Grid item xs={12} className={classes.centeredFlex}>
                    <Typography>
                      {t('your session has expired, please sign in again')}
                    </Typography>
                  </Grid>
                )}

                {errors.length > 0 && (
                  <Grid item xs={12}>
                    <ul>
                      {errors.map((error, index) => (
                        <li className={classes.redText} key={index}>
                          {t(errorCodesToMessages[error.code])}
                        </li>
                      ))}
                    </ul>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box pb={3}>
                    <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      variant='outlined'
                      ref={emailRef}
                      label={t('email')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name='email'
                      value={formData.email}
                      validators={['required', 'isEmail']}
                      errorMessages={[
                        t('this field is required'),
                        t('email is not valid'),
                      ]}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pb={3}>
                    <TextValidator
                      InputLabelProps={{
                        classes: {
                          root: classes.whiteBackgroundLabel,
                        },
                      }}
                      variant='outlined'
                      label={t('password')}
                      onChange={handleChange}
                      name='password'
                      type='password'
                      validators={['required']}
                      errorMessages={[t('this field is required')]}
                      value={formData.password}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} className={classes.centeredFlex}>
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={loading}
                    className={classes.mainButton}
                  >
                    {(loading && t('please wait')) ||
                      (!loading && t('sign in'))}
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.absolutePosition}
                      />
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.centeredFlex}>
                    <Link to='/auth/attempt-reset-password'>
                      <Typography variant='subtitle2'>
                        {t('forgot password?')}
                      </Typography>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Paper>
          <Box pt={2}>
            <Link to='/auth/sign-up' className={classes.undecoratedLink}>
              <Button className={classes.secondaryButton}>
                {t('create an account')}
              </Button>
            </Link>
          </Box>
        </>
      )}
    </Translation>
  );
};

export default Login;
