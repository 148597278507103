/**
 * @module ResetPassword
 */
//React imports
import React, { useState } from 'react';
import {
  Button,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Box,
} from '@material-ui/core';
//Other libraries
import { Translation } from 'react-i18next';
import axios from 'axios';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const errorCodesToMessages = {
  666: 'network error, please try again',
  1000: 'email is missing',
  3000: 'e-mail is not valid',
};

const useStyles = makeStyles((theme) => ({
  whiteBackgroundLabel: { backgroundColor: 'white' },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    margin: '40px 0px',
    alignItems: 'center',
    justifyItems: 'center',
    // height: "100%"
  },
  paper: {
    width: '40%',
    minWidth: 300,
    maxWidth: 600,
    minHeight: '200px',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    display: 'flex',
  },
  form: {
    padding: '10px 20px',
    margin: '10px',
    width: '100%',
  },
  centeredFlex: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  mainButton: {
    width: '100%',
    minWidth: 200,
    maxWidth: 320,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },

  redText: {
    color: 'red',
  },
  resetPasswordText: { margin: '20px 0' },
  absolutePosition: { position: 'absolute' },
}));

/**
 * @method ResetPassword
 * @description reset password component\
 * parses location.search to get token
 * @see module:Authentication
 */
const ResetPassword = (props) => {
  const [formData, setFormData] = useState({
    password: '',
    repeatPassword: '',
  });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const temp = { ...formData };
    temp[event.target.name] = event.target.value;
    setFormData(temp);
  };

  /**
   * @method handleSubmit
   *
   * @description sends a request to change to a new password
   */
  const handleSubmit = () => {
    const { password } = formData;
    const { token, email } = queryString.parse(props.location.search);

    setLoading(true);
    setErrors([]);
    axios
      .post(
        apiEndpoint + 'customers/reset-password',
        {
          token,
          password,
          email,
        },
        {
          timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
        }
      )
      .then((res) => {
        setLoading(false);

        props.history.push('/auth/sign-in');
      })
      .catch((error) => {
        setLoading(false);

        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          setErrors(error.response.data.errors);
        } else {
          setErrors([{ code: 666, message: 'timeout' }]);
        }
      });
  };

  ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
    if (value !== formData.password) {
      return false;
    }
    return true;
  });

  ValidatorForm.addValidationRule('minLength8', (value) => {
    if (!value) return true;

    return value.length >= 8 ? true : false;
  });

  ValidatorForm.addValidationRule('maxLength255', (value) => {
    if (!value) return true;
    return value.length <= 255 ? true : false;
  });
  ValidatorForm.addValidationRule('isComplexPassword', (value) => {
    return value.match(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}/
    );
  });

  const classes = useStyles();
  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t('netjeek - reset password')}</title>
          </Helmet>
          <Typography className={classes.resetPasswordText}>
            {t('reset your password')}
          </Typography>
          <Paper className={classes.paper}>
            <ValidatorForm
              onSubmit={handleSubmit}
              className={classes.form}
              instantValidate={false}
            >
              <Grid container>
                {errors.length > 0 && (
                  <Grid item xs={12}>
                    <ul>
                      {errors.map((error, index) => (
                        <li className={classes.redText} key={index}>
                          {t(errorCodesToMessages[error.code])}
                        </li>
                      ))}
                    </ul>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <Box pb={3}>
                  <TextValidator
                    InputLabelProps={{
                      classes: {
                        root: classes.whiteBackgroundLabel,
                      },
                    }}
                    variant='outlined'
                    label={t('password')}
                    onChange={handleChange}
                    name='password'
                    type='password'
                    validators={[
                      'required',
                      'minLength8',
                      'maxLength255',
                      'isComplexPassword',
                    ]}
                    errorMessages={[
                      t('this field is required'),
                      t('password length must be between 8 and 255 characters'),
                      t('password length must be between 8 and 255 characters'),
                      t(
                        'For your own safety, your password must contain at least 1 uppercase, 1 lowercase and 1 special character'
                      ),
                    ]}
                    value={formData.password}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box pb={3}>
                  <TextValidator
                    InputLabelProps={{
                      classes: {
                        root: classes.whiteBackgroundLabel,
                      },
                    }}
                    variant='outlined'
                    label={t('repeat password')}
                    onChange={handleChange}
                    name='repeatPassword'
                    type='password'
                    validators={['isPasswordMatch']}
                    errorMessages={[t('password mismatch')]}
                    value={formData.repeatPassword}
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid item xs={12} className={classes.centeredFlex}>
                <Button
                  variant='contained'
                  type='submit'
                  disabled={loading}
                  className={classes.mainButton}
                >
                  {t('reset password')}
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.absolutePosition}
                    />
                  )}
                </Button>
              </Grid>
            </ValidatorForm>
          </Paper>
        </>
      )}
    </Translation>
  );
};

export default ResetPassword;
